import { connect } from "react-redux";
import RepertoirePage from "./RepertoirePage";
import IAppState from "../redux/types/IAppState";
import {
    removeTab,
    openTab,
    addArtistToWork,
    updateAgreementIP,
    updateAgreementWork,
    updateAgreementCarveOutProduct,
    updateMatchingWork,
    updateMatchingProduct,
    setDataSource,
    addWorkToProduct,
    addArtistToProduct,
    updateProductContributorIP,
    updateRelatedWork,
    updateWorkSourceIP,
    updateEditableFields,
    expandProductResult,
    expandWorkResult,
    expandAllResults,
    resetPagination,
    updatePagination,
    expandMatchWorkResult,
    usageExpandAllResults,
    addMatchWorkToUsage,
    addMatchProductToUsage, 
    searchDataChanged, 
    updateSelectionCriteria, 
    updateForeignSocietyInfo, 
    displayRepresentationFlag, 
    addIPToAdjustmentDetails,
    addWorkOrProductToUsageSearchCriteria, 
    updateLicenceUserValues, 
    addWorkToLicenseRequest, 
    addIPToUsageDetails, 
    updateProductSourceIP, 
    addWorkToClaimRequest, 
    createNewProduct, 
    updateSubjectCode,
    updateScheduledJobField,
    updateTerritorySelector,
} from "../redux/reducers/RepertoireReducer";
import { showModal, showYesNoPrompt, updateComment } from "../redux/reducers/ModalReducer";
import { renderJobProgress } from "../redux/reducers/JobReducer";
import {
    getRepertoireComponentDataThunk,
    searchArtistsThunk,
    searchContributorsThunk,
    updateWorkContributorIPThunk,
    searchWorksThunk,
    loadRepertoireFromPathThunk,
    getAgreementShareDisplayOptionsThunk,
    getShareDecimalsToDisplayThunk,
    searchUsagesThunk,
    searchMatchWorksThunk,
    searchMatchProductsThunk,
    getMatchingSourcesThunk,
    importDistributionPoolsThunk,
    getMatchingWorkInfo,
    getUsageMaintenanceDataThunk,
    searchIPsThunk,
    ipTransferJobThunk,
    getUsageMatchingDefaultsProducts,
    getUsageMatchingDefaultsWorks,
    searchLicenseUserThunk,
    searchProductsThunk,
    getCommentCategoryFlagThunk,
    getDistributionSubTypesThunk,
    getAgreementAttachmentTypeRequiredThunk,
    getWorkMaintenanceGeneralViewDisplayOptions,
    getProductMaintenanceGeneralViewDisplayOptions,
    getPoolMaintenanceGeneralViewDisplayOptions,
    getShareToleranceSettings,
    getUsageGridSearchResultsDisplayOptions,
    searchSubjectsThunk,
    getExcludeSourceTypePickShowFieldsThunk,
    getDistributionMaintenanceSettingsDisplayOptions,
    getIPGridSearchResultsDisplayOptions,
    getPDArrangementMusicLyricOptions,
    getShortCutKeysThunk,
    getProductDetailsThunk,
    getProductMaintenanceEnableCuesheets,
    getProductMaintenanceCuesheetsDataSource,
    getRecalculateDomesticorForeignThunk,
    getDownloadFileFormatThunk
} from "../redux/thunks/RepertoireThunks";
import { Dispatch } from "redux";
import { IContributorSearchQuery } from "./types/IContributorSearchQuery";
import { IArtistSearchQuery } from "./types/IArtistSearchQuery";
import { IArtist } from "./types/IArtist";
import { IP } from "./types/IP";
import { clearModalSearchResultsThunk } from "../redux/thunks/ModalThunks";
import { IJob } from "../job/types/IJob";
import { IContributorItem } from "./types/IContributorItem";
import { DataSource } from "./types/DataSource";
import { IWorksSearchQuery } from "./types/IWorksSearchQuery";
import { IWorksSearchResult } from "./types/IWorksSearchResult";
import { RouteChildrenProps } from 'react-router';
import { IRepertoirePathInfo } from "./types/IRepertoirePathInfo";
import { setCurrentPage } from "../redux/reducers/HomeReducer";
import { IYesNoPromptViewModalProps } from "./components/modalViews/yesNoPromptView/YesNoPromptView";
import { IUsagesSearchQuery } from "./types/usageTypes/IUsagesSearchQuery";
import { IMatchWorksSearchQuery } from "./types/usageTypes/IMatchWorksSearchQuery";
import { IMatchWorksSearchResult } from "./types/usageTypes/IMatchWorksSearchResult";
import { getDistributionsThunk } from "../redux/thunks/DataIngestionThunks";
import { IMatchProductsSearchQuery } from "./types/usageTypes/IMatchProductsSearchQuery";
import { IProductSearchResult } from "./types/IProductSearchResult";
import { ILookupDictionary } from "../lookup/types/ILookupDictionary";
import IRepertoireComponentDataItem from "../redux/types/IRepertoireComponentDataItem";
import { ITreeData } from "./types/ITreeData";
import { addFileToUpload, setInDropZone, setProgressUploadFileProccess } from "../redux/reducers/DataIngestionReducer";
import IFile from "../redux/types/IFiles";
import { getDistributionTypesThunk } from "../redux/thunks/RepertoireThunks";
import { IDistributionState } from "../redux/types/IDistributionState";
import { getLookupEntitiesThunk } from "../redux/thunks/LookupThunks";
import { IIPsSearchQuery } from "./types/IIPsSearchQuery";
import { IipTransfer } from "./types/usageTypes/IipTransfer";
import { ILicenseUserSearchQuery } from "./types/ILicenseUserSearchQuery";
import { IProductSearchQuery } from "./types/IProductSearchQuery";
import { FormatFields } from "../redux/types/FormatFields";
import { ISubjectsSearchQuery } from "./types/ISubjectsSearchQuery";
import { ISubject } from "./types/ISubject";
import { IScheduledJobStateKeys } from "../redux/types/IScheduledJobState";
import { getCustomerDateConfigurationThunk } from "../redux/thunks/AccountThunks";
import { IIPRepresentation } from "./types/IIPRepresentation";
import MyPackagesGeneralDataView from "./myPackages/components/MyPackagesGeneralDataView/MyPackagesGeneralDataView";

export default connect(
    (state: IAppState, props?: RouteChildrenProps) => ({
        pathInfo: props.match.params,
        displayModal: state.modal.displayModal,
        modalContent: state.modal.modalContent,
        modalProps: state.modal.modalProps,
        modalIndex: state.modal.index,
        modalKey:state.modal.key,
        modalComponentInstance: state.modal.modalComponentInstance,
        modalTitle: state.modal.title,
        displayModalCloseButton: state.modal.displayModalCloseButton,
        tabs: state.repertoire.tabs,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.SearchResultsTable,
        dataSource: state.repertoire.dataSource,
        agreementsDataSource: state.repertoire.agreementDataSource,
        productDataSource: state.repertoire.productsDataSource,
        workNotFoundViewData: state.repertoire.componentData.WorkNotFoundView,
        loadingViewData: state.repertoire.componentData.LoadingView,
        yesNoPromptViewData: state.repertoire.componentData.YesNoPromptView,
        tabsViewData: state.repertoire.componentData.TabsView,
        activeTab: state.repertoire.activeTab,
        closeTabDisabled: state.repertoire.saveInProgress,
        artistsSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].artistsSearchResult,
        contributorsSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].contributorsSearchResult,
        worksSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].worksSearchResult,
        licenseWorksSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].licenseWorksSearchResults,
        productSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].productsSearchResult,
        workSubjectSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].workSubjects,
        repertoirePageDataView: state.repertoire.componentData.RepertoirePageDataView,
        searchSuccessful: state.repertoire.searchSuccessful,
        currentUser: state.account.currentUser,
        editableFields: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].editableFields,
        expandedProductResults: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].expandedProductResults,
        expandedWorkResults: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].expandedWorkResults,
        expandAll: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].expandAll,
        modalMessage: state.modal.modalMessage,
        updateWorkflowViewData: state.repertoire.componentData.UpdateWorkflowViewData,
        indexOfFirstResult: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].indexOfFirstResult ? state.repertoire.tabs[state.repertoire.activeTab].indexOfFirstResult : undefined,
        indexOfLastResult: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].indexOfLastResult ? state.repertoire.tabs[state.repertoire.activeTab].indexOfLastResult : undefined,
        resultsPerPage: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].resultsPerPage ? state.repertoire.tabs[state.repertoire.activeTab].resultsPerPage : undefined,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        currentPage: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].currentPage ? state.repertoire.tabs[state.repertoire.activeTab].currentPage : undefined,
        component: state.repertoire.tabs && state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].component,
        usageSearchViewData: state.repertoire.componentData.UsageSearchView,
        usageSearchResultsTableData: state.repertoire.componentData.ResultsTable,
        usageSearchSuccessful: state.repertoire.searchSuccessful,
        usageGroupsSearchResult: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].usageGroupsSearchResult,
        matchWorkSearchResult: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].matchWorkSearchResult,
        matchProductsSearchResult: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].matchProductsSearchResult,
        expandedMatchWorkResults: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].expandedMatchWorkResults,
        usageExpandAll: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].usageExpandAll,
        lookupValues: state.lookupEntities,
        ipMaintenanceGeneralDataViewData: state.repertoire.componentData.IPMaintenanceGeneralDataView,
        lookupSources: state.repertoire.matchingSourceTreeData,
        shortCutKeys: state.repertoire.shortCutKeys,
        customerDateConfiguration: state.account.customerDate,
        dataGridTableData: state.repertoire.componentData.DataGridTable,
        distributions: state.dataIngestion.distributions,
        isReadOnlyModal: state.modal.isReadOnlyModal,
        containerDetailsWindowComponentData: state.dataIngestion.componentData.ContainerDetailsWindow,
        droppedFiles: state.dataIngestion.droppedFiles,
        distributionTypes: state.repertoire.distributionTypes,
        distributionSubTypes: state.repertoire.distributionSubTypes,
        ipTransferViewData: state.repertoire.componentData.IPTransferView,
        usageMatchingDefaultsWorks: state.repertoire.usageMatchingDefaultsWorks,
        usageMatchingDefaultsProducts: state.repertoire.usageMatchingDefaultsProducts,
        licenseUserSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].licenseUserSearchResult,
        customer: state.account.customer,
        myPackagesGeneralDataViewData:state.repertoire.componentData.MyPackagesGeneralDataView,
        myPackagesResult:state.repertoire.myPackagesResult,
        downloadFileFormat:state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].downloadFileFormat,
    }),
    (dispatch: Dispatch) => ({
        setDataSource: (dataSource: string) => dispatch(setDataSource(dataSource)),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string,
            modalMessage?: string,
            isReadOnly?: boolean,
            key?: string,
            index?:number
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title,modalMessage,isReadOnly,key,index)
            ),
        hideModal: () => dispatch<any>(clearModalSearchResultsThunk()),
        updateModalComment: (value: any) => dispatch(updateComment(value)),
        getRepertoireFields: () => dispatch<any>(getRepertoireComponentDataThunk()),
        removeTab: (index: number) => dispatch(removeTab(index)),
        openTab: (index: number) => dispatch(openTab(index)),
        addArtistToWork: (artist: IArtist, id: number) => dispatch(addArtistToWork(artist, id)),
        addArtistToProduct: (artist: IArtist, id: number, index?: number) => dispatch(addArtistToProduct(artist, id, index)),
        addWorkToProduct: (work: IWorksSearchResult) => dispatch(addWorkToProduct(work)),
        updateWorkContributorIP: (dataSource: DataSource, workContributor: IContributorItem, ip: IP) => dispatch<any>(updateWorkContributorIPThunk(dataSource, workContributor, ip)),
        updateProductContributorIP: (productContributorID: number, ip: IP) => dispatch<any>(updateProductContributorIP(productContributorID, ip)),
        updateAgreementIP: (agreementAdminIPID: number, ip: IP) => dispatch<any>(updateAgreementIP(agreementAdminIPID, ip)),
        updateAgreementWork: (work: IWorksSearchResult) => dispatch<any>(updateAgreementWork(work)),
        updateAgreementCarveOutProduct: (agreementProductWorkID: number, product: IProductSearchResult) => dispatch<any>(updateAgreementCarveOutProduct(agreementProductWorkID, product)),
        getProductDetails: (dataSource: string, productCoreID: number, openNewTab?: boolean) => dispatch<any>(getProductDetailsThunk('repertoire', productCoreID, null,[], false, openNewTab)),
        updateMatchingWork: (workID: number, work: IWorksSearchResult) => dispatch<any>(updateMatchingWork(workID, work)),
        updateMatchingProduct: (productID: number, product: IProductSearchResult) => dispatch<any>(updateMatchingProduct(productID, product)),
        updateRelatedWork: (work: IWorksSearchResult) => dispatch<any>(updateRelatedWork(work)),
        updateWorkSourceIP: (workSource: IP) => dispatch<any>(updateWorkSourceIP(workSource)),
        updateProductSourceIP: (productSource: IP) => dispatch<any>(updateProductSourceIP(productSource)),
        searchArtists: (query: IArtistSearchQuery, dataSource: string) =>
            dispatch<any>(searchArtistsThunk(query, dataSource, true)),
        searchContributors: (query: IContributorSearchQuery) =>
            dispatch<any>(searchContributorsThunk(query)),
        searchProducts: (query: IProductSearchQuery, modalOpen: boolean) =>
            dispatch<any>(searchProductsThunk(query, true)),
        searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) =>
            dispatch<any>(searchWorksThunk(query, true)),
        searchSubjects: (query: ISubjectsSearchQuery, modalOpen: boolean) =>
            dispatch<any>(searchSubjectsThunk(query, true)),
        updateSubjectCode: (subject: ISubject) =>
            dispatch<any>(updateSubjectCode(subject)),
        renderJobProgress: (jobs: IJob[]) => dispatch(renderJobProgress(jobs)),
        loadRepertoireFromPath: (pathInfo: IRepertoirePathInfo, location: Location) => dispatch<any>(loadRepertoireFromPathThunk(pathInfo, location)),
        setCurrentPage: (link: string) => dispatch(setCurrentPage(link)),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        updateEditableFields: (value: any[], key?: string) =>
            dispatch(updateEditableFields(value, key)),
        expandProductResult: (productResult: number) => dispatch<any>(expandProductResult(productResult)),
        expandWorkResult: (workResult: number) => dispatch<any>(expandWorkResult(workResult)),
        expandAllResults: () => dispatch<any>(expandAllResults()),
        getAgreementShareDisplayOptions: () => dispatch<any>(getAgreementShareDisplayOptionsThunk()),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        getShareDecimalsToDisplay: () => dispatch<any>(getShareDecimalsToDisplayThunk()),
        getUsageGridSearchResultsDisplayOptions: () => dispatch<any>(getUsageGridSearchResultsDisplayOptions()),
        getIPGridSearchResultsDisplayOptions: () => dispatch<any>(getIPGridSearchResultsDisplayOptions()),
        getProductMaintenanceEnableCuesheets:() => dispatch<any>(getProductMaintenanceEnableCuesheets()),
        getProductMaintenanceCuesheetsDataSource:() => dispatch<any>(getProductMaintenanceCuesheetsDataSource()),
        getPDArrangementMusicLyricOptions: () => dispatch<any>(getPDArrangementMusicLyricOptions()),
        getWorkMaintenanceGeneralViewDisplayOptions: () => dispatch<any>(getWorkMaintenanceGeneralViewDisplayOptions()),
        getRecalculateDomesticorForeignThunk:()=> dispatch<any>(getRecalculateDomesticorForeignThunk()),
        getProductMaintenanceGeneralViewDisplayOptions: () => dispatch<any>(getProductMaintenanceGeneralViewDisplayOptions()),
        getPoolMaintenanceGeneralViewDisplayOptions: () => dispatch<any>(getPoolMaintenanceGeneralViewDisplayOptions()),
        getShareToleranceSettings: () => dispatch<any>(getShareToleranceSettings()),
        getDistributionMaintenanceSettingsDisplayOptions: () => dispatch<any>(getDistributionMaintenanceSettingsDisplayOptions()),
        getUsageMatchingDefaultsProducts: () => dispatch<any>(getUsageMatchingDefaultsProducts()),
        getUsageMatchingDefaultsWorks: () => dispatch<any>(getUsageMatchingDefaultsWorks()),
        searchUsages: (query: IUsagesSearchQuery, modalOpen: boolean, usageType: string, lookupSources: ITreeData[]) => dispatch<any>(searchUsagesThunk(query, false, usageType, lookupSources)),
        searchMatchWorks: (query: IMatchWorksSearchQuery, modalOpen: boolean) => dispatch<any>(searchMatchWorksThunk(query, true)),
        searchMatchProducts: (query: IMatchProductsSearchQuery, modalOpen: boolean) => dispatch<any>(searchMatchProductsThunk(query, true)),
        expandMatchWorkResult: (matchWorkResults: number) => dispatch<any>(expandMatchWorkResult(matchWorkResults)),
        usageExpandAllResults: () => dispatch<any>(usageExpandAllResults()),
        addMatchworkToUsage: (work: IMatchWorksSearchResult) => dispatch(addMatchWorkToUsage(work)),
        addMatchProductToUsage: (product: IProductSearchResult) => dispatch(addMatchProductToUsage(product)),
        getDistributions: () => dispatch<any>(getDistributionsThunk()),
        searchDataChanged: (field: string, value: any) => dispatch(searchDataChanged(field, value)),
        addNewProduct: (lookups: ILookupDictionary, isNew?: boolean, productMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[]) =>
            dispatch<any>(createNewProduct(lookups, isNew, productMaintenanceGeneralDataView, formats)),
        getSources: () => dispatch<any>(getMatchingSourcesThunk()),
        getShortCutKeys: () => dispatch<any>(getShortCutKeysThunk()),
        updateSelectionCriteria: (distributionPoolId: number, value: any) => dispatch<any>(updateSelectionCriteria(distributionPoolId, value)),
        updateTerritorySelector: (repId: number, value: IIPRepresentation) => dispatch<any>(updateTerritorySelector(repId, value)),
        displayRepresentation: (display: boolean) => dispatch(displayRepresentationFlag(display)),
        setInDropZone: (isDropZone: boolean) => dispatch<any>(setInDropZone(isDropZone)),
        addFileToUpload: (file: IFile, inProgress: boolean) => dispatch<any>(addFileToUpload(file, inProgress)),
        setProgressUploadFileProccess: (message: string, file: IFile, isUploadComplete: boolean) => dispatch<any>(setProgressUploadFileProccess(message, file, isUploadComplete)),
        importDistributionPools: (distribution: IDistributionState, file: string, lookupValues: ILookupDictionary) => dispatch<any>(importDistributionPoolsThunk(distribution, file, lookupValues)),
        updateForeignSocietyInfo: (distributionPoolID: number, value: any, fieldName: string) => dispatch<any>(updateForeignSocietyInfo(distributionPoolID, value, fieldName)),
        getMatchingWorkInfo: (workId: number) => dispatch<any>(getMatchingWorkInfo(workId)),
        getUsageDetails: (usageID: string, openEntityForWorflowSession?: boolean) =>
            dispatch<any>(getUsageMaintenanceDataThunk(usageID, false)),
        getDistributionTypes: () => dispatch<any>(getDistributionTypesThunk()),
        getDistributionSubTypes: () => dispatch<any>(getDistributionSubTypesThunk()),
        getLookupEntities: () => dispatch<any>(getLookupEntitiesThunk()),
        searchIPs: (query: IIPsSearchQuery, keepModalOpenAfterSearch?: boolean) => dispatch<any>(searchIPsThunk(query, keepModalOpenAfterSearch)),
        ipTransfer: (ipTransferObject: IipTransfer) => dispatch<any>(ipTransferJobThunk(ipTransferObject)),
        addIpToAdjustmentDetail: (ip: IP, id: number, index: number) => dispatch<any>(addIPToAdjustmentDetails(ip, id, index)),
        addIPToUsageDetails: (ip: IP, usageDetailsIndex: number, usageRowIndex: number, adjustmentsIndex: number) => dispatch<any>(addIPToUsageDetails(ip, usageDetailsIndex, usageRowIndex, adjustmentsIndex)),
        addWorkOrProductToUsageSearchCriteria: (value: any) => dispatch(addWorkOrProductToUsageSearchCriteria(value)),
        searchLicenseUser: (query: ILicenseUserSearchQuery) =>
            dispatch<any>(searchLicenseUserThunk(query)),
        updateLicenceUserValues: (params: any) =>
            dispatch<any>(updateLicenceUserValues(params)),
        addWorkToLicenseRequest: (work: IWorksSearchResult) => dispatch<any>(addWorkToLicenseRequest(work)),
        addWorkToClaimRequest: (work: IWorksSearchResult) => dispatch<any>(addWorkToClaimRequest(work)),
        updateScheduledJobField: (value: any, fieldName: IScheduledJobStateKeys,componentName?:string) => dispatch<any>(updateScheduledJobField(value, fieldName,componentName)),
        getCommentCategoryFlag: () => dispatch<any>(getCommentCategoryFlagThunk()),
        getAgreementAttachmentTypeRequired: () => dispatch<any>(getAgreementAttachmentTypeRequiredThunk()),
        getExcludeSourceTypePickShowFields: () => dispatch<any>(getExcludeSourceTypePickShowFieldsThunk()),
        getCustomerDateConfiguration: () => dispatch<any>(getCustomerDateConfigurationThunk()),
        getDownloadFileFormat: () => dispatch<any>(getDownloadFileFormatThunk())
    })
)(RepertoirePage);
