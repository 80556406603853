import { ITabReduxItem } from "../redux/types/ITabReduxItem";
import { IDataItem } from "./types/IDataItem";
import { IRepertoireStateKeys } from "./types/IRepertoireStateKeys";
import { ITableActionName } from "./types/ITableAction";


export const REPERTOIRE_PAGE_FORM_ITEM_SIZE: string = "form-group col-md-4 col-sm-5 col-xs-12"; 
export const REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION: string = "form-group col-md-4 col-sm-5 col-xs-12"; 
export const REPERTOIRE_PAGE_FORM_WIDE_SIZE: string = "form-group col-md-12 col-sm-6 col-xs-12";
export const REPERTOIRE_PAGE_FORM_LARGE_BOX_SIZE: string = "form-group col-md-16 col-sm-6 col-xs-12";
export const REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE: string = "form-group col-md-2 col-sm-4 col-xs-4";

export const REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE_3: string = "form-group col-md-3 col-sm-6 col-xs-6";
export const REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE_2: string = "form-group col-md-2 col-sm-6 col-xs-6";

export const REPERTOIRE: string = "Repertoire";
export const INTRAY: string = "Intray";
export const SIMPLE: string = "SIMPLE";
export const ADVANCED: string = "ADVANCED";
export const POSTED: string = "Posted";
export const DATA_SOURCE: string = "Data Source:";
// Asignor = publisher
export const PUBLISHER: string = "ASSIGNOR";
// Asignee = Administrated by
export const ADMINISTERED: string = "ASSIGNEE";

export const ASSIGNOR: string = 'ASSIGNOR';
export const ASSIGNEE: string = 'ASSIGNEE';
export const SEARCH_RESULTS_HEADER_SECTIONS: string[] = [
    "Title",
    "Number",
    "Writers",
    "Publishers",
    "Artists",
    "Compound Type"
];
export const READ_ONLY_INPUT: string = "readonly";
export const TEXT_READONLY_IN_REPERTOIRE_INPUT: string = "textReadonlyInRepertoire";
export const TEXT_INPUT: string = "text";
export const INTEGER_INPUT: string = "integer";
export const INTEGER_PERCENTAGE_INPUT: string = "integerPercentage";
export const CHECKBOX_INPUT: string = "checkbox";
export const DROPDOWN_INPUT: string = "dropdown";
export const TIME_INPUT: string = "time";
export const DATE_INPUT: string = "date";
export const BOOLEAN_READONLY_INPUT: string = "booleanReadonly";
export const WORK_ITEM_VIEW: string = "WorkItemView";
export const POP_UP_INFO: string = "PopUpInfo";
export const SCREEN_WIDTH_CHANGE = 767;
export const TOGGLE_BUTTON = "toggleButton";
export const CUSTOM_INPUT: string = "custom";
export const TIMESPAN_INPUT: string = "timespan";
export const READ_ONLY_TIMESPAN_INPUT: string = "readOnlyTimespan";
export const DROPDOWN_TREE_INPUT: string = "dropdownTree";
export const LINK_INPUT: string = "link";

export const GENERIC_ERROR: string = "genericError";

export const IS_DISPLAY: string = "IsDisplayed";
export const CODE_CATEGORY: string = "CodeCategory";
export const DATA_TYPE: string = "Datatype";
export const IS_ACTIVE: string = "IsActive";
export const IS_MUSIC: string = "IsMusic";
export const IS_MAGAZINE_SUBJECT: string = "IsMagazineSubject";
export const SUBJECT_TYPE: string = "SubjectType";
export const GENRE: string = "Genre";

export const DONOTCARE_ABOUTWORKFLOWS = "doNotCareAboutWorkFlows";
export const HASOPEN_WORKFLOWS = "hasOpenWorkFlows";
export const WITHOUTOPEN_WORKFLOWS = "withoutOpenWorkFlows";

export const EXTERNAL: string = "Internal";
export const INTERNAL: string = "External";

// Roles
export const ALL_ROLES = "All";
export const VIEW_WORKS_ROLE = "Work.View";
export const UPDATE_WORKS_ROLE = "Work.Update";
export const DELETE_WORKS_ROLE = "Work.Delete";
export const CONFIGURE_WORKS_ROLE = "Work.Configure";
export const VIEW_ARTISTS_ROLE = "Artist.View";
export const UPDATE_ARTISTS_ROLE = "Artist.Update";
export const DELETE_ARTISTS_ROLE = "Artist.Delete";
export const CONFIGURE_ARTISTS_ROLE = "Artist.Configure";
export const VIEW_AGREEMENTS_ROLE = "Agreement.View";
export const UPDATE_AGREEMENTS_ROLE = "Agreement.Update";
export const DELETE_AGREEMENTS_ROLE = "Agreement.Delete";
export const CONFIGURE_AGREEMENTS_ROLE = "Agreement.Configure";
export const VIEW_PRODUCTS_ROLE = "Product.View";
export const UPDATE_PRODUCTS_ROLE = "Product.Update";
export const DELETE_PRODUCTS_ROLE = "Product.Delete";
export const CONFIGURE_PRODUCTS_ROLE = "Product.Configure";
export const VIEW_IPS_ROLE = "IP.View";
export const UPDATE_IPS_ROLE = "IP.Update";
export const DELETE_IPS_ROLE = "IP.Delete";
export const CONFIGURE_IPS_ROLE = "IP.Configure";
export const VIEW_POOLS_ROLE = "Pool.View";
export const UPDATE_POOLS_ROLE = "Pool.Update";
export const DELETE_POOLS_ROLE = "Pool.Delete";
export const CONFIGURE_POOLS_ROLE = "Pool.Configure";
export const VIEW_DISTRIBUTIONS_ROLE = "Distribution.View";
export const UPDATE_DISTRIBUTIONS_ROLE = "Distribution.Update";
export const DELETE_DISTRIBUTIONS_ROLE = "Distribution.Delete";
export const CONFIGURE_DISTRIBUTIONS_ROLE = "Distribution.Configure";
export const VIEW_ADJUSTMENTS_ROLE = "Adjustment.View";
export const UPDATE_ADJUSTMENTS_ROLE = "Adjustment.Update";
export const DELETE_ADJUSTMENTS_ROLE = "Adjustment.Delete";
export const CONFIGURE_ADJUSTMENTS_ROLE = "Adjustment.Configure";
export const VIEW_PAYMENT_RUNS_ROLE = "PaymentRun.View";
export const UPDATE_PAYMENT_RUNS_ROLE = "PaymentRun.Update";
export const DELETE_PAYMENT_RUNS_ROLE = "PaymentRun.Delete";
export const CONFIGURE_PAYMENT_RUNS_ROLE = "PaymentRun.Configure";
export const VIEW_USAGES_ROLE = "Usage.View";
export const UPDATE_USAGES_ROLE = "Usage.Update";
export const DELETE_USAGES_ROLE = "Usage.Delete";
export const CONFIGURE_USAGES_ROLE = "Usage.Configure";
export const VIEW_CLAIMS_ROLE = "Claim.View";
export const UPDATE_CLAIMS_ROLE = "Claim.Update";
export const DELETE_CLAIMS_ROLE = "Claim.Delete";
export const CONFIGURE_CLAIMS_ROLE = "Claim.Configure";
export const VIEW_WORKFLOWS_ROLE = "Workflow.View";
export const UPDATE_WORKFLOWS_ROLE = "Workflow.Update";
export const DELETE_WORKFLOWS_ROLE = "Workflow.Delete";
export const CONFIGURE_WORKFLOWS_ROLE = "Workflow.Configure";
export const VIEW_LICENSE_ROLE = "License.View";
export const UPDATE_LICENSE_ROLE = "License.Update";
export const DELETE_LICENSE_ROLE = "License.Delete";
export const CONFIGURE_LICENSE_ROLE = "License.Configure";

// SearchView Component Instances
export const SEARCH_VIEW_WORKS: string = "works";
export const SEARCH_VIEW_ARTISTS: string = "artists";
export const SEARCH_VIEW_PERFORMERS: string = "performers"
export const SEARCH_VIEW_CONTRIBUTORS: string = "contributors";
export const SEARCH_VIEW_AGREEMENTS: string = "agreements";
export const SEARCH_VIEW_DRAFT_AGREEMENTS: string = "draftAgreements";
export const SEARCH_VIEW_AGREEMENT_IP: string = "agreement_ip";
export const SEARCH_VIEW_JOB_IP: string = "job_ip";
export const SEARCH_VIEW_IPS: string = "ips";
export const SEARCH_VIEW_LICENSE_USER: string = "license_user";
export const SEARCH_VIEW_PRODUCTS: string = "products";
export const SEARCH_VIEW_AGREEMENT_CONTRIBUTORS: string = "agreementContributors";
export const SEARCH_VIEW_WORKSOURCE_IP: string = "workSourceIP";
export const SEARCH_VIEW_PRODUCTSOURCE_IP: string = "productSourceIP";
export const SEARCH_VIEW_HAS_OPEN_WORKFLOW: string = "hasOpenWorkflow";
export const SEARCH_VIEW_WORKFLOW: string = 'workflows';
export const SEARCH_VIEW_POOLS: string = 'pools';
export const SEARCH_VIEW_DISTRIBUTIONS: string = 'distributions';
export const SEARCH_VIEW_ADJUSTMENTS: string = 'adjustments';
export const SEARCH_VIEW_PAYMENTRUN: string = 'paymentRuns';
export const SEARCH_VIEW_LICENSES: string = 'licenses';
export const SEARCH_VIEW_WORK_LICENSES: string = 'licensesWork';
export const SEARCH_VIEW_LICENSES_FILEIMPORT: string = 'licensesFileImport';
export const IP_AGREEMENTS: string = 'ipAgreements';
export const LEGAL_ENTITY_TYPE: string = "legalEntityType";
export const INTERESTED_PARTY_TYPE: string = "interestedPartyType";
export const LEGAL_ENTITY: string = "legalEntity";
export const SEARCH_VIEW_WORK_CLAIMS: string = "claimedWorks";
export const PRODUCT_WORK_SOURCE_STATE_KEY: string = "dataSource";
export const SEARCH_VIEW_CLAIMS = "claims";


// stateKeys
// SearchView
export const TITLE_STATE_KEY: IRepertoireStateKeys = "title";
export const NUMBER_STATE_KEY: IRepertoireStateKeys = "number";
export const ARTIST_STATE_KEY: IRepertoireStateKeys = "artist";
export const WRITER_STATE_KEY: IRepertoireStateKeys = "writers";
export const ACC_NUMBER_STATE_KEY: IRepertoireStateKeys = "societyAccountNumber";
export const IP_NAME_NUMBER_STATE_KEY: IRepertoireStateKeys = "ipNameNumber";
export const IP_EFFECTIVE_FROM_KEY: IRepertoireStateKeys = "effectiveFrom";
export const IP_EFFECTIVE_TO_KEY: IRepertoireStateKeys = "effectiveTo";
export const UPDATE_ACTION_STATE_KEY: IRepertoireStateKeys = "update_action";
export const PRODUCT_SEARCH_TYPE_STATE_KEY: IRepertoireStateKeys = "type";
export const PRODUCT_SEARCH_TYPE_SOURCE_KEY: IRepertoireStateKeys = "source";
export const PRODUCT_SEARCH_MUSIC_DURATION_KEY: IRepertoireStateKeys = "musicDuration";
export const PRODUCT_WORKS_REPERTOIRE_STATE_KEY: IRepertoireStateKeys = "productWorksRepertoire";
export const COMPONENTS_WORKS_REPERTOIRE_STATE_KEY: IRepertoireStateKeys = "componentWorksRepertoire";

export const CUE_SHEET_KEY: IRepertoireStateKeys = "cueSheet"

//WorkItemView
export const RECORDING_COMPANY: IRepertoireStateKeys =  "recordingCompany";
export const RECORDING_PRODUCT_LABEL: IRepertoireStateKeys =  "label";
export const RECORDING_PRODUCT_FORMAT: IRepertoireStateKeys =  "format";
export const RECORDING_PRODUCT_TYPES_OF_RELEASE: IRepertoireStateKeys =  "typesOfRelease";
export const RECORDING_PRODUCT_SCHEME_TYPE: IRepertoireStateKeys =  "schemeType";
export const RECORDING_PRODUCT_PRODUCT_CATALOGUE_NUMBER: IRepertoireStateKeys =  "productCatalogueNumber";
export const RECORDING_PRODUCT_COUNTRY_OF_ORIGIN: IRepertoireStateKeys =  "countryOfOrigin";
export const RECORDING_PRODUCT_TOTAL_DURATION: IRepertoireStateKeys =  "productTotalDuration";
export const RECORDING_PRODUCT_MUSIC_DURATION: IRepertoireStateKeys =  "productMusicDuration";
export const RECORDING_PRODUCT_RELEASE_DATE: IRepertoireStateKeys =  "releaseDate";

export const STAMP_ID_STATE_KEY: IRepertoireStateKeys = "stampID";
export const TRACK_WORKS: IRepertoireStateKeys = "track";
export const SIDE_WORKS: IRepertoireStateKeys = "side";
export const SEQUENCE_WORKS: IRepertoireStateKeys = "sequence";
export const COMPOUND_TYPE_STATE_KEY: IRepertoireStateKeys = "compoundType";
export const COMMENT_CATEGORY_STATE_KEY: IRepertoireStateKeys = "CommentCategory";
export const GENRE_STATE_KEY: IRepertoireStateKeys = "genre";
export const WORK_TYPE_STATE_KEY: IRepertoireStateKeys = "workType";
export const DURATION_STATE_KEY: IRepertoireStateKeys = "duration";
export const WORK_BATCH_ID_STATE_KEY: IRepertoireStateKeys = "workBatchID";
export const POST_WORK_BATCH_STATE_KEY: IRepertoireStateKeys = "postWorkBatchButton";
export const MATCH_WORK_BATCH_STATE_KEY: IRepertoireStateKeys = "matchWorkBatchButton";
export const PRODUCT_BATCH_ID_STATE_KEY: IRepertoireStateKeys = "productBatchID";
export const POST_PRODUCT_BATCH_STATE_KEY: IRepertoireStateKeys = "postProductBatchButton";
export const MATCH_PRODUCT_BATCH_STATE_KEY: IRepertoireStateKeys = "matchProductBatchButton";
export const POST_AGREEMENT_BATCH_STATE_KEY: IRepertoireStateKeys = "postAgreementBatchButton";
export const POST_ADJUSTMENT_BATCH_STATE_KEY: IRepertoireStateKeys = "postAdjustmentBatchButton";
export const AGREEMENT_BATCH_ID_STATE_KEY: IRepertoireStateKeys = "agreementBatchID";
export const REG_DATE_STATE_KEY: IRepertoireStateKeys = "registrationDate";
export const LAST_MODIFIED_DATE_STATE_KEY: IRepertoireStateKeys = "lastModified";
export const SOURCE_STATE_KEY: IRepertoireStateKeys = "source";
export const SOURCE_SOCIETY_STATE_KEY: IRepertoireStateKeys = "sourceSociety";
export const SOURCE_TYPE_STATE_KEY: IRepertoireStateKeys = "sourceType";
export const SOURCE_IP_STATE_KEY: IRepertoireStateKeys = "sourceIP";
export const SOURCE_IPI_NUMBER_STATE_KEY: IRepertoireStateKeys = "sourceIPINumber";
export const MERGE_THIS_WORK_STATE_KEY: IRepertoireStateKeys = "mergeThisWork";
export const STATUS_STATE_KEY: IRepertoireStateKeys = "workStatus";
export const SUB_STATUS_STATE_KEY: IRepertoireStateKeys = "workSubStatus";
export const PUBLIC_DOMAIN_STATE_KEY: IRepertoireStateKeys = "isOutOfCopyright";
export const CONTROLLED_COMPOSITION_STATE_KEY: IRepertoireStateKeys = "isControlledComposition";
export const PC_SPECIFIC_STATE_KEY: IRepertoireStateKeys = "isPCSpecific";
export const PD_ARRANGEMENT_STATE_KEY: IRepertoireStateKeys = "isPDArrangement";
export const PD_ADM_STATE_KEY: IRepertoireStateKeys = "isPDADM";
export const PD_ADL_STATE_KEY: IRepertoireStateKeys = "isPDADL";
export const NON_ROYALTY_BEARING_STATE_KEY: IRepertoireStateKeys = "isNonRoyaltyBearing";
export const SET_TYPE_GROUP_KEY: IRepertoireStateKeys = "setTypeGroup";
export const AGREEMENT_TYPE_KEY: IRepertoireStateKeys = "agreementType";
export const MAINTAINED_STATE_KEY: IRepertoireStateKeys = "isMaintained";
export const IPI_NUMBER_STATE_KEY: IRepertoireStateKeys = "ipiNumber";
export const WORK_SUBJECT_STATE_KEY: IRepertoireStateKeys = "subject";
export const TERRITORY_SPECIFIC_INFO_STATE_KEY: IRepertoireStateKeys = "territorySpecificInfo";
export const WORK_CONTRIBUTOR_SET_TYPE_STATE_KEY: IRepertoireStateKeys = "workContributorSetTypes";
export const WORK_CONTRIBUTOR_RELATIONSHIPS_STATE_KEY: IRepertoireStateKeys = "relationships";
export const WORK_CONTRIBUTOR_REFERENCES_STATE_KEY: IRepertoireStateKeys = "references";
export const WORK_ISWC_SUBMISSIONS: IRepertoireStateKeys = 'iswcSubmissions';
export const WORK_ICE_SUBMISSIONS: IRepertoireStateKeys = 'iceSubmissions';
export const WORK_CISNET_SUBMISSIONS: IRepertoireStateKeys = 'cisNetSubmissions';
export const WORK_WID_SUBMISSIONS: IRepertoireStateKeys = 'widSubmissions';
export const WORK_ICE_STATUS: IRepertoireStateKeys = 'iceStatus';
export const WORK_ICE_SUBMISSION_RETRIES: IRepertoireStateKeys = 'iceSubmissionRetries';
export const WORK_ISWC_STATUS: IRepertoireStateKeys = 'iswcStatus';
export const WORK_ISWC_SUBMISSION_RETRIES: IRepertoireStateKeys = 'iswcSubmissionRetries';
export const WORK_CISNET_STATUS: IRepertoireStateKeys = 'cisNetStatus';
export const WORK_CISNET_SUBMISSION_RETRIES: IRepertoireStateKeys = 'cisNetSubmissionRetries';
export const WORK_WID_STATUS: IRepertoireStateKeys = 'widStatus';
export const WORK_WID_SUBMISSION_RETRIES: IRepertoireStateKeys = 'widSubmissionRetries';
export const WORK_IS_ORIGINAL_WORK_STATE_KEY: IRepertoireStateKeys = 'isOriginalWork';
export const WORK_DOMESTIC_FOREIGN_STATE_KEY: IRepertoireStateKeys = 'domesticOrForeign';
export const WORK_VALID_FOR_USAGE_FORM_DATE_STATE_KEY: IRepertoireStateKeys = 'validForUsageFrom';
export const WORK_VALID_FOR_USAGE_TO_DATE_STATE_KEY: IRepertoireStateKeys = 'validForUsageTo';
export const WORK_IS_ARRANGEMENT_STATE_KEY: string = 'isArrangement'
export const WORK_INSTRUMENTATION_STATE_KEY: IRepertoireStateKeys = 'instrumentation';
export const REGION_STATE_KEY: IRepertoireStateKeys = 'region';
export const REGION_LABEL_VALUE: string = 'Region'
export const INSTRUMENTS_STATE_KEY: IRepertoireStateKeys = "workInstruments";
export const WORK_IS_DISTRIBUTED_STATE_KEY: IRepertoireStateKeys = "isDistributed";
export const WORK_IS_MAINTAINED_MCPSI_STATE_KEY: IRepertoireStateKeys = "isMaintainedMCPSI";
export const WORK_IS_LIBRARY_STATE_KEY: IRepertoireStateKeys = "isLibraryWork";
export const FIRST_PERFORMANCE_STATE_KEY: IRepertoireStateKeys = "firstPerformance";
export const MATCH_CONNECTOR_STATUS_STATE_KEY: IRepertoireStateKeys = "matchConnectorStatus";
export const WORK_IS_COMMISIONED_FOR_COMMERCIAL_KEY: IRepertoireStateKeys = "isCommissionedForCommercial";
export const WORK_HASOPENWORKFLOW_STATE_KEY: IRepertoireStateKeys = "hasOpenWorkflow";
export const MUSIC_SOURCE_STATE_KEY: IRepertoireStateKeys = "musicSource";
export const MUSIC_ARRANGEMENT_STATE_KEY: IRepertoireStateKeys = "musicArrangement";
export const LYRIC_ADAPTATION_STATE_KEY: IRepertoireStateKeys = "lyricAdaptation";
export const WORK_PARTS_STATE_KEY: IRepertoireStateKeys = "workPart";
export const GENRE_CATEGORY_STATE_KEY: IRepertoireStateKeys = 'genreCategory';
export const DISTRIBUTION_CATEGORY_STATE_KEY: IRepertoireStateKeys = 'distributionCategory';
export const WORK_CREATION_DATE_STATE_KEY: IRepertoireStateKeys = "workCreationDate";
export const WORK_IS_MAINTAINED_LIBRARY_WORK_STATE_KEY: IRepertoireStateKeys = "isMaintainedLibraryWork";
export const WORK_PRICE: IRepertoireStateKeys = "workPrice";
export const WORK_EDITION: IRepertoireStateKeys = "workEdition";
//Work Attachment 
export const WORK_ATTACHEMENT_STATE_KEY: string = 'AttachmentWorkMaintenance';

//WorkNumber accordian dropdown filter criteria 
export const WORKNUMBER_CORE_OR_EXTERNAL_CORE: string = "CORE"
export const WORKNUMBER_CORE_OR_EXTERNAL_BOTH: string = "BOTH"

//editable fields modal
export const FIELD_LABELS: IRepertoireStateKeys = "fieldLabels";
export const IS_HIDDEN_FIELD: IRepertoireStateKeys = "isHiddenField";
export const SEQUENCE_FIELD: IRepertoireStateKeys = "sequence";
export const ORDER_GRID_FIELD_METADATA: IRepertoireStateKeys = "order";
export const EDITABLE_FIELDS: IRepertoireStateKeys = "editableFields";
export const AV_WORK_DETAILS_EDITABLE_FIELDS: IRepertoireStateKeys = "avWorkDetailsEditableFields";
export const WORK_SEARCH_EDITABLE_FIELDS: IRepertoireStateKeys = "workSearchEditableFields";
export const GENERAL_DATA_EDITABLE_FIELDS: IRepertoireStateKeys = "generalDataEditableFields";
export const EDITABLE_FIELDS_GRID: IRepertoireStateKeys = "editableFieldsGrid";
export const TITLE_EDITABLE_FIELDS_VIEW: IRepertoireStateKeys = "titleEditableFieldsView";
export const USAGE_DESCRIPTION_EDITABLE_VIEW: IRepertoireStateKeys = "usageDescription";
export const CANCEL_BUTTON_TEXT_FIELD: IRepertoireStateKeys = "cancelButtonText"
export const SAVE_BUTTON_TEXT_FIELD: IRepertoireStateKeys = "saveButtonText"
export const ON_VALUE = "on";
export const OFF_VALUE = "off";
export const USAGE_SEARCH_EDITABLE_FIELDS: IRepertoireStateKeys = "usageSearchEditableFields";

//Other work contributor
export const WORK_OTHERCONTRIBUTOR_SOURCETYPE_STATE_KEY: IRepertoireStateKeys = "otherContributorSourceType";
export const WORK_OTHERCONTRIBUTOR_SOURCEIP_STATE_KEY: IRepertoireStateKeys = "otherContributorSourceIP";
export const WORK_OTHERCONTRIBUTOR_SOURCESOCIETY_STATE_KEY: IRepertoireStateKeys = "otherContributorSourceSociety";

//workMaintenance toolbar
export const COPY_WORK_STATE_KEY: IRepertoireStateKeys = "copyWork";
export const UNDO_CHANGES_STATE_KEY: IRepertoireStateKeys = "undoChanges";
export const MERGE_WORK_STATE_KEY: IRepertoireStateKeys = "mergeWork";
export const DELETE_WORK_STATE_KEY: IRepertoireStateKeys = "deleteWork";
export const ADD_NEW_WORK_STATE_KEY: IRepertoireStateKeys = "addNewWork";
export const SAVE_CHANGES_STATE_KEY: IRepertoireStateKeys = "saveChanges";
export const POST_TO_REPERTOIRE_STATE_KEY: IRepertoireStateKeys = "postToRepertoirePrompt";
export const DELETE_WORK_PROMPT_STATE_KEY: IRepertoireStateKeys = "deleteWorkPrompt";
export const DELETE_AGREEMENT_PROMPT_STATE_KEY: IRepertoireStateKeys = "deleteAgreementPrompt";
export const VERSION_HISTORY_STATE_KEY: IRepertoireStateKeys = "versionHistory";
export const AUTO_MATCH_WORK_STATE_KEY: IRepertoireStateKeys = "autoMatchWork";
export const MERGE_WORK_PROMPT_STATE_KEY: IRepertoireStateKeys = "mergeWorkPrompt";
export const MERGE_WORK_WARNING_PROMPT_STATE_KEY: IRepertoireStateKeys = "mergeWorkWarningPrompt";
export const MERGE_IP_PROMPT_STATE_KEY: IRepertoireStateKeys = "mergeIPPrompt";
export const MERGE_IP_WARNING_PROMPT_STATE_KEY: IRepertoireStateKeys = "mergeIPWarningPrompt";
export const ADD_TO_PACKAGE_KEY:IRepertoireStateKeys="addtoPackage";

//avWorkDetails accordian
export const AVDETAILS_TERRITORY_STATE_KEY: IRepertoireStateKeys = "territory";
export const AVDETAILS_CATEGORY_STATE_KEY: IRepertoireStateKeys = "category";
export const AVDETAILS_VERSION_CATEGORY_STATE_KEY: IRepertoireStateKeys = "versionCategory";
export const AVDETAILS_SERIES_IDENTIFIER_STATE_KEY: IRepertoireStateKeys = "seriesIdentifier";
export const AVDETAILS_PRODUCTION_NUMBER_STATE_KEY: IRepertoireStateKeys = "productionNumber";
export const AVDETAILS_SOCIETY_SERIES_NUMBER_KEY: IRepertoireStateKeys = "societySeriesNumber";
export const AVDETAILS_EPISODE_NUMER_STATE_KEY: IRepertoireStateKeys = "episodeNumber";
export const AVDETAILS_YEAR_OF_PRODUCTION_STATE_KEY: IRepertoireStateKeys = "yearOfProduction";
export const AVDETAILS_TOTAL_DURATION_STATE_KEY: IRepertoireStateKeys = "totalDuration";
export const AVDETAILS_MUSIC_DURATION_STATE_KEY: IRepertoireStateKeys = "musicDuration";
export const AVDETAILS_AIR_DATE_STATE_KEY: IRepertoireStateKeys = "airDate";
export const AVDETAILS_AIRED_UNTIL_STATE_KEY: IRepertoireStateKeys = "airedUntil";
export const AVDETAILS_TYPE_STATE_KEY: IRepertoireStateKeys = "type";
export const AVDETAILS_CUE_SHEET_TYPE_STATE_KEY: IRepertoireStateKeys = "cueSheetType";
export const AVDETAILS_DESTINATION_STATE_KEY: IRepertoireStateKeys = "destination";
export const AVDETAILS_COUNTRY_OF_ORIGIN_STATE_KEY: IRepertoireStateKeys = "countryOfOrigin";
export const AVDETAILS_VERSION_TERRITORY_STATE_KEY: IRepertoireStateKeys = "versionTerritory";
export const AVDETAILS_CODE_OF_REVISION_STATE_KEY: IRepertoireStateKeys = "codeOfRevision";
export const AVDETAILS_DATE_OF_REVISION_STATE_KEY: IRepertoireStateKeys = "dateOfRevision";
export const AVDETAILS_DATE_OF_SUBMISSION_STATE_KEY: IRepertoireStateKeys = "dateOfSubmission";
export const AVDETAILS_V_ISAN_STATE_KEY: IRepertoireStateKeys = "vIsan";
export const AVDETAILS_COMMERICIAL_BRAND_STATE_KEY: IRepertoireStateKeys = "commercialBrand";
export const AVDETAILS_COMMERCIAL_PRODUCT_STATE_KEY: IRepertoireStateKeys = "commercialProduct";
export const AVDETAILS_COMMERCIAL_TYPE_STATE_KEY: IRepertoireStateKeys = "commercialType";
export const AVDETAILS_COMMERCIAL_AGENCY_STATE_KEY: IRepertoireStateKeys = "commercialAgency";
export const AVDETAILS_COMMERCIAL_FORM_STATE_KEY: IRepertoireStateKeys = "commercialForm";
export const AVDETAILS_PRODUCTION_STATE_KEY: IRepertoireStateKeys = "production";
export const AVDETAILS_SUBMITTER_PERCENT_STATE_KEY: IRepertoireStateKeys = "submitterInterestPercent";
export const AVDETAILS_SOCIETY_OF_ORIGINAL_MUSIC_STATE_KEY: IRepertoireStateKeys = "societyOfOriginalMusic";

//avSubmissionDetails accordian
export const AVSUBMISSION_REQUEST_DETAILS_HEADER_STATE_KEY: IRepertoireStateKeys = "requestDetailsHeader";
export const AVSUBMISSION_REQUEST_BATCH_ID_STATE_KEY: IRepertoireStateKeys = "requestBatchID";
export const AVSUBMISSION_AV_STATUS_STATE_KEY: IRepertoireStateKeys = "requestAVStatus";
export const AVSUBMISSION_REQUEST_DATE_STATE_KEY: IRepertoireStateKeys = "requestDate";
export const AVSUBMISSION_RECEIVED_DATE_STATE_KEY: IRepertoireStateKeys = "receivedDate";
export const AVSUBMISSION_REQUEST_THIS_CUE_SHEET_HEADER_STATE_KEY: IRepertoireStateKeys = "reqeustThisCueSheetHeader";
export const AVSUBMISSION_REQUIRED_BY_DATE_STATE_KEY: IRepertoireStateKeys = "requiredByDate";
export const AVSUBMISSION_URGENT_STATE_KEY: IRepertoireStateKeys = "urgent";
export const AVSUBMISSION_REQUEST_ACTION_STATE_KEY: IRepertoireStateKeys = "request";

export const AVSUBMISSION_SUBMISSION_STATE_KEY: IRepertoireStateKeys = "productAVWorkSubmissions";

//agreementMaintenance toolbar
export const COPY_AGREEMENT_STATE_KEY: IRepertoireStateKeys = "copyAgreement";
export const DELETE_AGREEMENT_STATE_KEY: IRepertoireStateKeys = "deleteAgreement";
export const ADD_NEW_AGREEMENT_STATE_KEY: IRepertoireStateKeys = "addNewAgreement";
export const ADD_TO_PACKAGE_AGREEMENT_STATE_KEY: IRepertoireStateKeys = "addtoPackageAgreement";

//interestedPartyMaintenance toolbar
export const ADD_NEW_ACCOUNT_STATE_KEY: IRepertoireStateKeys = "addNewAccount";
export const DELETE_ACCOUNT_STATE_KEY: IRepertoireStateKeys = "deleteAccount";
export const UNDO_CHANGES_ACCOUNT_KEY: IRepertoireStateKeys = "undoChanges";
export const DELETE_IP_PROMPT_STATE_KEY: IRepertoireStateKeys = "deleteAccountPrompt"
export const ADD_NEW_REPRESENTATION_STATE_KEY: IRepertoireStateKeys = "addNewRepresentation";
export const CRM_EXTENDED_DETAILS_STATE_KEY: IRepertoireStateKeys = "crmExtendedDetails";
export const MERGE_IP_ACCOUNT_KEY: IRepertoireStateKeys = "mergeIP";
//productMaintenance toolbar
export const COPY_PRODUCT_STATE_KEY: IRepertoireStateKeys = "copyProduct";
export const MERGE_PRODUCT_STATE_KEY: IRepertoireStateKeys = "mergeProduct";
export const MERGE_PRODUCT_PROMPT_STATE_KEY: IRepertoireStateKeys = "mergeProductPrompt";
export const MERGE_PRODUCT_SINGLE_ERROR_STATE_KEY: IRepertoireStateKeys = "mergeSingleProductError";
export const MERGE_PRODUCT_MULTIPLE_ERROR_STATE_KEY: IRepertoireStateKeys = "mergeMultipleProductError";
export const DELETE_PRODUCT_STATE_KEY: IRepertoireStateKeys = "deleteProduct";
export const ADD_NEW_PRODUCT_STATE_KEY: IRepertoireStateKeys = "addNewProduct";
export const DELETE_PRODUCT_PROMPT_STATE_KEY: IRepertoireStateKeys = "deleteProductPrompt";
export const UNDO_CHANGES_PRODUCT_STATE_KEY: IRepertoireStateKeys = "undoChanges";
export const AUTO_MATCH_PRODUCT_STATE_KEY: IRepertoireStateKeys = "autoMatchProduct";
export const ADD_TO_PACKAGE_PRODUCT_STATE_KEY: IRepertoireStateKeys = "addtoPackageProduct";
//artistMaintenance toolbar
export const ADD_NEW_ARTIST_STATE_KEY: IRepertoireStateKeys = "addNewArtist";
export const DELETE_ARTIST_STATE_KEY: IRepertoireStateKeys = "deleteArtist";
export const UNDO_CHANGES_ARTIST_KEY: IRepertoireStateKeys = "undoChanges";
export const DELETE_ARTIST_PROMPT_STATE_KEY: IRepertoireStateKeys = "deleteArtistPrompt"
//poolMaintenance toolbar
export const COPY_POOL_STATE_KEY: IRepertoireStateKeys = "copyPool";
export const DELETE_POOL_STATE_KEY: IRepertoireStateKeys = "deletePool";
export const UNDO_CHANGES_POOL_STATE_KEY: IRepertoireStateKeys = "undoChanges";
export const DELETE_POOL_PROMPT_STATE_KEY: IRepertoireStateKeys = "deletePool";
//adjustmentMaintenance toolbar
export const COPY_ADJUSTMENT_STATE_KEY: IRepertoireStateKeys = "copyAdjustment";
export const UNDO_ADJUSTMENT_CHANGES_STATE_KEY: IRepertoireStateKeys = "undoChanges";
//PPU License toolbar
export const COPY_LICENSE_STATE_KEY: IRepertoireStateKeys = "copyLicense";;
export const DELETE_LICENSE_STATE_KEY: IRepertoireStateKeys = "deleteLicense";
export const ADD_NEW_LICENSE_STATE_KEY: IRepertoireStateKeys = "addNewLicense";
export const UNDO_CHANGES_LICENSE_STATE_KEY: IRepertoireStateKeys = "undoChanges";


//AgreementMaintenance
export const IP_BASE_NUMBER_STATE_KEY: IRepertoireStateKeys = "ipBaseNumber";
export const AGREEMENT_LAST_MODIFIED_DATE_STATE_KEY: IRepertoireStateKeys = "lastModifiedDate";
export const AGREEMENT_CREATION_DATE_STATE_KEY: IRepertoireStateKeys = "creationDate";
export const IP_NAME_STATE_KEY: IRepertoireStateKeys = "ipName";
export const PERFORMING_SOCIETY_STATE_KEY: IRepertoireStateKeys = "performingSociety";
export const SOURCE_TEXT_STATE_KEY: IRepertoireStateKeys = "sourceText";
export const TYPE_STATE_KEY: IRepertoireStateKeys = "type";
export const AGREEMENT_CATEGORY_STATE_KEY: IRepertoireStateKeys = "agreementCategory";
export const AGREEMENT_SOURCE_TYPE_STATE_KEY: IRepertoireStateKeys = "agreementSourceType";
export const AGREEMENT_SOURCE_IP_STATE_KEY: IRepertoireStateKeys = "agreementSourceIP";
export const AGREEMENT_SOURCE_IPI_NAME_NUMBER_STATE_KEY: IRepertoireStateKeys = "agreementSourceIPINameNumber";
export const AGREEMENT_MAINTAINED_STATE_KEY: IRepertoireStateKeys = "maintained";
export const APPLICABLE_WORKS_TYPE_STATE_KEY: IRepertoireStateKeys = "applicableWorksType";
export const AGREEMENT_CARVEOUTS_TYPE_STATE_KEY: IRepertoireStateKeys = "agreementCarveOutsType";
export const WORKS_RETAINED_STATE_KEY: IRepertoireStateKeys = "worksRetained";
export const BULK_TRANSFER_WORKS_STATE_KEY: IRepertoireStateKeys = "bulkTransferWorks";
export const SPECIFIC_AGREEMENT_TYPE_STATE_KEY: IRepertoireStateKeys = "specificAgreementType";
export const AGREEMENT_SIGNATURE_DATE_STATE_KEY: IRepertoireStateKeys = "agreementSignatureDate";
export const PRIOR_ROYALTIES_STATE_KEY: IRepertoireStateKeys = "priorRoyalties";
export const EFFECTIVE_NOTIFIED_FROM_STATE_KEY: IRepertoireStateKeys = "effectiveNotifiedFrom";
export const EFFECTIVE_NOTIFIED_TO_STATE_KEY: IRepertoireStateKeys = "effectiveNotifiedTo";
export const LICENSED_DIRECT_STATE_KEY: IRepertoireStateKeys = "licensedDirect";
export const EFFECTIVE_FROM_STATE_KEY: IRepertoireStateKeys = "effectiveFrom";
export const EFFECTIVE_TO_STATE_KEY: IRepertoireStateKeys = "effectiveTo";
export const EXPECTED_TERMINATION_STATE_KEY: IRepertoireStateKeys = "expectedTermination";
export const EXPECTED_TERMINATION_TEXT_STATE_KEY: IRepertoireStateKeys = "expectedTerminationText";
export const POST_TERM_RETENTION_PERIOD_STATE_KEY: IRepertoireStateKeys = "postTermRetentionPeriod";
export const DEFAULT_RETAINED_UNTIL_STATE_KEY: IRepertoireStateKeys = "defaultRetainedUntil";
export const DEFAULT_RETENTION_END_DATE_STATE_KEY: IRepertoireStateKeys = "defaultRetentionEndDate";
export const POST_TERM_COLLECTION_PERIOD_STATE_KEY: IRepertoireStateKeys = "postTermCollectionPeriod";
export const DEFAULT_COLLECTION_UNTIL_STATE_KEY: IRepertoireStateKeys = "defaultCollectionUntil";
export const DEFAULT_COLLECTION_END_DATE_STATE_KEY: IRepertoireStateKeys = "defaultCollectionPeriodEndDate";
export const SALES_OR_MANUFACTURE_STATE_KEY: IRepertoireStateKeys = "salesOrManufacture";
export const LIBRARY_MUSIC_STATE_KEY: IRepertoireStateKeys = "libraryMusic";
export const RECORDING_PREFIX_STATE_KEY: IRepertoireStateKeys = "recordingPrefix";
export const SET_TYPE_GROUP_STATE_KEY: IRepertoireStateKeys = "setTypeGroup";
export const ASSIGNED_SET_TYPES_STATE_KEY: IRepertoireStateKeys = "assignedSetTypes";
export const AGREEMENT_SHARE_SETS_STATE_KEY: IRepertoireStateKeys = "agreementShareSet";
export const AGREEMENT_REGION_STATE_KEY: IRepertoireStateKeys = "agreementRegion";
export const AGREEMENT_POOLS_STATE_KEY: IRepertoireStateKeys = "agreementUsagePools";
export const ADMINISTERED_BY_IP_BASE_NUMBER_STATE_KEY: IRepertoireStateKeys = "administeredByIPBaseNumber";
export const APPLICABLE_WORK_NAME_STATE_KEY: IRepertoireStateKeys = "workName";
export const AGREEMENT_CARVEOUTS_PRODUCT_NAME_STATE_KEY: IRepertoireStateKeys = "productName";
export const MATCHING_REPERTOIRE_WORK_NAME_STATE_KEY: IRepertoireStateKeys = "name";
export const CURRENCY_STATE_KEY: IRepertoireStateKeys = "currency";
export const AGREEMENT_POOL_OPTION_STATE_KEY : IRepertoireStateKeys = "carveOutPool";
export const WORLD_EXCLUDING_STATE_KEY: IRepertoireStateKeys = "worldExcluding";
export const SET_TYPE_GROUP_EXCLUDING_STATE_KEY: IRepertoireStateKeys = "setTypeGroupExcluding";


// Agreement Attachment
export const AGREEMENT_ATTACHEMENT_STATE_KEY: string = 'attachments';


// InterestedPartyMaintenance
export const IP_IPBASENUMBER_STATE_KEY: IRepertoireStateKeys = "ipBaseNumber";
export const IP_BIRTHDATE_STATE_KEY: IRepertoireStateKeys = "birthDate";
export const IP_DEATHDATE_STATE_KEY: IRepertoireStateKeys = "deathDate";
export const IP_STATUS_STATE_KEY: IRepertoireStateKeys = "status";
export const IP_REFERENCED_IP_STATE_KEY: IRepertoireStateKeys = "referencedIP";
export const IP_SOCIETY_NAME_STATE_KEY: IRepertoireStateKeys = "societyName";
export const IP_TYPE_STATE_KEY: IRepertoireStateKeys = "type";
export const IP_ACCOUNT_NUMBER_STATE_KEY: IRepertoireStateKeys = "accountNumber";
export const IP_SUB_ACCOUNT_STATE_KEY: IRepertoireStateKeys = "subAccount";
export const IP_LAST_NAME_STATE_KEY: IRepertoireStateKeys = "lastName";
export const IP_ROLE_STATE_KEY: IRepertoireStateKeys = "role";
export const IP_SECOND_LAST_NAME_STATE_KEY: IRepertoireStateKeys = "secondLastName";
export const IP_FIRST_NAME_STATE_KEY: IRepertoireStateKeys = "firstName";
export const IP_ACCOUNT_TYPE_STATE_KEY: IRepertoireStateKeys = "accountType";
export const IP_DEFAULT_SOCIETY_OF_LICENSE_STATE_KEY: IRepertoireStateKeys = "defaultSocietyOfLicense";
export const IP_RANGE_IP_AGREEMENTS_FILTER: string = "now";
export const ECONOMIC_RIGHT_DESCRIPTION: string = 'economicRightDescription';
export const ECONOMIC_RIGHT: string = 'economicRights';
export const IP_TRANSFER_STATE_KEY: IRepertoireStateKeys = "ipTransfer";
export const IP_TRANSFER_VIEW: string = "IPTransferView";
export const IP_MERGE_STATE_KEY: string = "mergeThisIP";


// IP Representation
export const IP_REPRESENTATION_PARENT_STATE_KEY: IRepertoireStateKeys = "parent";
export const IP_REPRESENTATION_REPRESENTATION_GROUP_STATE_KEY: IRepertoireStateKeys = "representationGroup";
export const IP_REPRESENTATION_WORK_GROUP_STATE_KEY: IRepertoireStateKeys = "workGroup";
export const IP_REPRESENTATION_WORK_GROUP_TYPE_STATE_KEY: IRepertoireStateKeys = "workGroupType";
export const IP_REPRESENTATION_USE_STATE_KEY: IRepertoireStateKeys = "use";
export const IP_REPRESENTATION_REPRESENTATION_TYPE_STATE_KEY: IRepertoireStateKeys = "representationType";
export const IP_REPRESENTATION_EXCLUDED_STATE_KEY: IRepertoireStateKeys = "excluded";
export const IP_REPRESENTATION_START_DATE_STATE_KEY: IRepertoireStateKeys = "startDate";
export const IP_REPRESENTATION_END_DATE_STATE_KEY: IRepertoireStateKeys = "endDate";
export const IP_REPRESENTATION_DESCRIPTION_STATE_KEY: IRepertoireStateKeys = "description";

// ProductMaintenance
export const PRODUCT_MAINTAINED_STATE_KEY: IRepertoireStateKeys = "maintained";
export const MERGE_THIS_PRODUCT_STATE_KEY: IRepertoireStateKeys = "mergeThisProduct";
export const PRODUCT_TYPE_STATE_KEY: IRepertoireStateKeys = "productType";
export const PRODUCT_ID1_STATE_KEY: IRepertoireStateKeys = "id1";
export const PRODUCT_ID2_STATE_KEY: IRepertoireStateKeys = "id2";
export const PRODUCT_SOURCE_STATE_KEY: IRepertoireStateKeys = "source";
export const PRODUCT_RELEASED_DATE_STATE_KEY: IRepertoireStateKeys = "released";
export const PRODUCT_DURATION_STATE_KEY: IRepertoireStateKeys = "duration";
export const PRODUCT_LAST_MODIFIED_DATE_STATE_KEY: IRepertoireStateKeys = "lastModifiedDate";
export const PRODUCT_CONTRIBUTOR_NAME_STATE_KEY: IRepertoireStateKeys = "name";
export const PRODUCT_PERFORMER_NAME_STATE_KEY: IRepertoireStateKeys = "performerName";
export const MATCHING_REPERTOIRE_PRODUCT_NAME_STATE_KEY: IRepertoireStateKeys = "name";
export const PRODUCT_SOURCE_TYPE_STATE_KEY: IRepertoireStateKeys = "sourceType";
export const PRODUCT_SOURCE_IP_STATE_KEY: IRepertoireStateKeys = "sourceIP";
export const PRODUCT_SOURCE_IPI_NUMBER_STATE_KEY: IRepertoireStateKeys = "sourceIPINumber";
export const PRODUCT_STATUS_KEY: IRepertoireStateKeys = "statusType";
export const CUE_SHEET_TYPE: string = "CUE SHEET";
export const ADVERTISEMENT_TYPE: string = "ADVERTISEMENT";
export const RECORDING_TYPE: string = "RECORDING";
export const SET_LIST_TYPE: string = "SET LIST";
export const INTRAY_WORK_COUNT_STATE_KEY = "intrayWorkCount";
export const REPERTOIRE_WORK_COUNT_STATE_KEY = "repertoireWorkCount";
// ArtistMaintenance
export const ARTIST_FIRST_NAME_STATE_KEY: IRepertoireStateKeys = "firstName";
export const ARTIST_LAST_NAME_STATE_KEY: IRepertoireStateKeys = "lastName";
export const ARTIST_TYPE_STATE_KEY: IRepertoireStateKeys = "type";
// PoolMaintenance
export const POOL_POOL_CODE_STATE_KEY: IRepertoireStateKeys = "poolCode";
export const POOL_DESCRIPTION_STATE_KEY: IRepertoireStateKeys = "description";
export const POOL_CAT_1_STATE_KEY: IRepertoireStateKeys = "cat1";
export const POOL_CAT_2_STATE_KEY: IRepertoireStateKeys = "cat2";
export const POOL_INVOICE_STATE_KEY: IRepertoireStateKeys = "invoice";
export const POOL_ENABLED_STATE_KEY: IRepertoireStateKeys = "weightSettingsIsEnabled";
export const POOL_EVPP_ENABLED_STATE_KEY: IRepertoireStateKeys = "evppSettingsIsEnabled";
export const POOL_PAYMENT_STATE_KEY: IRepertoireStateKeys = "payment";
export const POOL_ALLOCATION_RULE_STATE_KEY: IRepertoireStateKeys = "allocationRule";
export const POOL_DISTRIBUTION_CATEGORY_TYPE_STATE_KEY: IRepertoireStateKeys = "distributionCategoryType";
export const POOL_LICENSEE_ID_STATE_KEY: IRepertoireStateKeys = "licenseeID";
export const POOL_UNIDENTIFIED_USAGE_STATE_KEY: IRepertoireStateKeys = "unidentifiedUsage";
export const POOL_NON_SOCIETY_IPS_STATE_KEY: IRepertoireStateKeys = "nonSocietyIPs";
export const POOL_REFERENCE_IPS_STATE_KEY: IRepertoireStateKeys = "referenceIPs";
export const POOL_MISSING_SHARES_STATE_KEY: IRepertoireStateKeys = "missingShares";
export const POOL_WARSAW_RULE_STATE_KEY: IRepertoireStateKeys = "warsawRule";
export const POOL_PERCENTAGE_DURATION_STATE_KEY: IRepertoireStateKeys = "percentageDurationForUnidentifiedUsage";
export const POOL_CUSTOM_NOTEBOOK_STATE_KEY: IRepertoireStateKeys = "customNotebook";
export const POOL_ALLOCATE_SPECIFIC_ROLES_STATE_KEY: IRepertoireStateKeys = "allocateSpecificRoles";
export const POOL_ALLOCATE_SPECIFIC_SOCIETIES_STATE_KEY: IRepertoireStateKeys = "allocateSpecificSocieties";
export const POOL_DEFAULT_DURATION_PERCENTAGE_STATE_KEY: IRepertoireStateKeys = "defaultDurationPercentage";
export const POOL_RECIPROCAL_AGREEMENTS_STATE_KEY: IRepertoireStateKeys = "reciprocalAgreements";
export const POOL_PD_WORK_PERCENT_STATE_KEY: IRepertoireStateKeys = "pdWork100Percent";
export const POOL_PD_IPS_STATE_KEY: IRepertoireStateKeys = "pdiPs";
export const POOL_IS_FOREIGN_STATE_KEY: IRepertoireStateKeys = "isForeign";
export const USE_ADVERTISEMENT_DURATION_FOR_MATCHED_USAGE: IRepertoireStateKeys = "useAdvertisementDurationForMatchedUsage";
export const USE_CUE_SHEET_DURATION_FOR_MATCHED_USAGE: IRepertoireStateKeys = "useCueSheetDurationForMatchedUsage";

// RepertoireSideMenu
export const REPERTOIRE_SIDE_MENU_WORKS_KEY: IRepertoireStateKeys = "works";
export const REPERTOIRE_SIDE_AGREEMENTS_KEY: IRepertoireStateKeys = "agreements";
export const REPERTOIRE_SIDE_MENU_IPS_KEY: IRepertoireStateKeys = "ips";
export const REPERTOIRE_SIDE_MENU_PRODUCTS_KEY: IRepertoireStateKeys = "products";
export const REPERTOIRE_SIDE_MENU_WORKFLOWS_KEY: IRepertoireStateKeys = "workflows";
export const REPERTOIRE_SIDE_MENU_ARTISTS_KEY: IRepertoireStateKeys = "artists";
export const REPERTOIRE_SIDE_MENU_USAGE_KEY: IRepertoireStateKeys = "usage";
export const REPERTOIRE_SIDE_MENU_USAGE_POOLS_KEY: IRepertoireStateKeys = "usagePools";
export const REPERTOIRE_SIDE_MENU_USAGE_DISTRIBUTIONS_KEY: IRepertoireStateKeys = "distributions";
export const REPERTOIRE_PAGE_REPERTOIRE_OPTIONS_BUTTON_KEY: IRepertoireStateKeys = "repertoireOptions";
export const REPERTOIRE_SIDE_MENU_USAGE_PAYMENTRUN_KEY: IRepertoireStateKeys = "paymentRuns";
export const REPERTOIRE_SIDE_MENU_LICENSES_KEY: IRepertoireStateKeys = "licenses";
export const REPERTOIRE_SIDE_MENU_ADJUSTMENTS_KEY: IRepertoireStateKeys = "adjustments";

//WorkMaintenance
export const TABLE_ACTIONS_INSTANCE_KEY: string = "table_actions";

// Distributions
export const DISTRIBUTION_ID_STATE_KEY: IRepertoireStateKeys = "distributionID";
export const DISTRIBUTION_CODE_STATE_KEY: IRepertoireStateKeys = "distributionCode";
export const DISTRIBUTION_TYPE_STATE_KEY: IRepertoireStateKeys = "distributionType";
export const DISTRIBUTION_SUB_TYPE_STATE_KEY: IRepertoireStateKeys = "distributionSubType";
export const DISTRIBUTION_STATUS_STATE_KEY: IRepertoireStateKeys = "distributionStatus";
export const DISTRIBUTION_CAT1_STATE_KEY: IRepertoireStateKeys = "cat1";
export const DISTRIBUTION_CAT2_STATE_KEY: IRepertoireStateKeys = "cat2";
export const DISTRIBUTION_DATE_STATE_KEY: IRepertoireStateKeys = "distributionDate";
export const DISTRIBUTION_DESCRIPTION_STATE_KEY: IRepertoireStateKeys = "description";
export const DISTRIBUTION_STATEMENT_DESCRIPTION_STATE_KEY: IRepertoireStateKeys = "statementDescription";
export const DISTRIBUTION_USAGE_FROM_STATE_KEY: IRepertoireStateKeys = "usageFrom";
export const DISTRIBUTION_USAGE_TO_STATE_KEY: IRepertoireStateKeys = "usageTo";
export const DISTRIBUTION_LIVE_PERFORMANCE_STATE_KEY: IRepertoireStateKeys = "isLivePerformancePortal";
export const DISTRIBUTION_CLAIMS_FROM_STATE_KEY: IRepertoireStateKeys = "claimsFrom";
export const DISTRIBUTION_CLAIMS_TO_STATE_KEY: IRepertoireStateKeys = "claimsTo";
export const DELETE_DISTRIBUTION_STATE_KEY: IRepertoireStateKeys = "deleteDistribution";
export const UNDO_CHANGES_DISTRIBUTION_STATE_KEY: IRepertoireStateKeys = "undoChanges";
export const DELETE_DISTRIBUTION_PROMPT_STATE_KEY: IRepertoireStateKeys = "deletePoolPrompt";
export const ADD_NEW_DISTRIBUTION_STATE_KEY: IRepertoireStateKeys = "addNewDistribution";
export const DISTRIBUTION_POOL_CODE_STATE_KEY: IRepertoireStateKeys = "poolCode";
export const DISTRIBUTION_POOL_DESCRIPTION_STATE_KEY: IRepertoireStateKeys = "description";
export const ALLOCATE_POOLS_DISTRIBUTION_STATE_KEY: IRepertoireStateKeys = "allocate";
export const SET_CD_ALLOCATION_SCHEDULE_STATE_KEY: IRepertoireStateKeys = "setCDAllocationSchedule";
export const UNDO_ALLOCATION_POOLS_DISTRIBUTION_STATE_KEY: IRepertoireStateKeys = "undoAllocation";
export const RUN_QA_POOLS_DISTRIBUTION_STATE_KEY: IRepertoireStateKeys = "runQA";
export const UNDO_QA_POOLS_DISTRIBUTION_STATE_KEY: IRepertoireStateKeys = "undoQA";
export const DISTRIBUTION_POOL_SELECTION_CRITERIA_STATE_KEY: IRepertoireStateKeys = "selectionCriteria";
export const DISTRIBUTION_POOL_DATE_RANGE_SELECTION_CRITERIA_STATE_KEY: IRepertoireStateKeys = "dateRangeSelectionCriteria";
export const DISTRIBUTION_EXPORT_STATE_KEY: IRepertoireStateKeys = "exportPools";
export const DISTRIBUTION_IMPORT_STATE_KEY: IRepertoireStateKeys = "importPools";
export const DISTRIBUTION_RESET_CACHE_STATE_KEY: IRepertoireStateKeys = "resetCache";
export const COPY_DISTRIBUTION_STATE_KEY: IRepertoireStateKeys = "copyDistribution";
export const ALLOCATE_POOLS_CONFIRMATION_STATE_KEY: IRepertoireStateKeys = "allocationConfirmationMessage";
export const UNSAVED_ALLOCATE_STATE_KEY : IRepertoireStateKeys = "unsavedAllocationMessage";
export const UNDO_ALLOCATE_POOLS_CONFIRMATION_STATE_KEY: IRepertoireStateKeys = "undoAllocationConfirmationMessage";
export const DISTRIBUTION_DISCOUNT_TYPE_STATE_KEY: IRepertoireStateKeys = "discountType";
export const DISTRIBUTION_CLAIMABLE_STATE_KEY: IRepertoireStateKeys = "claimable";
export const QA_POOLS_CONFIRMATION_STATE_KEY: IRepertoireStateKeys = "runQAPoolsConfirmationMessage"
export const REVERT_QA_POOLS_CONFIRMATION_STATE_KEY: IRepertoireStateKeys = "revertQAPoolsConfirmationMessage"

// Adjustments
export const ADJUSTMENT_ID_STATE_KEY: IRepertoireStateKeys = "id";
export const ADJUSTMENT_STATUS_STATE_KEY: IRepertoireStateKeys = "adjustmentStatus";
export const ADJUSTMENT_CATEGORY_STATE_KEY: IRepertoireStateKeys = "adjustmentCategory";
export const ADJUSTMENT_DATE_STATE_KEY: IRepertoireStateKeys = "adjustmentDate";
export const ADJUSTMENT_SOURCE_DISTRIBUTION_STATE_KEY: IRepertoireStateKeys = "sourceDistribution";
export const ADJUSTMENT_SOURCE_POOL_STATE_KEY: IRepertoireStateKeys = "sourcePool";
export const ADJUSTMENT_SOURCE_SEGMENT_STATE_KEY: IRepertoireStateKeys = "sourceSegment";
export const ADJUSTMENT_ADJUSTMENT_DISTRIBUTION_STATE_KEY: IRepertoireStateKeys = "adjustmentDistribution";
export const ADJUSTMENT_ADJUSTMENT_POOL_STATE_KEY: IRepertoireStateKeys = "adjustmentPool";
export const ADJUSTMENT_ADJUSTMENT_SEGMENT_STATE_KEY: IRepertoireStateKeys = "adjustmentSegment";
export const ADJUSTMENT_NARRATIVE_STATE_KEY: IRepertoireStateKeys = "narrative";
export const ADJUSTMENT_BATCH_ID_STATE_KEY: IRepertoireStateKeys = "batchId";
export const ADJUSTMENT_UNDO_CHANGES_STATE_KEY: IRepertoireStateKeys = "undoChanges";
export const ADJUSTMENT_COPY_ADJUSTMENT_STATE_KEY: IRepertoireStateKeys = "copyAdjustment";
export const ADJUSTMENT_ADD_NEW_STATE_KEY: IRepertoireStateKeys = "addNewAdjustment";
export const ADJUSTMENT_SELECT_ALLOCATIONS: IRepertoireStateKeys = "allocationSelection";
export const ADJUSTMENT_SEARCH_WORK_NUMBERS: IRepertoireStateKeys = "workNumberSearch";
export const ADJUSTMENT_SEARCH_IP_NUMBERS: IRepertoireStateKeys = "ipNumberSearch";
export const ADJUSTMENT_SEARCH_PRODUCT_NUMBERS: IRepertoireStateKeys = "productNumberSearch";
export const ALLOCATION_HEADER_ID: IRepertoireStateKeys = "allocationHeaderID";
export const ALLOCATION_WORK_NUMBER: IRepertoireStateKeys = "workNumber";
export const ALLOCATION_WORK_NAME: IRepertoireStateKeys = "workName";
export const ALLOCATION_WEIGHT: IRepertoireStateKeys = "allocationWeight";
export const ALLOCATION_TOTAL_ALLOCATED: IRepertoireStateKeys = "totalAmountAllocated";
export const ALLOCATION_ACTION: IRepertoireStateKeys = "action";
export const ALLOCATION_ACCOUNT_NUMBER: IRepertoireStateKeys = "accountNumber";
export const ALLOCATION_IP_NAME: IRepertoireStateKeys = "name";
export const ALLOCATION_IPI_NUMBER: IRepertoireStateKeys = "ipiNumber";
export const ALLOCATION_BASE_NUMBER: IRepertoireStateKeys = "ipBaseNumber";
export const ALLOCATION_SHARE: IRepertoireStateKeys = "sharePct";
export const ALLOCATION_POINTS_ALLOCATED: IRepertoireStateKeys = "pointsAllocated";
export const ALLOCATION_AMOUNT_ALLOCATED: IRepertoireStateKeys = "amountAllocated";
export const ALLOCATION_DISTRIBUTION: IRepertoireStateKeys = "distribution";
export const ALLOCATION_POOL: IRepertoireStateKeys = "pool";
export const ALLOCATION_SEGMENT: IRepertoireStateKeys = "segment";
export const ALLOCATION_SHARE_ADJUSTED: IRepertoireStateKeys = "sharePctAdj";
export const ALLOCATION_POINT_ADJUSTED: IRepertoireStateKeys = "pointsAdj";
export const ALLOCATION_AMOUNT_ADJUSTED: IRepertoireStateKeys = "amountAdj";

export const ADJUSTMENT_SAVE_VALIDATION_DATE_NOT_SET = 'saveValidationAdjustmentDateNotSet';
export const ADJUSTMENT_SAVE_VALIDATION_SOURCE_DISTRIBUTION_NOT_SET = 'saveValidationSourceDistributionNotSet';
export const ADJUSTMENT_SAVE_VALIDATION_ADJUSTMENT_DISTRIBUTION_NOT_SET = 'saveValidationAdjustmentDistributionNotSet';
export const ADJUSTMENT_SAVE_VALIDATION_STATUS_NOT_SET = 'saveValidationAdjustmentStatusNotSet';
export const ADJUSTMENT_SAVE_VALIDATION_CATEGORY_NOT_SET = 'saveValidationAdjustmentCategoryNotSet';

// Pay Per Use Licenses
export const LICENSE_ID_STATE_KEY: IRepertoireStateKeys = "shoppingCartID";
export const LICENSE_AFFILIATED_USER_STATE_KEY: IRepertoireStateKeys = "affiliatedUser";
export const LICENSE_SHOPPING_CART_NAME_STATE_KEY: IRepertoireStateKeys = "shoppingCartName";
export const LICENSE_USER_TYPE_STATE_KEY: IRepertoireStateKeys = "userTypes";
export const LICENSE_EXTERNAL_USER_TYPE_STATE_KEY: IRepertoireStateKeys = "isUserExternal";
export const LICENSE_COMMERCIAL_USE_TYPE_STATE_KEY: IRepertoireStateKeys = "commercialUse";
export const LICENSE_GEOGRAPHICAL_AREA_TYPE_STATE_KEY: IRepertoireStateKeys = "geographicalArea";
export const LICENSE_SECTOR_TYPE_STATE_KEY: IRepertoireStateKeys = "sector";
export const LICENSE_USES_TYPE_STATE_KEY: IRepertoireStateKeys = "uses";
export const LICENSE_LICENSEE_NAME_STATE_KEY: IRepertoireStateKeys = "licenseeName";
export const LICENSE_LICENSEE_ADDRESS_STATE_KEY: IRepertoireStateKeys = "licenseeAddress";
export const LICENSE_USE_DETAILS_FOR_BILLING_STATE_KEY: IRepertoireStateKeys = "reuseDetailsForBilling";
export const LICENSE_BILLING_NAME_STATE_KEY: IRepertoireStateKeys = "billingName";
export const LICENSE_BILLING_ADDRESS_STATE_KEY: IRepertoireStateKeys = "billingAddress";
export const LICENSE_SIGNER_NAME_STATE_KEY: IRepertoireStateKeys = "signerName";
export const LICENSE_LICENSE_CODE_STATE_KEY: IRepertoireStateKeys = "licenseCode";
export const LICENSE_TOTAL_AMOUNT_STATE_KEY: IRepertoireStateKeys = "totalAmount";
export const LICENSE_STATUS_STATE_KEY: IRepertoireStateKeys = "status";
export const LICENSE_SUBMIT_CONFIRMATION_STATE_KEY: IRepertoireStateKeys = "submitLicenseConfirmationMessage";

//
export const LICENSE_OPEN_STATUS_STATE_KEY: string = "Open";
export const LICENSE_SUBMITTED_STATUS_STATE_KEY: string = "Submitted";

/// Pay Per Use License License Request Work Parameters Keys
/// Ony CEDRO applicable
export const DE_PAGINA_FIELD_KEY: string = "De página";
export const A_FIELD_KEY: string = "A";
export const NUMERO_DE_PAGINAS_FIELD_KEY: string = "Número de páginas";
export const TOTAL_DE_PAGINAS_FIELD_KEY: string = "Total paginas";
export const PORCENTAJE_FIELD_KEY: string = "Porcentaje";
export const OBRA_COMPLETA_FIELD_KEY: string = "Obra completa";
export const NUMERO_CAPITULOS_FIELD_KEY: string = "Número de capítulos";
export const NUMERO_ARTICULOS_FIELD_KEY: string = "Número de artículos";
export const NUMERO_GRAFICOS_FIELD_KEY: string = "Número de gráficos";
export const IDIOMA_FIELD_KEY: string = "Idioma";
export const OBSERVACIONES_FIELD_KEY: string = "Observaciones";
export const NOMBRE_CURSO_FIELD_KEY: string = "Nombre del curso";
export const UNIVERSIDAD_INSTITUCION_FIELD_KEY: string = "Universidad o institución";
export const PRECIO_EJEMPLEAR_FIELD_KEY: string = "Precio por ejemplar";
export const TITULO_NUEVA_OBRA_FIELD_KEY: string = "Titulo nueva obra";
export const SOPORTE_ESTAMPADO_FIELD_KEY: string = "Soporte del estampado";
export const ENTREGA_CONTENIDO_FIELD_KEY: string = "Entrega de contenido";
export const NUMERO_REPRODUCCIONES_FIELD_KEY: string = "Número de reproducciones";
export const NUMERO_ACCESOS_FIELD_KEY: string = "Número de accesos";
export const NUMERO_USUARIOS_FIELD_KEY: string = "Número de usuarios";
export const NUMERO_DESTINATARIO_FIELD_KEY: string = "Número de destinatarios";
export const NUMERO_ASISTENTE_FIELD_KEY: string = "Número de asistentes";
export const NUMERO_DE_RECORTES_FIELD_KEY: string = "Número de recortes";
export const INICIO_FIELD_KEY: string = "Inicio";
export const FIN_FIELD_KEY: string = "Fin";
export const URL_FIELD_KEY: string = "Url";
export const TITULO_FIELD_KEY: string = "Título";
export const TOTAL_PAGINA_2_PAGINA_FIELD_KEY: string = "Total páginas";
export const ANO_PUBLICACION_FIELD_KEY: string = "Año de publicación";
export const NUMERO_VOLUMEN_FIELD_KEY: string = "Número/Volumen";
export const AUTOR_FIELD_KEY: string = "Autor";
export const FECHA_POST_FIELD_KEY: string = "Fecha del post";
export const TITULO_POST_FIELD_KEY: string = "Título del post";
export const OBRA_COMPLETA_FIELD_NAME: string = "obraCompleta";

// Distribution Settings
export const DISTRIBUTION_SET_TYPE_GROUP_STATE_KEY: IRepertoireStateKeys = "distributionSettingsSetTypeGroup";
export const DISTRIBUTION_SHARE_RIGHT_TYPE_STATE_KEY: IRepertoireStateKeys = "distributionSettingsShareRightType";
export const DISTRIBUTION_NO_DISTRIBUTION_TYPE_AVAILABLE_STATE_KEY: IRepertoireStateKeys = "subjectValuesNotAvailable";

// Distribution Subjects
export const DISTRIBUTION_SUBJECT_WORK_TYPE_STATE_KEY: IRepertoireStateKeys = "distributionSubjectWorkType";
export const DISTRIBUTION_SUBJECT_LICENSING_TYPE_STATE_KEY: IRepertoireStateKeys = "distributionSubjectLicensingUseType";
export const DISTRIBUTION_SUBJECT_RIGHT_TYPE_STATE_KEY: IRepertoireStateKeys = "distributionSubjectRightType";
export const DISTRIBUTION_SUBJECT_MEMBER_TYPE_STATE_KEY: IRepertoireStateKeys = "distributionSubjectMemberType";

//Claims
export const MEMBER_NUMBER_STATE_KEY: IRepertoireStateKeys = "memberNumber";
export const RIGHTHOLDER_ID_STATE_KEY: IRepertoireStateKeys = "rightHolderID";
export const RIGHTHOLDER_NAME_STATE_KEY: IRepertoireStateKeys = "name";
export const CLAIM_TARGET_DISTRIBUTION_KEY: IRepertoireStateKeys = "targetDistribution";
export const CLAIM_ID_STATE_KEY: IRepertoireStateKeys = "claimId";
export const CLAIM_CODE_STATE_KEY: IRepertoireStateKeys = "claimCode";
export const CLAIM_STATUS_STATE_KEY: IRepertoireStateKeys = "claimStatus";
export const CLAIM_TYPE_STATE_KEY: IRepertoireStateKeys = "claimType";
export const ORIGIN_STATE_KEY: IRepertoireStateKeys = "origin";
export const CLAIM_DATE_KEY: IRepertoireStateKeys = "claimDate";
export const CLAIM_DEPARTMENT_KEY: IRepertoireStateKeys = "department";
export const DISTRIBUTION_STARTDATE_KEY: IRepertoireStateKeys = "startYearOfDistributions";
export const DISTRIBUTION_ENDDATE_KEY: IRepertoireStateKeys = "endYearOfDistributions";
export const KEEP_ORIGINALVALUE_STATE_KEY: IRepertoireStateKeys = "keepOriginalValue";
export const MINIMUM_AMOUNT_STATE_KEY: IRepertoireStateKeys = "minimumAmount";
export const FIRST_VALUE_STATE_KEY: IRepertoireStateKeys = "firstValue";
export const CALCULATEALL_AFFECTED_STATE_KEY: IRepertoireStateKeys = "calculateAllAffected";

//ClaimMaintenance toolbar
export const CLAIM_MAINTENANCE_TOOLBAR: IRepertoireStateKeys = "ClaimMaintenanceToolbar";
export const ADD_NEW_CLAIM_STATE_KEY: IRepertoireStateKeys = "addNewClaim";
export const PROCESS_CLAIM_STATE_KEY: IRepertoireStateKeys = "processClaim";
export const PROCESS_CLAIM_CONFIRMATION_STATE_KEY: IRepertoireStateKeys = "claimConfirmationMessage";


//Payment Run State Keys
export const PAYMENTRUN_ID_STATE_KEY: IRepertoireStateKeys = "paymentRunID";
export const PAYMENTRUN_STATUS_STATE_KEY: IRepertoireStateKeys = "status";
export const PAYMENTRUN_CODE_STATE_KEY: IRepertoireStateKeys = "code";
export const PAYMENTRUN_TYPE_STATE_KEY: IRepertoireStateKeys = "type";
export const PAYMENTRUN_INTERNAL_NARRATIVE_STATE_KEY: IRepertoireStateKeys = "internalNarrative";
export const PAYMENTRUN_EXTERNAL_NARRATIVE_STATE_KEY: IRepertoireStateKeys = "externalNarrative";
export const PAYMENTRUN_PAYMENTRUN_DATE_STATE_KEY: IRepertoireStateKeys = "paymentRunDate";
export const PAYMENTRUN_SHOW_ON_PORTAL_STATE_KEY: IRepertoireStateKeys = "showOnPortal";
export const PAYMENTRUN_CREATED_DATE_STATE_KEY: IRepertoireStateKeys = "createdDate";
export const PAYMENTRUN_LAST_MODIFIED_DATE_STATE_KEY: IRepertoireStateKeys = "lastModifiedDate";
export const PAYMENTRUN_AVAILABLE_ON_PORTAL_FROM_STATE_KEY: IRepertoireStateKeys = "availableOnPortalFrom";
export const PAYMENTRUN_AVAILABLE_ON_PORTAL_TO_STATE_KEY: IRepertoireStateKeys = "availableOnPortalTo";

// Save PaymentRun Validation Messages
export const PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_ID_NOT_SET = 'saveValidationPaymentRunIDNotSet'
export const PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_STATUS_NOT_SET = 'saveValidationPaymentRunStatusNotSet'
export const PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_TYPE_NOT_SET = 'saveValidationPaymentRunTypeNotSet'
export const PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_RUN_DATE_NOT_SET = 'saveValidationPaymentRunDateNotSet'
export const PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_AVAILABLE_FROM_DATE_NOT_SET_CONDITIONALLY = 'saveValidationAvailableOnPortalDateNotSetConditionally'

// Save Distribution Validation Messages
export const DISTRIBUTION_SAVE_VALIDATION_USAGE_FROM_NOT_SET = 'saveValidationUsageFromNotSet'
export const DISTRIBUTION_SAVE_VALIDATION_CLAIMS_FROM_NOT_SET = 'saveValidationClaimsFromNotSet'
export const DISTRIBUTION_SAVE_VALIDATION_USAGE_TO_NOT_SET = 'saveValidationUsageToNotSet'
export const DISTRIBUTION_SAVE_VALIDATION_CLAIMS_TO_NOT_SET = 'saveValidationClaimsToNotSet'
export const DISTRIBUTION_SAVE_VALIDATION_NO_POOL_ASSIGNED = 'saveValidationNoPoolAssigned'
export const DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_DATE_NOT_SET = 'saveValidationDistributionDateNotSet'
export const DISTRIBUTION_SAVE_VALIDATION_AMOUNT_NOT_NUMERIC = 'amountToAllocateNotNumerical'
export const DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_TYPE = 'noDistributionTypeValidationMessage';
export const DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_STATUS = 'noDistributionStatusValidationMessage';
export const DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_CAT_ONE = 'noCat1ValidationMessage';
export const DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_CAT_TWO = 'noCat2ValidationMessage';
export const DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_DESCRIPTION = 'noDescriptionValidationMessage';
export const DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_SET_TYPE = 'noSetTypeGroupValidationMessageDistribution';
export const DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_SHARE_RIGHT_TYPE = 'noShareRightTypeValidationMessage';
export const DISTRIBUTION_SAVE_VALIDATION_PERCENTAGE_NOT_100 = 'distributionSaveValidationNotOneHundred';
export const DISTRIBUTION_SAVE_NO_STATEMENT_DESCRIPTION = 'noStatementDescriptionValidationMessage';

// Table Actions
export const OPEN_ACTION: ITableActionName = "open_action";
export const COPY_ACTION: ITableActionName = "copy_action";
export const ADD_NEW_ACTION: ITableActionName = "addNew_action";
export const SEARCH_ACTION: ITableActionName = "search_action";
export const REMOVE_ACTION: ITableActionName = "remove_action";
export const ADD_TO_PACKAGE_ACTION: ITableActionName = "addtopackage_action"
export const UPDATE_ACTION: ITableActionName = "update_action";
export const DEPENDENT_ROW_ACTION: ITableActionName = "dependentRow_action";
export const CODE_ACTION: ITableActionName = "code_action";
export const GROUPED_ACTION: ITableActionName = "grouped_action";
export const REQUEST_ACTION: ITableActionName = "request_action";
export const SELECT_ACTION: ITableActionName = "select_action";
export const ADD_NEW_DATE_ACTION: ITableActionName = "addNewDate_action";
export const MERGE_ACTION: ITableActionName ="merge_action"
// Components
export const MESSAGE_VIEW: string = "MessageView";
export const LOADING_VIEW: string = "LoadingView";
export const WORK_SEARCH_VIEW: string = "WorkSearchView";
export const UNPAID_CLAIMS_WORK_SEARCH_VIEW: string = "UnpaidClaimsWorkSearchView";
export const TABS_VIEW: string = "TabsView";
export const USAGE_TABS_VIEW: string = "UsageTabsView";
export const COMMENTS_VIEW: string = "CommentsView";
export const CONTRIBUTOR_COMMENTS_VIEW: string = "ContributorCommentsView";
export const SEARCH_VIEW: string = "SearchView";
export const DURATION_VALIDATION: string = "DurationValidationView";
export const USAGE_LOCATION_EDIT_WINDOW: string = "UsageLocationEditWindow";
export const USAGE_SET_LIST_ADDITIONAL_EDIT_WINDOW: string = "UsageSetListAdditionalEditWindow";
export const WORK_NOT_FOUND_VIEW: string = "WorkNotFoundView";
export const MERGE_DENIED_VIEW: string = "MergeIpDenied";
export const YES_NO_PROMPT_VIEW: string = "YesNoPromptView";
export const SCHEDULED_JOB_MODAL_VIEW: string = "ScheduledJobModalView";
export const VERSION_HISTORY_VIEW: string = "VersionHistoryView";
export const DISTRIBUTION_VERSION_HISTORY_VIEW: string = "DistributionVersionHistoryView";
export const AGREEMENT_NOT_FOUND_VIEW: string = "AgreementNotFoundView";
export const INTERESTED_PARTY_NOT_FOUND_VIEW: string = "InterestedPartyNotFoundView";
export const SHORTCUT_KEY_MODAL: string = "shortcutKeyModal";
export const JOBS_IN_PROGRESS_MODAL: string = "jobsInProgressModal";
export const ARTIST_NOT_FOUND_VIEW: string = "ArtistNotFoundView";
export const PRODUCT_NOT_FOUND_VIEW: string = "ProductNotFoundView";
export const SEARCH_RESULTS_TABLE: string = "SearchResultsTable";
export const WORKS_PAGE_VIEW: string = "WorksPage";
export const AGREEMENTS_PAGE_VIEW: string = "AgreementsPage";
export const DRAFT_AGREEMENTS_PAGE_VIEW: string = "DraftAgreementsPage";
export const WORK_SUBMISSION_VIEW: string = "WorkSubmission";
export const REJECT_UP_WORKFLOW: string = "RejectUPWorkflow";
export const FIELD_SETTING_VIEW: string = "FieldSettingView";
export const WORKFLOW_PAGE_VIEW: string = 'WorkflowPage';
export const AGREEMENT_MAINTENANCE_PAGE_VIEW: string = 'AgreementMaintenancePage';
export const LIVE_PERFORMANCE_PAGE_VIEW: string = "LivePerformancePage";
export const AGREEMENT_MAINTENANCE_GENERAL_DATA_VIEW: string =
    "AgreementMaintenanceGeneralDataView";
export const AGREEEMENT_MAINTENANCE_GRIDS_VIEW: string =
    "AgreementMaintenanceGridsView";
export const AGREEMENT_MAINTENANCE_ACCORDION_VIEW: string =
    "AgreementMaintenanceAccordionView";
export const AGREEMENT_MAINTENANCE_TOOLBAR: string =
    "AgreementMaintenanceToolbar";
export const IPS_PAGE_VIEW: string = "IPsPage";
export const IP_MAINTENANCE_GENERAL_DATA_VIEW: string =
    "IPMaintenanceGeneralDataView";
export const IP_REPRESENTATION_GENERAL_DATA_VIEW: string =
    "IPRepresentationGeneralDataView";
export const WORK_GROUP_GENERAL_DATA_VIEW: string =
    "WorkGroupGeneralDataView";
export const IP_MAINTENANCE_GRIDS_VIEW: string =
    "IPMaintenanceGridsView";
export const IP_MAINTENANCE_ACCORDION_VIEW: string =
    "IPMaintenanceAccordionView";
export const MY_PACKAGES_MAINTENANCE_PAGE: string =
    "MyPackagesMaintenancePage";
export const MY_PACKAGES_GENERAL_DATA_VIEW:string=
    "MyPackagesGeneralDataView"  
export const MY_PACKAGES_VIEW: string = "MyPackagesPage"; 
export const MY_PACKAGES_GRID_VIEW:string=
    "MyPackagesGridView"   
export const IP_MAINTENANCE_TOOLBAR: string =
    "IPMaintenanceToolbar";
export const WEIGHT_SETTINGS_POOL_MAINTENANCE: string =
    "WeightSettingsPoolMaintenance";
export const IP_MAINTENANCE_SAVE_RESULT: string = "IPMaintenanceSaveResult";
export const IP_MAINTENANCE_TIME_RANGE_SELECTOR: string = "TimeRangeSelectorIP";
export const IP_MAINTENANCE_REPRESENTATION_TIME_RANGE_SELECTOR: string = "TimeRangeSelectorIPRepresentation";
export const ARTISTS_PAGE_VIEW: string = "ArtistsPage";
export const ARTIST_MAINTENANCE_GENERAL_DATA_VIEW: string =
    "ArtistMaintenanceGeneralDataView";
export const ARTIST_MAINTENANCE_GRIDS_VIEW: string =
    "ArtistMaintenanceGridsView";
export const ARTIST_MAINTENANCE_ACCORDION_VIEW: string =
    "ArtistMaintenanceAccordionView";
export const ARTIST_MAINTENANCE_TOOLBAR: string =
    "ArtistMaintenanceToolbar";
export const ARTIST_MAINTENANCE_SAVE_RESULT: string = "ArtistMaintenanceSaveResult";
export const PRODUCTS_PAGE_VIEW: string = "ProductsPage";
export const PRODUCT_MAINTENANCE_GENERAL_DATA_VIEW: string =
    "ProductMaintenanceGeneralDataView";
export const PRODUCT_MAINTENANCE_GRIDS_VIEW: string =
    "ProductMaintenanceGridsView";
export const PRODUCT_MAINTENANCE_ACCORDION_VIEW: string =
    "ProductMaintenanceAccordionView";
export const AVWORK_DETAILS_ACCORDION_VIEW: string =
    "AVWorkDetailsAccordianViewData";
export const AV_SUBMISSION_DETAILS_ACCORDIAN_VIEW: string =
    "AVSubmissionViewData"
export const PRODUCT_MAINTENANCE_TOOLBAR: string =
    "ProductMaintenanceToolbar";
export const PRODUCT_MAINTENANCE_SAVE_RESULT: string = "ProductMaintenanceSaveResult";
export const PRODUCT_MAINTENANCE_PAGE_KEY: string = "ProductMaintenancePage"
export const WORK_MAINTENANCE_GRIDS_VIEW: string = "WorkMaintenanceGridsView";
export const WORK_MAINTENANCE_ACCORDION_VIEW: string =
    "WorkMaintenanceAccordionView";
export const WORK_MAINTENANCE_GENERAL_DATA_VIEW: string =
    "WorkMaintenanceGeneralDataView";
export const WORK_MAINTENANCE_TERRITORY_SPECIFIC_INFO_VIEW: string = "WorkMaintenanceTerritorySpecificInfoView";
export const WORK_MAINTENANCE_TOOLBAR: string = "WorkMaintenanceToolbar";
export const WORK_MAINTENANCE_PAGE_KEY: string = "WorkMaintenancePage"
export const SAVE_RESULT: string = "SaveResult";
export const DATA_GRID_TABLE: string = "DataGridTable";
export const CONTRIBUTORS_DATA_VIEW: string = "ContributorsDataView";
export const WORK_METADATA_ACCORDION_VIEW = "WorkMetadataAccordionView";
export const CONTRIBUTORS_DATA_GRID_TABLE: string = "ContributorsDataGridTable";
export const REPERTOIRE_PAGE_DATA_VIEW: string = "RepertoirePageDataView";
export const WORK_SUBMISSION_DETAILS_VIEW: string = "WorkSubmissionDetails";
export const EDITABLE_FIELDS_DATA_VIEW: string = "EditableFieldsView";
export const EDITABLE_GRID_FIELDS_DATA_VIEW: string = "EditableGridFieldsView";
export const WORKFLOW_FIELDS: string = "WorkflowFields";
export const WORKFLOW_PAGE_TOOLBAR_FIELDS: string = "WorkflowPageToolbar";
export const ASSING_WORKFLOW_VIEW: string = "AssignWorkflowView";
export const UPDATE_WORKFLOW_VIEW: string = "UpdateWorkflowView";
export const USAGE_POOLS_PAGE_VIEW: string = "UsagePoolsPage";
export const USAGE_POOL_MAINTENANCE_PAGE_KEY: string = "PoolMaintenancePage";
export const USAGE_POOL_MAINTENANCE_GENERAL_DATA_VIEW: string = "UsagePoolMaintenanceGeneralDataView";
export const USAGE_POOL_MAINTENANCE_TOOLBAR: string = "UsagePoolMaintenanceToolbar"
export const USAGE_POOL_MAINTENANCE_GRIDS_VIEW: string = "UsagePoolMaintenanceGridsView";
export const USAGE_POOL_MAINTENANCE_ALLOCATION_SETTINGS_GRID: string = "UsagePoolMaintenanceAllocationSettingsGrid";
export const USAGE_POOL_MAINTENANCE_WEIGHT_SETTINGS_GRID: string = "UsagePoolMaintenanceWeightSettingsGrid";
export const USAGE_POOL_MAINTENANCE_ESTIMATED_VALUE_PER_POINT_SETTINGS_GRID: string = "UsagePoolMaintenanceEstimatedValuePerPointSettingsGrid";
export const DISTRIBUTIONS_PAGE_VIEW: string = "DistributionsPage";
export const DISTRIBUTIONS_MAINTENANCE_GENERAL_DATA_VIEW: string = "DistributionMaintenanceGeneralDataView";
export const DISTRIBUTION_MAINTENANCE_TOOLBAR: string = "DistributionMaintenanceToolbar";
export const DISTRIBUTION_MAINTENANCE_GRIDS_VIEW: string = "DistributionMaintenanceGridsView";
export const REPRESENTATION_GENERAL_DATA_VIEW: string = "IPRepresentationGeneralDataView";
export const CONTRIBUTORS_SEARCH_VIEW: string = "contributors";
export const SELECTION_CRITERIA_VIEW: string = "selectionCriteria";
export const TERRITORY_SELECTION_VIEW: string = "territorySelection";
export const TERRITORY_SELECTION_WITH_RADIO_BTN_VIEW: string = "territorySelectionWithRadioBtn";
export const IMPORT_DATA_VIEW: string = "importDataView";
export const IMPORT_POOL_DATA_VIEW_DISTRIBUTIONS: string = "importPoolDataViewDistributions";
export const FOREIGN_SOCIETY_INFO_VIEW: string = "foreignSocietyInfo"
export const CLAIMS_MAINTENANCE_GENERAL_DATA_VIEW: string = "ClaimMaintenanceGeneralDataView"
export const CLAIMS_MAINTENANCE_GRIDS_VIEW: string = "ClaimMaintenanceGridsView"
export const CLAIMS_PAGE_VIEW: string = "ClaimsPage"
export const CLAIMS_MAINTENANCE_TOOLBAR: string = "ClaimMaintenanceToolbar"
export const MATCHING_WORK_MORE_INFO_VIEW: string = "matchingWorkMoreInfo";
export const ADJUSTMENTS_PAGE_VIEW: string = "AdjustmentsPage";
export const ADJUSTMENT_MAINTENANCE_GENERAL_DATA_VIEW = "AdjustmentMaintenanceGeneralDataView";
export const ADJUSTMENT_MAINTENANCE_TOOLBAR = "AdjustmentMaintenanceToolbar";
export const ADJUSTMENT_MAINTENANCE_GRIDS_VIEW = "AdjustmentMaintenanceGridsView"
export const ALLOCATION_ADJUSTMENT_DETAILS_VIEW = "AllocationAdjustmentDetailsViewData";
export const CLAIM_NOT_FOUND_VIEW = "ClaimNotFoundView";
export const PAYMENTRUN_PAGE_VIEW: string = "PaymentRunPage";
export const PAYMENTRUN_MAINTENANCE_GENERAL_DATA_VIEW: string = "PaymentRunMaintenanceGeneralDataView";
export const PAYMENTRUN_MAINTENANCE_TOOLBAR: string = "PaymentRunMaintenanceToolbar";
export const PAYMENTRUN_MAINTENANCE_GRIDS_VIEW: string = "PaymentRunMaintenanceGridsView";
export const LICENSES_PAGE_VIEW: string = "LicensesPage";
export const LICENSE_MAINTENANCE_GENERAL_DATA_VIEW: string = "LicenseMaintenanceGeneralDataView";
export const LICENSE_MAINTENANCE_TOOLBAR: string = "LicenseMaintenanceToolbar";
export const LICENSE_MAINTENANCE_GRIDS_VIEW: string = "LicenseMaintenanceGridsView";
export const LICENSE_WORK_DATAGRID_VIEW: string = "licensesWorkGrid";
export const FOOTER: string = "Footer";

// DataGridTable instances
export const DATA_GRID_TABLE_FIELDS_EXPANDER: string = "FieldExpander";

export const EXPANDED_ACCORDION: string = "expanded";

//Icon names
export const COPY_ICON: string = "copy";
export const UNDO_ICON: string = "undo";
export const MERGE_ICON: string = "merge";
export const DELETE_ICON: string = "delete";
export const NEW_ICON: string = "addNew";
export const VERSION_HISTORY_ICON: string = "versionHistory";
export const AUTO_MATCH_ICON: string = "automatch";
export const ASSING_TO_ICON: string = "assingTo";
export const UPDATE_STATUS_ICON: string = "updateStatus";
export const SELECTED_ICON: string = "deselect";
export const START_SESSION: string = "startSession";
export const ALLOCATE_ICON: string = "allocate";
export const UNDO_ALLOCATION_ICON: string = "undoAllocation";
export const SET_CD_ALLOCATION_SCHEDULE_ICON: string = "setCDAllocationSchedule";
export const OPEN_IN_NEW_TAB: string = "openInNewTab";
export const ADD_TO_PACKAGE_ICON: string = "addtoPackage";

// ContributorsDataView fields
export const CONTRIBUTORS_DATA_VIEW_INHERIT_FROM: IRepertoireStateKeys = "inheritFrom";
export const CONTRIBUTORS_DATA_VIEW_BREAK_INHERITANCE: IRepertoireStateKeys = "breakInheritance";
export const CONTRIBUTORS_DATA_VIEW_DO_NOT_INHERIT: IRepertoireStateKeys = "doNotInherit";
export const CONTRIBUTORS_DATA_VIEW_INHERITANCE_BROKEN_MESSAGE: IRepertoireStateKeys = "inheritanceBrokenMessage";
export const CONTRIBUTORS_DATA_VIEW_INHERITANCE_ADDED_MESSAGE: IRepertoireStateKeys = "inheritanceAddedMessage";
export const CONTRIBUTORS_DATA_VIEW_CANNOT_SET_INHERITANCE_MESSAGE: IRepertoireStateKeys = "cannotSetInheritanceMessage";


export const LASTMODIFIED_DATE_KEY:IRepertoireStateKeys = "lastModifiedDate";
export const ITEM_COUNT_KEY:IRepertoireStateKeys = "itemCount";
export const DOWNLOAD_FILE_FORMAT_KEY:IRepertoireStateKeys = "downloadFileFormat";
export const NOTE_KEY :IRepertoireStateKeys= "note";
export const CLEAR_PACKAGE_BUTTON :IRepertoireStateKeys= "clearPackage";
export const GENERATE_FILE_PACKAGE :IRepertoireStateKeys= "generatePackageFile";

//WorkMetadataAccordionView keys
export const ARTICLE_METADATA_KEY = "articleMetadata";
export const BOOK_METADATA_KEY = "bookMetadata";
export const CHAPTER_METADATA_KEY = "chapterMetadata";
export const SHEET_MUSIC_METADATA_KEY = "sheetMusicMetadata";
export const MAGAZINE_METADATA_KEY = "magazineMetadata";
export const OTHER_INDICATORS_KEY = "otherIndicators";
export const READONLY_INDICATORS_KEY = "readonlyIndicators";

//AdditionalFields keys
export const ADDITIONAL_ATTRIBUTES_KEY = "workAdditionalAttribute";

// Tab Components
export const WORK_GROUP_PAGE: ITabReduxItem = {
    title: "Work Group",
    component: "WorkGroup",
    tabTitle: "Work Group | Matching Engine",
};
export const REPRESENTATION_PAGE: ITabReduxItem = {
    title: "Representation Page",
    component: "NewIpRepresentationDataView",
    tabTitle: "Add Representation | Matching Engine"
};
export const WORKS_PAGE: ITabReduxItem = {
    title: "Find Work",
    component: "WorksPage",
    tabTitle: "Works | Matching Engine"
};
export const IPS_PAGE: ITabReduxItem = {
    title: "Find IP",
    component: "IPsPage",
    tabTitle: "Ips | Matching Engine"
};
export const IP_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "IP",
    component: "IPMaintenancePage",
    tabTitle: "Ips | Matching Engine"
};
export const AGREEMENTS_PAGE: ITabReduxItem = {
    title: "Find Agreement",
    component: "AgreementsPage",
    tabTitle: "Agreements | Matching Engine"
};
export const WORK_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Repertoire",
    component: "WorkMaintenancePage",
    tabTitle: "Works | Matching Engine"
};
export const AGREEMENT_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Agreement",
    component: "AgreementMaintenancePage",
    tabTitle: "Agreements | Matching Engine"
}
export const PRODUCTS_PAGE: ITabReduxItem = {
    title: "Find Product",
    component: "ProductsPage",
    tabTitle: "Products | Matching Engine"
};
export const PRODUCT_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Product",
    component: "ProductMaintenancePage",
    tabTitle: "Products | Matching Engine"
}
export const WORKFLOW_PAGE: ITabReduxItem = {
    title: "Find Workflow",
    component: "WorkflowPage",
    tabTitle: "Workflow | Matching Engine",
};
export const ARTISTS_PAGE: ITabReduxItem = {
    title: "Find Artist",
    component: "ArtistPage",
    tabTitle: "Artists | Matching Engine"
};
export const ARTIST_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Artist",
    component: "ArtistMaintenancePage",
    tabTitle: "Artists | Matching Engine"
};
export const FINDUSAGE_PAGE: ITabReduxItem = {
    title: "Find Usage Information",
    component: "FindUsagePage",
    tabTitle: "Find Usage Information | Matching Engine",
};
export const USAGE_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Usage",
    component: "UsageMaintenancePage",
    tabTitle: "Usage | Matching Engine",
};
export const USAGE_POOLS_PAGE: ITabReduxItem = {
    title: "Find Usage Pools",
    component: "UsagePoolsPage",
    tabTitle: "Pools | Matching Engine"
}
export const USAGE_POOL_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Pool",
    component: "PoolMaintenancePage",
    tabTitle: "Pools | Matching Engine"
}
export const DISTRIBUTIONS_PAGE: ITabReduxItem = {
    title: "Find Distribution",
    component: "DistributionsPage",
    tabTitle: "Distributions | Matching Engine"
}
export const DISTRIBUTION_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Distribution",
    component: "DistributionMaintenancePage",
    tabTitle: "Distributions | Matching Engine"
}
export const CLAIMS_PAGE: ITabReduxItem = {
    title: "Find Claim",
    component: "ClaimsPage",
    tabTitle: "Claims | Matching Engine"
}
export const CLAIM_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Claim",
    component: "ClaimMaintenancePage",
    tabTitle: "Claims | Matching Engine"
}

export const ADJUSTMENTS_PAGE: ITabReduxItem = {
    title: "Find Adjustment",
    component: "AdjustmentsPage",
    tabTitle: "Adjustments | Matching Engine"
}

export const ADJUSTMENT_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Adjustment",
    component: "AdjustmentMaintenancePage",
    tabTitle: "Adjustments | Matching Engine"
}

export const PAYMENTRUN_PAGE: ITabReduxItem = {
    title: "Find Payment Run",
    component: "PaymentRunPage",
    tabTitle: "Payment Run | Matching Engine"
}

export const PAYMENTRUN_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Payment Run",
    component: "PaymentRunMaintenancePage",
    tabTitle: "Payment Run | Matching Engine"
}

export const LICENSE_PAGE: ITabReduxItem = {
    title: "Find License",
    component: "LicensesPage",
    tabTitle: "Licenses | Matching Engine"
}

export const LICENSE_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "License",
    component: "LicenseMaintenancePage",
    tabTitle: "Licenses | Matching Engine"
}
export const MY_PACKAGES_PAGE: ITabReduxItem = {
    title: "My Packages",
    component: "MyPackagesPage",
    tabTitle: "My Packages | Matching Engine"
};

// Data grid keys
export const TITLES_KEY = "titles";
export const ARTISTS_KEY = "artists";
export const NUMBERS_KEY = "numbers";
export const ADDITIONAL_NUMBERS_KEY = "additionalNumbers";
export const WORK_ARTIST_NUMBER_KEY = "number";
export const CONTRIBUTORS_KEY = "contributors";
export const COMPONENTS_KEY = "components";
export const OTHER_KEY = "other";
export const AGREEMENTS_KEY = "agreements";
export const ADMINISTERED_BY_KEY = "administeredBy";
export const APPLICABLE_WORKS_KEY = "applicableWorks";
export const AGREEMENT_CARVEOUTS_KEY = "agreementCarveOuts"
export const AGREEMENT_NUMBERS_KEY = "agreementNumbers";
export const AGREEMENT_DUPLICATE_KEY = "agreementDuplicates";
export const AGREEMENT_ADDITIONAL_DATA_KEY = "agreementAdditionalData";
export const REPLACED_AGREEMENT_KEY = "replacedAgreement";
export const IP_NAMES_KEY = "ipNames";
export const IP_AGREEMENTS_KEY = "ipAgreements";
export const IP_MERGED_ACCOUNTS_KEY = "ipMergedAccounts";
export const IP_LICENSING_REPRESENTATION_KEY = "ipLicensingRepresentation";
export const IP_REPRESENTATION_KEY = "ipRepresentation";
export const IP_LICENSING_REPRESENTATION_WORK_GROUP_KEY = "workGroup";
export const AGREEMENTS_IP_KEY = "agreement_ip";
export const SET_TYPE_KEY = "setType";
export const PRODUCT_NAMES_KEY = "productName";
export const PRODUCT_NUMBERS_KEY = "productNumber";
export const PRODUCT_PERFORMERS_KEY = "productPerformer";
export const PRODUCT_WORKS_KEY = "productWork";
export const RECORDING_DETAILS = 'recordingDetails';
export const PRODUCT_AV_WORKS_KEY = "productAVWork";
export const PRODUCT_AV_SUBMISSIONS_KEY = "avSubmissions";
export const PRODUCT_AV_REQUEST_KEY = "productAVRequest";
export const PRODUCT_CONTRIBUTORS_KEY = "productContributor";
export const MATCHING_REPERTOIRE_WORKS_KEY = "matchingRepertoireWorks";
export const MATCHING_REPERTOIRE_PRODUCTS_KEY = "matchingRepertoireProducts";
export const WORK_SUBMISSION = "submission";
export const WORK_RESPONSE = "response";
export const COMMENTS_KEY = 'comments';
export const PARENT_WORK_KEY = 'parents';
export const OPEN_PARENT = 'open_parent';
export const ARTIST_NUMBERS_KEY = "artistNumbers";
export const POOL_SOURCES_KEY = "poolSources";
export const POOL_RIGHT_TYPES_KEY = "poolRightTypes";
export const POOL_WEIGHT_SETTINGS_KEY = "poolWeightSettings";
export const POOL_ESTIMATED_VALUE_PER_POINT_SETTINGS_KEY = "poolEstimatedValuePerPointSettings";
export const DATES_AND_DIMENSIONS = "datesAndDimensions";
export const DISTRIBUTION_POOLS_KEY = "distributionPools";
export const DISTRIBUTION_SETTINGS_KEY = "distributionSettings";
export const DISTRIBUTION_SUBJECTS_KEY = "distributionSubjects";
export const WORK_TYPE = "workType";
export const DISTRIBUTION_POOL_SELECTION_CRITERIA_KEY = "SelectionCriteria";
export const DISTRIBUTION_POOL_DATE_RANGE_SELECTION_CRITERIA_KEY = "DateRangeSelectionCriteria";
export const POOL_ALLOCATION_SETTINGS_KEY = "poolAllocationSettings";
export const CLAIMS_GENERAL = "claimsGeneral";
export const CLAIMED_WORKS = "claimedWorks";
export const TYPES_OF_CALCULATION = "typeOfCalculations";
export const CLAIM_CALCULATIONS = "claimCalculations";
export const ALLOCATION_ADJUSTMENT_IP_KEY = "allocationAdjustmentIp";
export const PAYMENTRUN_DISTRIBUTIONS_KEY = "paymentRunDistributions";
export const LICENSE_AFFILIATED_USER_KEY = "liceseAffiliatedUser";
export const LICENSE_USES_KEY = "licensesUses";
export const LICENSE_BILLING_KEY = "licensesBilling";
export const LICENSE_WORKS_KEY = "licensesWork";
export const SHOPPINGCART_KEY = "licenses";
export const LICENSE_FILE_IMPORT_KEY = "licensesFileImport";
export const LICENSE_WORKS_COLUMNS_TO_FILTER: string[] = ['key', 'expandedTitles', 'expandedWorkNumber', 'priceVAT', 'actualPrice', 'priceTotal', 'priceNet', 'licenseRequestID', 'licenseRequestWorkID', 'deletedDate', 'state', 'source', 'currencyActual', 'currencyExchangeRate', 'priceDate', 'notes', 'expirationDate', 'administrationPrice', 'workID', 'currencyCalculation'];
export const INSTRUMENTATION_KEY = "instrumentation";
export const WORK_SUBJECT_KEY = "subjects";
export const WORK_NAME_KEY = "workName";

export const MY_PACKAGES_WORKS_KEY = "myPackagesWorks";
export const MY_PACKAGES_PRODUCTS_KEY = "myPackagesProducts";
export const MY_PACKAGES_AGREEMENTS_KEY = "myPackagesAgreements";
export const LANGUAGE_OBJECT_KEY = "language";

export const TOOLBAR_POSITION_BREAKPOINT = 105;
export const EVENT_HANDLER_THROTTLE_TIME = 100;

export const TITLES_TABLE_HEADERS: IDataItem[] = [
    { value: "Type", inputType: "dropdown" },
    { value: "Language", inputType: "dropdown" },
    { value: "Title", inputType: "text" }
];

export const REPERTOIRE_PAGE_JOBS: string[] = ["WBP", "WM", "WMF", "IPI", "PBP", "PM", "CMU", "ABP"];

export type ContributorsDataViewFields = (
    "inheritFrom"
    | "breakInheritance"
    | "doNotInherit"
    | "inheritanceBrokenMessage"
    | "inheritanceAddedMessage"
    | "cannotSetInheritanceMessage"
);

export const COPY_METHOD_TOOLBAR = "Copy"
export const UNDO_METHOD_TOOLBAR = "Undo"
export const MERGE_METHOD_TOOLBAR = "Merge"
export const ADD_METHOD_TOOLBAR = "Add"
export const ADD_REPRESENTATION_TOOLBAR = "Add Representation"
export const IP_TRANSFER_TOOLBAR = "IP Transfer"
export const CRM_EXTENDED_DETAILS_TOOLBAR = "CRM Extended Details"
export const MATCH_METHOD_TOOLBAR = "Match"
export const SAVE_METHOD_TOOLBAR = "Save"
export const HISTORY_METHOD_TOOLBAR = "History"
export const ADD_TO_PACKAGES_TOOLBAR = "Add to Packages"
export const DELETE_METHOD_TOOLBAR = "Delete"
export const ASSING_TO_METHOD_TOOLBAR = "Assing"
export const UPDATE_WORKFLOW_STATUS_METHOD_TOOLBAR = "UpdateWorkflowStatus"
export const DESELECT_ALL_METHOD_TOOLBAR = "deselect"
export const START_WORKFLOW_SESSION_METHOD_TOOLBAR = "StartWorkflowSession"
export const ALLOCATE_METHOD_TOOLBAR = "Allocate"
export const SET_CD_ALLOCATION_METHOD_TOOLBAR = "Set CD Allocation Schedule"
export const UNDO_ALLOCATION_METHOD_TOOLBAR = "Undo Allocation"
export const RUN_QA_METHOD_TOOLBAR = "Ran"
export const UNDO_RUN_QA_METHOD_TOOLBAR = "Not Ran"
export const ALLOCATE_STATUS_VALUE = "Allocated"
export const QA_STATUS_VALUE = "Ran"
export const UNDO_ALLOCATION_STATUS_VALUE = "Not Allocated"
export const UNDO_QA_STATUS_VALUE = "Not Ran"
export const EXPORT_METHOD_TOOLBAR = "Export"
export const IMPORT_METHOD_TOOLBAR = "Import"
export const IP_TRANSFER = "IPTransfer"
export const HAMBURGER_MENU_TOOLBAR = "Hamburgermenu"
export const RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR = "ResetCache"
export const SUBMIT_LICENSE = "submitLicense"
export const PROCESS_CLAIM_TOOLBAR = "Process"

//button key

export const SEARCH_DATA_FIELD = "search";


export const EMPTY_STRING_VALUE = "";

//WORKFLOW KEYS
export const WORKFLOW_INPROGRESS = "workflowInProgress";
export const WORKFLOW_TYPE = "workflowType";
export const WORKFLOW_CREATED_DATE = "workflowCreatedDate";
export const WORKFLOW_ASSIGNED_TO = "workflowAssignedTo";
export const WORKFLOW_MESSAGE = "workflowMessage";
export const WORKFLOW_COMPLETE_ON_SAVE = "worflowCompleteOnSave";
export const WORKFLOW_EXPAND = "expandWorkflows";
export const WORKFLOW_COLLAPSE = "collapseWorkflows";

//Workflow search vew state key
export const WORKFLOW_ENTITY_TYPE_STATE_KEY: IRepertoireStateKeys = 'entityType';
export const WORKFLOW_WORKFLOW_TYPE_STATE_KEY: IRepertoireStateKeys = 'workflowType';
export const WORKFLOW_SELECTED_STATE_KEY: IRepertoireStateKeys = 'selected';
export const WORKFLOW_RELATED_ENTITY_STATE_KEY: IRepertoireStateKeys = 'relatedEntity';
export const WORKFLOW_TYPE_VALIDATION_ERROR: string = 'Validation';
export const WORKFLOW_TYPE_MATCHING_ERROR: string = 'Match';

//Workflow page toolbar keys
export const ASSING_TO_WORKFLOW_STATE_KEY: IRepertoireStateKeys = "assingTo";
export const ASSING_TO_SEARCH_WORKFLOW_STATE_KEY: IRepertoireStateKeys = "assignedTo";
export const UPDATE_STATUS_WORKFLOW_STATE_KEY: IRepertoireStateKeys = "updateStatus";
export const DESELECT_ALL_WORKFLOW_STATE_KEY: IRepertoireStateKeys = "deselect";
export const START_WORKFLOW_SESSION_STATE_KEY: IRepertoireStateKeys = "startSession";
export const ASSING_VIEW_TITLE_STATE_KEY: IRepertoireStateKeys = "title";
export const ASSING_VIEW_DESCRIPTION_STATE_KEY: IRepertoireStateKeys = "description";
export const WORKFLOW_PAGE_TOOLBAR: string = "WorkflowPageToolbar";
export const UPDATE_WORKFLOW_VIEW_TITLE_STATE_KEY: IRepertoireStateKeys = "title";
export const UPDATE_WORKFLOW_VIEW_DESCRIPTION_STATE_KEY: IRepertoireStateKeys = "description";
export const UPDATE_WORKFLOW_VIEW_STATUS_FIELD_STATE_KEY: IRepertoireStateKeys = "status";
export const UPDATE_WORKFLOW_FIELDS_VIEW: string = "UpdateWorkflowFields";
export const IS_ANY_COMPLETED_WORKFLOW_ERROR: string = 'anySelectedCompleted';
export const WORKFLOW_COMPLETED_STATUS: string = 'Completed';
export const WORKFLOW_DIRECT_COMPLETE_ON_SAVE: string = 'worflowDirectCompleteOnSave';
export const WORKFLOW_APPROVE: string = 'workflowApprove';
export const WORKFLOW_REJECT: string = 'workflowReject';

export const REFERENCE_STATE_KEY: IRepertoireStateKeys = "reference";
export const RFERENCE_KEY_VALUE_STATE_KEY: IRepertoireStateKeys = "referenceKey";
export const REFERENCE_VALUE_STATE_KEY: IRepertoireStateKeys = "referenceValue";

export const VALUE_STATE_KEY: IRepertoireStateKeys = "value";
export const VALUE_KEY_VALUE_STATE_KEY: IRepertoireStateKeys = "valueKey"
export const VALUE_MIN_STATE_KEY: IRepertoireStateKeys = "valuesMin";
export const VALUE_MAX_STATE_KEY: IRepertoireStateKeys = "valuesMax";

export const MEDLEY_TYPE_HAS_DERIVED_VERSIONS: string = "UCO";


//TabIcon html
export const WORK_INTRAY_TAB_ICON = "M205.25,236L226,273.281h44L290.094,236H284l-18,32H230l-18-32h-6.75Zm66.024-28.234q-0.145-4.165-.146-6.256,0-1.057-.5-1.058-1.562,0-4.7.132t-4.695.133a0.487,0.487,0,0,0-.608.476q0,2.117.2,6.388t0.2,6.415a22.46,22.46,0,0,1-1.826,4.973q-1.639,3.836-2.38,5.131a1.537,1.537,0,0,1-.529.688,8.682,8.682,0,0,1-1.111-2.513q-2.726-7.248-2.725-8.6v-1.349q-0.027-1.878.106-5.5,0.132-3.729.132-5.5,0-.714-5.052-0.714a46,46,0,0,0-4.656.159,1.244,1.244,0,0,0-.581.172,1.226,1.226,0,0,0-.08.595q0,0.793.08,6.216,0.078,3.756-.027,6.19-0.053,1.3-2.539,8.411-0.873,2.539-1.085,2.54a1.537,1.537,0,0,1-.529-0.688q-0.793-1.349-2.539-5.158-1.932-4.258-1.931-5.052,0-2.117.2-6.389t0.2-6.388a0.5,0.5,0,0,0-.609-0.5q-1.535,0-4.589-.133t-4.563-.132q-0.53,0-.529.873,0,2.117-.146,6.335T223.568,214q0,1.507,10.369,22.8a1.179,1.179,0,0,0,.741.767,24.463,24.463,0,0,0,3.809.159,24.157,24.157,0,0,0,3.465-.159,1.517,1.517,0,0,0,.608-0.172,2.775,2.775,0,0,0,.371-0.542q1.03-2.38,1.481-3.65l2.936-7.433,2.883,7.353q1.35,3.44,1.508,3.73a2.01,2.01,0,0,0,.344.5,2.456,2.456,0,0,0,.635.211,21.449,21.449,0,0,0,3.518.159,24.23,24.23,0,0,0,3.756-.159,1,1,0,0,0,.5-0.238,3.815,3.815,0,0,0,.291-0.529q0.714-1.349,5.713-12.141,4.92-10.581,4.92-10.66Q271.419,211.932,271.274,207.766ZM244,245h9v9h7l-11.5,10L237,254h7v-9Z";
export const WORK_INTRAY_TAB_ICON_TRANSFORM = "translate(-205.25 -200.438)"
export const WORK_REPERTOIRE_TAB_ICON = "M171.885,81.293H129.459L108.245,44.551,129.459,7.808h42.426L193.1,44.551Zm-2.571-5h-36.56L114.475,44.63l18.279-31.661h36.56L187.593,44.63Zm2.8-41.36q-0.129-3.691-.129-5.543,0-.937-0.445-0.937-1.383,0-4.16.117t-4.16.117a0.432,0.432,0,0,0-.539.422q0,1.875.175,5.66t0.176,5.684a19.876,19.876,0,0,1-1.617,4.406q-1.453,3.4-2.109,4.547a1.363,1.363,0,0,1-.469.609,7.693,7.693,0,0,1-.985-2.227q-2.413-6.422-2.414-7.617v-1.2q-0.023-1.664.094-4.875,0.117-3.3.117-4.875,0-.633-4.476-0.633a40.791,40.791,0,0,0-4.125.141,1.116,1.116,0,0,0-.516.152,1.091,1.091,0,0,0-.07.527q0,0.7.07,5.508,0.071,3.328-.023,5.484a46.32,46.32,0,0,1-2.25,7.453,6.838,6.838,0,0,1-.961,2.25,1.348,1.348,0,0,1-.469-0.609q-0.7-1.2-2.25-4.57a19.919,19.919,0,0,1-1.711-4.477q0-1.875.176-5.66t0.176-5.66a0.44,0.44,0,0,0-.539-0.445q-1.361,0-4.067-.117t-4.043-.117q-0.47,0-.469.773,0,1.875-.128,5.613t-0.129,5.613q0,1.336,9.187,20.2a1.046,1.046,0,0,0,.656.68,21.7,21.7,0,0,0,3.375.141,21.442,21.442,0,0,0,3.071-.141,1.356,1.356,0,0,0,.539-0.152A2.494,2.494,0,0,0,147,60.7q0.914-2.109,1.312-3.234l2.6-6.586,2.555,6.516q1.2,3.047,1.336,3.3a1.774,1.774,0,0,0,.3.445,2.152,2.152,0,0,0,.563.188,19.029,19.029,0,0,0,3.117.141,21.5,21.5,0,0,0,3.328-.141,0.887,0.887,0,0,0,.445-0.211,3.145,3.145,0,0,0,.258-0.469q0.633-1.2,5.063-10.758,4.359-9.375,4.359-9.445Q172.246,38.622,172.117,34.931Z";
export const WORK_REPERTOIRE_TAB_ICON_TRANSFORM = "translate(-106)";
export const PRODUCT_INTRAY_TAB_ICON = "M925.25,236L946,273.281h44L1010.09,236H1004l-18,32H950l-18-32h-6.75ZM964,245h9v9h7l-11.5,10L957,254h7v-9Zm16.63-40.454a14.635,14.635,0,0,0-10.221-3.987q-3.853-.107-8.054-0.107-7.118,0-9.2.268c-0.571.071-.839,0.277-0.8,0.615l0.027,0.375q0.723,9.258.722,17.659,0,2.97-.093,8.924t-0.094,8.923a0.685,0.685,0,0,0,.776.776q1.632,0,4.9.094t4.9,0.093a0.521,0.521,0,0,0,.562-0.615q0-1.2-.241-3.639t-0.241-3.666a0.953,0.953,0,0,1,1.151-.99q1.069,0,3.224.121t3.224,0.12q6.394,0,10.034-4.254,3.4-4.014,3.4-10.489a13.723,13.723,0,0,0-3.96-10.221h0Zm-12.522,16.509q-4.816,0-4.816-.535,0-.829-0.067-2.448t-0.067-2.449a43.56,43.56,0,0,1,.187-6.18q0.215-.482,4.5-0.482,6.073,0,6.074,6.234,0,5.861-5.806,5.86h0Z";
export const PRODUCT_INTRAY_TAB_ICON_TRANSFORM = "translate(-925.25 -200.438)";
export const PRODUCT_REPERTOIRE_TAB_ICON = "M268.885,171.293H226.459l-21.214-36.742,21.214-36.743h42.426L290.1,134.551Zm-2.571-5h-36.56L211.475,134.63l18.279-31.661h36.56l18.279,31.661Zm-7.193-44.255a12.826,12.826,0,0,0-8.953-3.492q-3.375-.093-7.055-0.093-6.234,0-8.062.234-0.75.095-.7,0.539l0.023,0.328q0.633,8.109.633,15.469,0,2.6-.082,7.816t-0.082,7.817a0.6,0.6,0,0,0,.68.679q1.428,0,4.289.082T244.1,151.5a0.457,0.457,0,0,0,.492-0.539q0-1.054-.211-3.187t-0.211-3.211a0.834,0.834,0,0,1,1.008-.867q0.938,0,2.824.1t2.824,0.106a10.953,10.953,0,0,0,8.789-3.727,13.734,13.734,0,0,0,2.977-9.187,12.023,12.023,0,0,0-3.469-8.954h0ZM248.152,136.5q-4.218,0-4.218-.468,0-.726-0.059-2.145t-0.059-2.144a38.222,38.222,0,0,1,.164-5.414q0.188-.423,3.938-0.422,5.32,0,5.32,5.461,0,5.131-5.086,5.132h0Z";
export const PRODUCT_REPERTOIRE_TAB_ICON_TRANSFORM = "translate(-205.25 -97.813)";
export const AGREEMENT_INTRAY_TAB_ICON = "M1022.25,236L1043,273.281h44L1107.09,236H1101l-18,32h-36l-18-32h-6.75Zm38.75,9h9v9h7l-11.5,10L1054,254h7v-9Zm16.18-25.278q-6.225-16.134-7.51-18.888a1.2,1.2,0,0,0-.91-0.866c-0.46,0-1.15.031-2.06,0.092s-1.62.092-2.09,0.092q-0.735,0-2.22-.131t-2.19-.131a1.13,1.13,0,0,0-.73,1q-0.555,1.732-6.98,19.124-5.925,16.082-5.93,16.763,0,0.762,4.02.761a44.131,44.131,0,0,0,5.98-.21q0.7-.183,1.2-2.361a7.2,7.2,0,0,1,1-2.807c0.19-.157,1.77-0.236,4.75-0.236a57.859,57.859,0,0,1,7.03.184c0.33,0.175.79,1.521,1.39,4.04a1.412,1.412,0,0,0,1.13,1.18,32.94,32.94,0,0,0,4.82.21,30.043,30.043,0,0,0,4.88-.21,0.719,0.719,0,0,0,.66-0.655q0-.71-6.24-16.947h0Zm-10.63-2.125a25.2,25.2,0,0,1,1.21,4.774,1.161,1.161,0,0,1-.05.368q-0.2.42-1.92,0.419c-1.24,0-2.13-.017-2.68-0.052-1.04-.035-1.57-0.21-1.57-0.525a26.792,26.792,0,0,1,1.42-4.8q1.3-3.856,1.86-5.011a37.3,37.3,0,0,1,1.73,4.827h0Z";
export const AGREEMENT_INTRAY_TAB_ICON_TRANSFORM = "translate(-1022.25 -199.875)";
export const AGREEMENT_REPERTOIRE_TAB_ICON = "M365.885,171.293H323.459l-21.214-36.742,21.214-36.743h42.426L387.1,134.551Zm-2.571-5h-36.56L308.475,134.63l18.279-31.661h36.56l18.279,31.661Zm-7.474-30.684q-5.554-14.415-6.7-16.875a1.077,1.077,0,0,0-.821-0.774q-0.609,0-1.839.082t-1.864.082q-0.656,0-1.98-.117t-1.957-.117a1.011,1.011,0,0,0-.656.891q-0.494,1.547-6.235,17.086-5.3,14.367-5.3,14.976,0,0.681,3.586.68a39.357,39.357,0,0,0,5.344-.188q0.633-.164,1.078-2.109a6.437,6.437,0,0,1,.891-2.508,18.542,18.542,0,0,1,4.242-.211,51.792,51.792,0,0,1,6.281.164q0.445,0.235,1.242,3.61a1.263,1.263,0,0,0,1.008,1.054,29.586,29.586,0,0,0,4.313.188,26.873,26.873,0,0,0,4.359-.188,0.641,0.641,0,0,0,.586-0.586q0-.632-5.578-15.14h0Zm-9.492-1.9a22.891,22.891,0,0,1,1.078,4.266,0.915,0.915,0,0,1-.047.328q-0.165.375-1.711,0.375-1.665,0-2.391-.047-1.406-.047-1.406-0.469a24.3,24.3,0,0,1,1.266-4.289q1.171-3.444,1.664-4.476a33.148,33.148,0,0,1,1.547,4.312h0Z";
export const AGREEMENT_REPERTOIRE_TAB_ICON_TRANSFORM = "translate(-302.25 -97.813)";


// Product number types
export const AVSeasonCode = 'AVSeasonCode'
export const AVEpisode = 'AVEpisode'
export const AVProductionYear = 'AVProductionYear'
export const AVProductionNumber = 'AVProductionNumber'
export const AVSocietyCode = 'AVSociety'
export const AVSocietySeriesNumber = 'AVSocietySeriesNumber'
export const AVWorkNumber = 'AVWorkNumber'
export const CatalogueNumber = 'CatalogueNumber'


export const USAGE_SIDE_MAINTENANCE_KEY: IRepertoireStateKeys = "maintenance";
export const USAGE_SIDE_INGESTION_KEY: string = "ingestion";

export const USAGE_PAGE_FORM_ITEM_SIZE: string = "form-group col-md-4 col-sm-6 col-xs-12";
export const USAGE_PAGE_FORM_WIDE_SIZE: string = "form-group col-md-12 col-sm-6 col-xs-12";
export const USAGE_PAGE_FORM_LARGE_BOX_SIZE: string = "form-group col-md-16 col-sm-6 col-xs-12";
export const USAGE_PAGE_FORM_ACTION_BUTTON_SIZE: string = "form-group col-md-2 col-sm-4 col-xs-4";

export const USAGE_PAGE_FORM_ACTION_BUTTON_SIZE_3: string = "form-group col-md-3 col-sm-6 col-xs-6";
export const USAGE_PAGE_FORM_ACTION_BUTTON_SIZE_2: string = "form-group col-md-2 col-sm-6 col-xs-6";

export const USAGETYPE_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "type";
export const DISTRIBUTION_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "distribution";
export const POOL_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "pool";
export const SOURCEMAJOR_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "sourceMajor";
export const SOURCEMINOR_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "sourceMinor";
export const ALLOCATIONSTATUS_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "allocationStatus";
export const USAGEWEIGHT_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "usageWeight";
export const MATCHSTATUS_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "matchStatus";
export const TITLE_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "title";
export const FILENAME_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "fileName";
export const COMPOSER_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "composer";
export const ARTIST_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "artist";
export const OWNER_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "rightShareOwner";
export const LWNUMBER_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "licenseesWorkNumber";
export const MATCHES_SEARCH_USAGES_STATE_KEY: IRepertoireStateKeys = "matches";
export const MATCHINGWORKS_KEY: IRepertoireStateKeys = "matchingWorks";
export const MATCHINGPRODUCTS_KEY: IRepertoireStateKeys = "matchingProducts";
export const MATCHSTATUS_USAGE_MAINTENANCE_STATE_KEY: IRepertoireStateKeys = "matchStatus";
export const PAYMENTRUN_STATE_KEY: IRepertoireStateKeys = "paymentRun";
export const PAYMENTRUN_DISTRIBUTION_CODE_STATE_KEY: IRepertoireStateKeys = "distributionCode";
export const USAGE_DETAILS_ROWS_SECTION_STATE_KEY: IRepertoireStateKeys = "usageDetailsRowsSection";
export const IP_NAMES_SECTION_STATE_KEY: IRepertoireStateKeys = "ipNames";


export const WORK_STATE_KEY: IRepertoireStateKeys = "work";
export const PRODUCT_STATE_KEY: IRepertoireStateKeys = "product";
export const DISTRIBUTIONCODE_STATE_KEY: IRepertoireStateKeys = "distributionCode";


// Components
export const USAGELOADING_VIEW: string = "UsageLoadingView";
export const USAGESEARCH_VIEW: string = "UsageSearchView";
export const USAGE_SIDE_MENU: string = "UsageSideMenuDataView";
export const FINDUSAGE_PAGE_VIEW: string = "FindUsagePage";
export const USAGE_PAGE_VIEW: string = "UsagePage";
export const USAGERESULTTABLE_VIEW: string = "ResultsTable";
export const USAGE_MAINTENANCE_GENERAL_DATA_VIEW: string = "UsageMaintenanceGeneralDataView";
export const USAGE_MAINTENANCE_GRIDS_VIEW: string = "UsageMaintenanceGridsView";
export const USAGE_TO_REPERTOIRE_SEARCH_VIEW: string = "UsageToRepertoireSearchView";
export const USAGE_SAVE_RESULT: string = "SaveResult";


// SearchView Component Instances
export const SEARCH_VIEW_USAGES: string = "usages";
export const USAGE_WORKS_SEARCH_KEY: string = "UsageWorksSearch";
export const USAGEGROUPUPDATE_ACTION_STATE_KEY: IRepertoireStateKeys = "update_action";
export const SEARCH_VIEW_USAGES_USAGE_TO_WORK: string = "usagesUsageToWork";
export const SEARCH_VIEW_USAGES_USAGE_TO_PRODUCT: string = "usagesUsageToProduct";
export const SEARCH_VIEW_SUBJECTS: string = "subjectsSearch";



// Usage Maintenance State Keys
export const USAGE_GROUP_TITLE_STATE_KEY: IRepertoireStateKeys = "title";
export const USAGE_GROUP_COMPOSER_STATE_KEY: IRepertoireStateKeys = "composer";
export const USAGE_GROUP_ARTIST_STATE_KEY: IRepertoireStateKeys = "artist";
export const USAGE_GROUP_PRODUCT_TYPE_STATE_KEY: IRepertoireStateKeys = "productType";
export const USAGE_GROUP_MATCH_STATUS_STATE_KEY: IRepertoireStateKeys = "matchStatus";
export const USAGE_GROUP_INCLUDE_FEES_IN_ERROR_STATE_KEY: IRepertoireStateKeys = "includeFeesInError";
export const USAGE_GROUP_APPROVAL_STATUS_STATE_KEY: IRepertoireStateKeys = "approvalStatus";
export const USAGE_GROUP_ALLOCATION_STATUS_STATE_KEY: IRepertoireStateKeys = "allocationStatus";
export const USAGE_GROUP_SOURCE_MAJOR_STATE_KEY: IRepertoireStateKeys = "sourceMajor";
export const USAGE_GROUP_SOURCE_MINOR_STATE_KEY: IRepertoireStateKeys = "sourceMinor";
export const USAGE_GROUP_USAGE_WEIGHT_STATE_KEY: IRepertoireStateKeys = "usageWeight";
export const USAGE_GROUP_USAGE_WEIGHT_CATEGORY_STATE_KEY: IRepertoireStateKeys = "usageWeightCategory";
export const USAGE_GROUP_LICENSEE_WORK_NUMBER_STATE_KEY: IRepertoireStateKeys = "licenseesWorkNumber";
export const USAGE_GROUP_DISTRIBUTION_STATE_KEY: IRepertoireStateKeys = "distribution";
export const USAGE_GROUP_DISTRIBUTION_STATUS_STATE_KEY: IRepertoireStateKeys = "distributionStatus";
export const USAGE_GROUP_DISTRIBUTION_DATE_STATE_KEY: IRepertoireStateKeys = "distributionDate";
export const USAGE_GROUP_QA_STATUS_STATE_KEY: IRepertoireStateKeys = "qaStatus";
export const USAGE_GROUP_POOL_CODE_STATE_KEY: IRepertoireStateKeys = "poolCode";
export const USAGE_AMOUNT_STATE_KEY: IRepertoireStateKeys = "amount";
export const USAGE_SENDING_LICENSEE_STATE_KEY: IRepertoireStateKeys = "sendingLicensee";
export const DEFINITE_MATCH: IRepertoireStateKeys = "DefiniteMatch";
export const POTENTIAL_MATCH: IRepertoireStateKeys = "PotentialMatch";
export const USAGE_ID_STATE_KEY: IRepertoireStateKeys = "id";
export const USAGE_IPI_NUMBER_STATE_KEY: IRepertoireStateKeys = "ipiNumber";
export const USAGE_NAME_STATE_KEY: IRepertoireStateKeys = "name";
export const USAGE_SHARE_STATE_KEY: IRepertoireStateKeys = "share";
export const USAGE_APPLICABLE_ROYALTY_RATE_STATE_KEY: IRepertoireStateKeys = "applicableRoyaltyRate";
export const USAGE_LICENSE_REF_ID_STATE_KEY: IRepertoireStateKeys = "licenseRefID";
export const USAGE_ROYALTY_RATE_STATE_KEY: IRepertoireStateKeys = "royaltyRate";
export const USAGE_INBOUND_FEES_IN_ERROR_STATE_KEY: IRepertoireStateKeys = "inboundFeesInError";
export const USAGE_OUTBOUND_FEES_IN_ERROR_STATE_KEY: IRepertoireStateKeys = "outboundFeesInError";
export const LOCATION_SECTION_TITLE: IRepertoireStateKeys = "locationSectionTitle";
export const LOCATION_GOOGLE_VENUE: IRepertoireStateKeys = "locationGoogleVenue";
export const LOCATION_KNOWN_MUSIC_VENUE: IRepertoireStateKeys = "locationKnownMusicVenue";
export const LOCATION_COUNTRY: IRepertoireStateKeys = "locationCountry";
export const LOCATION_CITY: IRepertoireStateKeys = "locationCity";
export const LOCATION_VENUE: IRepertoireStateKeys = "locationVenue";
export const LOCATION_EVENT_NAME: IRepertoireStateKeys = "locationEventName";
export const LOCATION_ADD_DETAILS_MANUALLY: IRepertoireStateKeys = "locationAddDetailsManually";
export const SET_LIST_ADDITIONAL_SECTION_TITLE: IRepertoireStateKeys = "setListAdditionalSectionTitle";
export const SET_LIST_ADDITIONAL_PERFORMANCE_TYPE: IRepertoireStateKeys = "setListAdditionalPerformanceType";
export const SET_LIST_ADDITIONAL_STAGE: IRepertoireStateKeys = "setListAdditionalStage";
export const SET_LIST_ADDITIONAL_PROMOTER: IRepertoireStateKeys = "setListAdditionalPromoter";
export const SET_LIST_ADDITIONAL_OTHER_ARTIST_PERFORM: IRepertoireStateKeys = "setListAdditionalOtherArtistPerform";
export const VENUE_DETAILS_CONTACT_FIRST_NAME: IRepertoireStateKeys = "contactFirstName";
export const VENUE_DETAILS_CONTACT_LAST_NAME: IRepertoireStateKeys = "contactLastName";
export const VENUE_DETAILS_CONTACT_PHONE_NUMBER: IRepertoireStateKeys = "contactPhoneNumber";
export const VENUE_DETAILS_VENUE_TYPE: IRepertoireStateKeys = "venueType";
export const VENUE_DETAILS_OTHER_VENUE_TYPE: IRepertoireStateKeys = "otherVenueType";
export const VENUE_DETAILS_TITLE: IRepertoireStateKeys = "venueDetailsTitle";




// Data grid keys
export const USAGE_ROWS_SECTION_KEY = "usageRowsSection";
export const USAGE_ROW_DROPDOWN_KEY = "usageRowDropdown";
export const USAGE_DETAILS_ROWS_SECTION_KEY = "usageDetailsRowsSection";
export const USAGE_PRODUCT_DETAILS_ROWS_SECTION_KEY = "usageProductDetailsRowsSection";
export const USAGE_MATCHING_WORKS_SECTION_KEY = "usageMatchingWorksSection";
export const USAGE_MATCHING_PRODUCTS_SECTION_KEY = "usageMatchingProductsSection";
export const USAGE_GROUP_WORK_ALLOCATIONS_SECTION_KEY = "usageGroupWorkAllocationsSection";
export const USAGE_GROUP_PRODUCT_ALLOCATIONS_SECTION_KEY = "usageGroupProductAllocationsSection";
export const USAGE_GROUP_ATTACHMENTS_SECTION_KEY = "usageGroupAttachments"
export const LICENSE_MAINTENANCE_WORK_GRID_SECTION_KEY: string = 'licensesWorkGrid';
export const USAGE_DETAILS_ROWS_INBOUNDFEESINERROR: string = 'inboundFeesInError';
export const USAGE_DETAILS_ROWS_OUTBOUNDFEESINERROR: string = 'outboundFeesInError';
export const USAGE_DETAILS_ROWS_ACTIONS: string = 'actions';

// Matching Works
export const MATCHING_WORKS_WORKNAME_TYPE_OT = "OT";
export const CONFIGURATION_PARAMETER_USAGE_SORT_NUMBER_MATCHING_WORKS_KEY = "SortNumberMatchingWorks";
export const CONFIGURATION_PARAMETER_USAGE_MAINTENANCE_GROUP = "UsageMaintenance";



export const USAGETOWORK: string = 'Usage To Work';
export const USAGETOPRODUCT: string = 'Usage To Product';
export const USAGETOWORK_NO_SPACES: string = 'UsageToWork';
export const USAGETOPRODUCT_NO_SPACES: string = 'UsageToProduct';
export const USAGE_WORK_NUMBER_COMPONENT_FIELD_NAME: string = 'Work Number';
export const USAGE_PRODUCT_NUMBER_COMPONENT_FIELD_NAME: string = 'Product Number';
export const USAGE_MATCH_MAKE_DEFINITE: string = 'makeDefinite';
export const USAGE_MATCHSTATUS_DEFINITE_MATCH: string = 'Definite Match';
export const USAGE_MATCHSTATUS_POTENTIAL_MATCH: string = 'Potential Match';
export const USAGE_MATCHSTATUS_NO_MATCH_FOUND: string = 'No Match Found';

//Toolbar

export const USAGE_MAINTENANCE_TOOLBAR: string = "UsageMaintenanceToolbar";

//Metadata Work
export const TRANSLATION_TYPE = "typeOfTranslation";
export const BINDING_TYPE = "typeOfBinding";
export const MEDIUM_TYPE = "medium";
export const MEDIUM_CATEGORY_TYPE = "typeOfMedium";
export const SHEET_MUSIC_TYPE = "sheetMusicType";
export const BOOK_TYPE = "typeOfBook";
export const TEACHING_TYPE = "typeOfTeaching";
export const AVAILABILITY = "availability";
export const PUBLICATION_TYPE = "typeOfPublication"
export const PERIODICITY_TYPE = "periodicity"

//Other Indicators
export const CREATIVE_COMMONS = "CREATIVE_COMMONS";


// Component instances
export const COMPONENT_INSTANCE_WORKS = 'works';
export const COMPONENT_INSTANCE_AGREEMENTS = 'agreements';
export const COMPONENT_INSTANCE_IPS = 'ips';
export const COMPONENT_INSTANCE_PRODUCTS = 'products';
export const COMPONENT_INSTANCE_CUE_SHEET = 'cueSheet';
export const COMPONENT_INSTANCE_ARTISTS = 'artists';
export const COMPONENT_INSTANCE_USAGES = 'usages';
export const COMPONENT_INSTANCE_POOLS = 'pools';
export const COMPONENT_INSTANCE_DISTRIBUTIONS = 'distributions'

export const USAGE_TO_WORK = 'Usage to Work';
export const USAGE_TO_PRODUCT = 'Usage to Product';
export const GLOBAL = 'Global';
export const ADD_ALL_FOR_SHARE_SET = 'addAllForShareSet';

export const CREATION_CLASS: string = 'CreationClass'
export const ALL_CREATION_CLASSES: string = 'AllCreationClasses'

// Usage ingestion
export const USAGE_TO_PRODUCT_INGESTION_PATH: string = 'usage/product based'
export const USAGE_TO_WORK_INGESTION_PATH: string = 'usage/work based'
export const USAGE_SOCIETY_PATH: string = 'usage/society'
export const USAGE_TO_PRODUCT_MATCH_TYPE_CODE: string = 'Usage to Product'
export const USAGE_TO_WORK_MATCH_TYPE_CODE: string = 'Usage to Work'
export const USAGE_SOCIETY_CODE: string = 'Usage Society'
export const USAGE_TO_WORK_MATCH_TYPE_ID: string = '1'
export const USAGE_TO_PRODUCT_MATCH_TYPE_ID: string = '4'

// Contributors Share Type Code

export const LEVY_COPY_TYPE_CODE: string = 'CP'
export const LICENCE_COPY_TYPE_CODE: string = 'CL'

export const NOT_ALLOCATED_STATUS: string = 'Not Allocated';

// Work Type Code

export const ARTICLE_WORK_TYPE_CODE = 'PP-A';
export const MAGAZINE_WORK_TYPE_CODE = 'PP';
export const SHEET_MUSIC_WORK_TYPE_CODE = 'MI';
export const CHAPTER_WORK_TYPE_CODE = 'LI-C';
export const BOOK_WORK_TYPE_CODE = 'LI';

//Work Flag Code

export const UNIVERSITY_DIST_COMP_CODE = 'COMPENSACION_USO_UNIVERSITARIO';
export const DISCONTINUED_CODE = 'DESCATALOGADA_REGLA';

//Work flow

export const USAGE_WORKFLOW = 'USAGE_WORKFLOW';
export const WORK_WORKFLOW = 'WORK_WORKFLOW';
export const PRODUCT_WORKFLOW = 'PRODUCT_WORKFLOW';
export const CLAIM_WORKFLOW = 'CLAIM_WORKFLOW'
export const AGREEMENT_WORKFLOW = 'AGREEMENT_WORKFLOW'

//IP Transfer 
export const IP_TRANSFER_RECIPIENT_STATE_KEY: IRepertoireStateKeys = "recipientIP";
export const IP_TRANSFER_TYPE_STATE_KEY: IRepertoireStateKeys = "transferType";
export const IP_TRANSFER_SUBTYPE_STATE_KEY: IRepertoireStateKeys = "transferSubType";
export const IP_TRANSFER_PERCENTAGE_STATE_KEY: IRepertoireStateKeys = "transferPercentage";
export const IP_TRANSFER_IP_TYPE_STATE_KEY: IRepertoireStateKeys = "transferIpType";
export const IP_TRANSFER_STARTDATE_STATE_KEY: IRepertoireStateKeys = "transferStartDate";
export const IP_TRANSFER_ENDDATE_STATE_KEY: IRepertoireStateKeys = "transferEndDate";
export const IP_TRANSFER_REGISTRATIONDATE_STATE_KEY: IRepertoireStateKeys = "transferRegistrationDate";

//PaymentRun
// Payment Run Maintenance State Keys
export const PAYMENTRUN_DATE_STATE_KEY: IRepertoireStateKeys = "paymentRunDate";
export const DELETE_PAYMENTRUN_STATE_KEY: IRepertoireStateKeys = "deletePaymentRun";
export const ADD_NEW_PAYMENTRUN_STATE_KEY: IRepertoireStateKeys = "addNewPaymentRun";
export const UNDO_CHANGES_PAYMENTRUN_STATE_KEY: IRepertoireStateKeys = "undoChanges";
export const COPY_PAYMENTRUN_STATE_KEY: IRepertoireStateKeys = "copyPaymentRun";


//CEDRO
//Request parameters State Keys

export const TO_ASK_ORIGIN: string = "pedir";
export const DATABASE_ORIGIN: string = "bbdd";
export const TO_LOOKUP_ORIGIN: string = "buscar";
export const TO_CALCULATE_ORIGIN: string = "Calcular";

export const IP_NAMES_EDITABLE_FIELD_TITLE: string = "Edit names fields";

export const COMMENT_CATEGORY_FLAG_CONFIGURATION_KEY: string = "CommentCategoryFlag";
export const COMMENT_CATEGORY_KEY: string = "commentCategory";
export const CUSTOMER_CONFIGURATION_GROUP: string = "CustomerConfiguration";

export const CLAIM_TYPE_SHARES_IN_SUSPENSE: string = "Shares in Suspense";
export const CLAIM_TYPE_MISSING_WORK: string = "Missing Work";

export const AGREEMENT_MAINTENANCE_CONFIGURATION_GROUP: string = "AgreementMaintenance";
export const AGREEMENT_ATTACHMENT_TYPE_REQUIRED_CONFIGURATION_KEY: string = "AgreementAttachmentTypeRequired";
export const ATTACHMENT_TYPE_MANDATORY: string = "Mandatory";
export const ATTACHMENT_TYPE_OPTIONAL: string = "Optional";
export const ATTACHMENT_TYPE_NOT_REQUIRED: string = "NotRequired";


//Job Type
export const QA_JOB_TYPE: string = "QA"
export const QAScheduledJob: string = "QAScheduledJob";
export const CDAllocationScheduledJob: string = "CDAllocationScheduledJob";
export const ALK_JOB_TYPE: string = "ALK"


//other product
export const ORIGIN_OF_REVISION: string = "sptmlqa.product.product"

//Lookup Types
export const LOOKUP_WORKSOURCE: string = "WorkSource";
export const LOOKUP_WORKSTATUS: string = "WorkStatus";

// CWR Generation Job Parameters
export const SCHEDULE: string = "Schedule";
export const HIGH_WATER_MARK: string = "High Water Mark";
export const LINK_TO_IPBASENUMBER: string = "Link to IP Base Number";
export const SEPARATE_PUBLISHED: string = "Separate Published/Unpublished";


