import * as React from "react";
import SearchResultsTableSection from "../searchResultsTableSection/SearchResultsTableSection";
import TableHeaderSection from "../tableHeaderSection/TableHeaderSection";
import { createCopyOfTableContents } from "../../services/TableFunctions";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireField } from "../../types/IRepertoireField";
import { SEARCH_VIEW_WORKS, SEARCH_VIEW_AGREEMENTS, SEARCH_VIEW_IPS, UPDATE_ACTION_STATE_KEY, SEARCH_VIEW_PRODUCTS, SEARCH_VIEW_WORKFLOW, WORKFLOW_SELECTED_STATE_KEY, WORKFLOW_PAGE_VIEW, SEARCH_VIEW_ARTISTS, ADD_NEW_ACTION, SEARCH_VIEW_POOLS, ADD_TO_PACKAGE_ACTION, ADD_TO_PACKAGE_KEY } from "../../Consts";
import PaginationView from "../paginationView/PaginationView";
import { ComponentsHelper } from "../../../core/services/ComponentHelper";
import { selectSingleWorkflow, selectAllWorkflow, markSelectAll } from "../../../redux/reducers/RepertoireReducer";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { ILicenseInputItem } from "../../../redux/types/ILicenseInputItem";
import { DataSource } from "../../types/DataSource";

export interface ISearchResultsTableProps {
    searchResultsTableData: IRepertoireComponentDataItem;
    tableContents: any;
    componentInstance: string;
    getWorkDetails?: (dataSource: string, workID: number) => void;
    getAgreementDetails?: (dataSource: string, agreementID: number) => void;
    getIPDetails?: (ipBaseNumber: string) => void;
    getProductDetails?: (dataSource: string, productCoreID: number,isForWorkflowSession?: boolean, openNewTab?: boolean,id1?:string,id2?:string,type?:string,originOfRevision?:string, productionType?:string, musicDuration?:number[]) => void;
    getArtistDetails?: (performerID: number) => void;
    expandedResults?: number[];
    expandAll?: boolean;
    expandResult?: (result: number) => void;
    expandAllResults?: () => void;
    selectWorkflow?: typeof selectSingleWorkflow;
    selectAll?: boolean;
    selectAllWorkflow?: typeof selectAllWorkflow;
    isSelectedAll?: boolean;
    markSelectAll?: typeof markSelectAll;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    lookupValues?: ILookupDictionary;
    resultsPerPage?: number;
    currentPage?: number;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    repertoireSection: string;
    getUsagePoolDetails?: (poolId: number) => void;
    getDistributionDetails?: (distributionID: number, lookupValues: ILookupDictionary) => void;
    getPaymentRunDetails?: (paymentRunID: number, lookupValues: ILookupDictionary) => void;
    getUsageDetails?: (usageID: string) => void;
    sortSearchResults?: (name: string, results: any) => void;
    getAdjustmentDetails?: (adjustmentId: string) => void;
    getLicenseDetails?: (licenseID: number, lookupValues: ILookupDictionary, licenseRequestWorkParameterFields: ILicenseInputItem[]) => void;
    getDraftWorkDetails?: (dataSource: string, workId: number) => void;
    getDraftSetListDetails?: (dataSource: string, productCoreId: number) => void;
    getMemberStatementDetails?: (paymentID: string) => void;
    getLivePerformanceDetails?: (usageId: string) => void;
    getClaimDetails?: (claimID: number, lookupValues: ILookupDictionary) => void;
    addToPackages?:(userName:string,entityType:string,entityID:number)=>void;
    currentUser?:string;
}

interface ISearchResultsTableState {
    tableContents: any;
    activeSortHeaderSection: string;
    indexOfFirstResult: number;
    indexOfLastResult: number;
    resultsPerPage: number;
    currentPage: number;
}

export default class SearchResultsTable extends React.PureComponent<
    ISearchResultsTableProps,
    ISearchResultsTableState
> {
    constructor(props: ISearchResultsTableProps) {
        super(props);

        this.state = {
            tableContents: props.tableContents,
            activeSortHeaderSection: undefined,
            indexOfFirstResult: props.indexOfFirstResult ? props.indexOfFirstResult : 0,
            indexOfLastResult: props.indexOfLastResult ? props.indexOfLastResult : 10,
            resultsPerPage: props.resultsPerPage ? props.resultsPerPage : 10,
            currentPage: props.currentPage ? props.currentPage : 1,
        };
    }

    public ascending: boolean = false;

    componentDidUpdate = (prevProps: ISearchResultsTableProps) => {
        const { tableContents } = this.props;
        if (prevProps.tableContents !== tableContents) {
            this.setState({
                tableContents
            });
        }
    };

    sortTableByColumn = (section: string) => {
        const { componentInstance, sortSearchResults } = this.props;
        const { activeSortHeaderSection, tableContents } = this.state;
        const tableContentsCopy = createCopyOfTableContents(tableContents);

        if (activeSortHeaderSection !== section) {
            this.ascending = false;
        }

        tableContentsCopy.sort((a: any, b: any) => {
            // set to emtpy string if null
            let newA = a[section] || "";
            let newB = b[section] || "";

            newA = newA ? newA : "";
            newB = newB ? newB : "";

            if (!this.ascending) {
                return newA.toString().localeCompare(newB.toString(), undefined, { 'numeric': true });
            }
            return newB.toString().localeCompare(newA.toString(), undefined, { 'numeric': true });
        });

        this.setState({
            tableContents: tableContentsCopy,
            activeSortHeaderSection: section
        });

        this.ascending = !this.ascending;

        if (sortSearchResults) {
            sortSearchResults(componentInstance, tableContentsCopy);
        }
    };

    updateContentsTable(indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string): void {
        const { markSelectAll, componentInstance } = this.props;
        this.setState({ indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage });
        if (componentInstance === WORKFLOW_PAGE_VIEW) {
            markSelectAll(false);
        }
        this.props.updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
    }

    getHeaderString = (key: string) => {
        const { searchResultsTableData } = this.props;

        for (let x = 0; x < searchResultsTableData.fields.length; x++) {
            if (searchResultsTableData.fields[x].name === key) {
                return searchResultsTableData.fields[x].data;
            }
        }
    };

    renderExpandAll = () => {
        const { expandAll, expandAllResults, expandResult, } = this.props;

        return (expandResult ?
            <div key='expandAll' className='arrowIconExpandDiv'>
                <div className={expandAll ? "arrowIconExpandAllExpanded" : "arrowIconExpandAll"}>
                    <i className="icon ms-Icon ms-Icon--DoubleChevronLeft" aria-hidden="true" id="expandResultsButton" onClick={() => expandAllResults()} title={expandAll ? "Collapse Results" : "Expand Results"}></i>
                </div>
                {expandAll ? <div className='arrowIconExpandText'> Collapse Results</div> : <div className='arrowIconExpandText'> Expand Results</div>}
            </div> : <div></div>
        );
    }

    renderSelectAll = () => {
        const { selectAll, selectAllWorkflow, isSelectedAll, tableContents, markSelectAll } = this.props;
        const { indexOfFirstResult, indexOfLastResult } = this.state

        if (selectAll && !isSelectedAll) {
            if (tableContents) {
                if (tableContents.slice(indexOfFirstResult, indexOfLastResult).every(x => x.selected)) {
                    markSelectAll(true);
                }
            }
        }

        return (selectAll ?
            <div key='selectAll' className='arrowIconExpandDiv'>
                <div className={selectAll ? "arrowIconExpandAllExpanded selectAllCheckBox" : "arrowIconExpandAll selectAllCheckBox"}>
                    <input checked={isSelectedAll} type={"checkbox"} onChange={e => selectAllWorkflow(indexOfFirstResult, indexOfLastResult, e.target.checked)} />
                </div>
                {selectAll ? <div className='arrowIconExpandText selectAllCheckBoxText'> Select All</div> : <div className='arrowIconExpandText selectAllCheckBoxText'> Unselect All</div>}
            </div> : <div></div>
        );
    }

    renderHeaderSections = () => {
        const { searchResultsTableData, componentInstance } = this.props;
        const { activeSortHeaderSection, tableContents } = this.state;
        let removedItemAction: IRepertoireField = undefined;
        if (searchResultsTableData && searchResultsTableData.fields) {
            let filteredFields = searchResultsTableData.fields.filter(
                (field: IRepertoireField) =>
                    field.componentInstance === componentInstance
                    && field.name !== UPDATE_ACTION_STATE_KEY && field.name !== ADD_NEW_ACTION
                
            );
            filteredFields = filteredFields.filter(
                (field: IRepertoireField) =>
                    !(tableContents[0].dataSource === DataSource.Intray && field.name === ADD_TO_PACKAGE_ACTION)
            );

            const actionFieldIndex = filteredFields.findIndex(({ name }) => name === "update_action" || name === "addNew_action");
            if (actionFieldIndex > -1) {
                removedItemAction = filteredFields[actionFieldIndex];
                filteredFields.splice(actionFieldIndex, 1);
            }
            let orderedFields = ComponentsHelper.orderFieldsBySpecificField(filteredFields, "order");

            if (!orderedFields && orderedFields.length > 0) {
                orderedFields = filteredFields
            }
            if (removedItemAction) {
                orderedFields.push(removedItemAction);
            }

            return orderedFields.map((section: IRepertoireField, index: number) => (
                <td key={index} className="td">
                    <TableHeaderSection
                        section={section.data}
                        fieldName={section.name}
                        isSortingActive={section.name === activeSortHeaderSection}
                        ascending={this.ascending}
                        onClickHeaderSection={this.sortTableByColumn}
                        sortable
                    />
                </td>
            ));
        }
    };
    onSelectRow(tableContentItem: any, index: number) {
        const { selectWorkflow } = this.props;
        selectWorkflow(tableContentItem, index);
    }

    renderPaginationView(): JSX.Element {
        const { repertoireSection } = this.props;
        const { tableContents, resultsPerPage, currentPage } = this.state;
        return <PaginationView key='pagination'
            contentsLength={tableContents ? tableContents.length : 0}
            resultsPerPage={resultsPerPage}
            currentPage={currentPage}
            updateContentsTable={this.updateContentsTable.bind(this)}
            repertoireSection={repertoireSection}
        />
    }

    renderSearchResults = () => {
        const { tableContents, indexOfFirstResult, indexOfLastResult } = this.state;
        const { searchResultsTableData,
            componentInstance,
            getWorkDetails,
            getAgreementDetails,
            getIPDetails,
            getProductDetails,
            getArtistDetails,
            getUsagePoolDetails,
            lookupValues,
            getDistributionDetails,
            expandedResults,
            expandAll,
            expandResult,
            expandAllResults,
            getUsageDetails,
            getAdjustmentDetails,
            getPaymentRunDetails,
            getLicenseDetails,
            getDraftWorkDetails,
            getDraftSetListDetails,
            getMemberStatementDetails,
            getLivePerformanceDetails,
            getClaimDetails,
            addToPackages,
            currentUser
        } = this.props;
        let orderedFields: IRepertoireField[] = [];
        let removedItemAction: IRepertoireField = undefined;
        const currentResults = tableContents.slice(
            indexOfFirstResult,
            indexOfLastResult
        );

        let filteredFields = searchResultsTableData.fields.filter(
            (field: IRepertoireField) => {
                if (tableContents[0].dataSource === DataSource.Intray && field.name === "addtopackage_action") {
                    return false; 
                }
                return field.componentInstance === componentInstance
            }
        );

        const actionFieldIndex = filteredFields.findIndex(({ name }) => name === "update_action" || name === "addNew_action");
        if (actionFieldIndex > -1) {
            removedItemAction = filteredFields[actionFieldIndex];
            filteredFields.splice(actionFieldIndex, 1);
        }
        orderedFields = ComponentsHelper.orderFieldsBySpecificField(filteredFields, "order");


        if (!orderedFields && orderedFields.length > 0)
            orderedFields = filteredFields

        if (removedItemAction)
            orderedFields.push(removedItemAction);

        const keys: { name, title }[] = [];
        orderedFields.map(field => keys.push({ name: field.name, title: field.data }));

        const onSelectRow = (e, tableContentItem: any, index: number) => {
            this.onSelectRow(tableContentItem, index);
        }

        return currentResults.map(
            (tableContentItem: any, index: number) => {
                const sections: JSX.Element[] = [];

                keys.map((key: { name, title }, x: number) => {
                    sections.push(
                        (componentInstance === SEARCH_VIEW_WORKS || componentInstance === SEARCH_VIEW_PRODUCTS) ?//|| componentInstance === SEARCH_VIEW_WORKFLOW
                            <td key={`${index}.${x}`} className="td" title={
                                key.name in tableContentItem ? tableContentItem[key.name] && tableContentItem[key.name][0] : key.title} >
                                <SearchResultsTableSection
                                    objectKey={key.name}
                                    index={index}
                                    tableContentItem={tableContentItem}
                                    getWorkDetails={getWorkDetails}
                                    getProductDetails={getProductDetails}
                                    componentInstance={componentInstance}
                                    expanded={((expandedResults && expandedResults.includes(index))) ? true : false}
                                    expandResult={expandResult}
                                    addToPackages={addToPackages}
                                    currentUser={currentUser}
                                    renderExpandRow={((key.name === 'title' && componentInstance === 'works') || (key.name === 'title' && componentInstance === 'products')) ? true : false}
                                />
                            </td>
                            :
                            (componentInstance === SEARCH_VIEW_WORKFLOW && key.name === WORKFLOW_SELECTED_STATE_KEY) ?
                                <td key={`${index}.selectable`} className="td">
                                    <div className='tableCell'>
                                        <input type="checkbox" checked={tableContentItem[key.name]} onChange={e => onSelectRow(e, tableContentItem, index)} />
                                    </div>
                                </td>
                                :
                                <td key={`${index}.${x}`} className="td" title={
                                    key.name in tableContentItem ? tableContentItem[key.name] : key.title
                                } >
                                    <SearchResultsTableSection
                                        objectKey={key.name}
                                        tableContentItem={tableContentItem}
                                        getAgreementDetails={getAgreementDetails}
                                        getIPDetails={getIPDetails}
                                        getArtistDetails={getArtistDetails}
                                        getUsagePoolDetails={getUsagePoolDetails}
                                        lookupValues={lookupValues}
                                        getDistributionDetails={getDistributionDetails}
                                        getAdjustmentDetails={getAdjustmentDetails}
                                        componentInstance={componentInstance}
                                        expanded={((expandedResults && expandedResults.includes(index)) || expandAll) ? true : false}
                                        getWorkDetails={getWorkDetails}
                                        getProductDetails={getProductDetails}
                                        getUsageDetails={getUsageDetails}
                                        getPaymentRunDetails={getPaymentRunDetails}
                                        getLicenseDetails={getLicenseDetails}
                                        getDraftWorkDetails={getDraftWorkDetails}
                                        getDraftSetListDetails={getDraftSetListDetails}
                                        getMemberStatementDetails={getMemberStatementDetails}
                                        getLivePerformanceDetails={getLivePerformanceDetails}
                                        getClaimDetails={getClaimDetails}
                                        renderExpandRow={((key.name === 'title' && componentInstance === 'draftWorks') || (key.name === 'paymentRunCode' && componentInstance === 'memberStatements')) ? true : false}
                                        expandResult={expandResult}
                                        index={index}
                                        currentUser={currentUser}
                                        addToPackages={addToPackages}
                                    />
                                </td>
                    );
                });

                if (componentInstance === SEARCH_VIEW_WORKS || componentInstance === SEARCH_VIEW_AGREEMENTS || componentInstance == SEARCH_VIEW_IPS || componentInstance === SEARCH_VIEW_PRODUCTS || componentInstance === SEARCH_VIEW_WORKFLOW || SEARCH_VIEW_ARTISTS || componentInstance === SEARCH_VIEW_POOLS) {
                    return (
                        <tr key={index} className="tr">
                            {sections}
                        </tr>
                    );
                } else {
                    return (
                        <tr
                            key={index}
                            className="trSelectable"
                            // change row
                            onClick={() => null}
                        >
                            {sections}
                        </tr>
                    );
                }
            }
        );
    };

    render() {
        return (
            <div className="SearchResultsTable">
                {this.renderExpandAll()}
                {this.renderSelectAll()}
                {this.renderPaginationView()}
                <div key='tableContainer' className="tableContainer">
                    <table className="table">
                        <thead className="thead">
                            <tr className="tr">
                                {this.renderHeaderSections()}
                            </tr>
                        </thead>
                        <tbody className="tbody">{this.renderSearchResults()}</tbody>
                    </table>
                    {this.renderPaginationView()}
                </div>
            </div>
        );
    }
}
