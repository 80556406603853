import * as React from "react";
import { Button, Form, Input, InputNumber, InputRef, Row, Space, Table } from "antd";
import { Key, useRef, useState } from "react";
import { ColumnsType, ColumnType, FilterConfirmProps, FilterValue } from "antd/lib/table/interface";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import LicensesMaintenanceWorkGridToolBar from "./LicensesMaintenanceWorkGridToolBar";
import IRepertoireComponentDataItem from "../../../../../redux/types/IRepertoireComponentDataItem";
import { ITreeData } from "../../../../types/ITreeData";
import { showModal } from "../../../../../redux/reducers/ModalReducer";
import { ILicenseWorksRowType } from "../../../../types/usageTypes/ILicenseWorksRowType";
import { IRepertoireField } from "../../../../types/IRepertoireField";
import { ComponentsHelper } from "../../../../../core/services/ComponentHelper";
import { APPLICABLE_WORKS_KEY, EMPTY_STRING_VALUE, LICENSE_MAINTENANCE_WORK_GRID_SECTION_KEY, LICENSE_WORKS_COLUMNS_TO_FILTER, SEARCH_VIEW, SEARCH_VIEW_WORKS, SEARCH_VIEW_WORK_LICENSES, TO_ASK_ORIGIN, REPERTOIRE } from "../../../../Consts";
import { DataSource } from "../../../../types/DataSource";
import { addWorkToLicenseRequest, updateLicenseRequestWorkItem } from "../../../../../redux/reducers/RepertoireReducer";
import { IShoppingCartState } from "../../../../../redux/types/IShoppingCartState";
import { MessageBanner, MessageType } from "../../../../components/messageBanner/MessageBanner";
import { ILicenseInputItem } from "../../../../../redux/types/ILicenseInputItem";
import { ILicenseRequestItem } from "../../../../../redux/types/ILicenseRequestItem";
import * as XLSX from "xlsx";
import { ITabReduxItem } from "../../../../../redux/types/ITabReduxItem";
import { ILookupDictionary } from "../../../../../lookup/types/ILookupDictionary";
import IconTextButton from "../../../../components/iconTextButton/IconTextButton";
import { ILicenseRequestWorkItem } from "../../../../../redux/types/ILicenseRequestWorkItem";
import { render } from "react-dom";
import { Checkbox } from "react-bootstrap";

export interface ILicenseMaintenanceWorkGrid {
    searchResultsTableData: IRepertoireComponentDataItem;
    tableContents: any;
    componentInstance: string;
    selectAll?: boolean;
    isSelectedAll?: boolean;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    repertoireSection: string;
    sortSearchResults?: (name: string, results: any) => void;
    lookupSources?: ITreeData[];
    changeData: (value: any, fieldName: string) => void;
    search: (continuationToken?: string) => void;
    continuationToken?: string;
    showFieldsModal?: (componentFieldName: string) => void;
    saveResultData?: IRepertoireComponentDataItem;
    showModal: typeof showModal;
    addWorkToLicenseRequest: typeof addWorkToLicenseRequest;
    shoppingCart: IShoppingCartState;
    updateLicenseRequestWorkItem: typeof updateLicenseRequestWorkItem;
    licenseRequestWorkParameterFields?: ILicenseInputItem[];
    getLicenseRequestWorkFields: (commercialUseToken: number, geographicalToken: number, sectorToken: number, userTypeToken: number, useTypeToken: string, workID: number, licenseRequestWorkID: number) => void;
    licenseRequest?: ILicenseRequestItem;
    getWorksContributors: (workIds: number[]) => void;
    licenseWorksPricesImport: (licenseWorksPriceImport: any[]) => void;
    licenseCalculateWorkPrice: (shoppingCArt: IShoppingCartState, selectedLicenseRequestWorks: ILicenseRequestWorkItem[], licenseRequestWorkParameterFields: ILicenseInputItem[],
    licenseRequest: ILicenseRequestItem, lookupValues: ILookupDictionary, isModified: boolean) => void;
    activeTab: number;
    tabs: ITabReduxItem[];
    lookupValues: ILookupDictionary;
    updateLicenseRequestWorkItemToLocalState: (licenseRequestWorks: ILicenseRequestWorkItem[]) => void;
    isUsesGridModified: boolean,
    updateUsesModifiedFlag:() => void
    getWorkDetails: (dataSource: string, workID: number) => void;
    changesMade: boolean;
}

type DataIndex = keyof ILicenseWorksRowType;

export const LicenseMaintenanceWorkGrid: React.FC<ILicenseMaintenanceWorkGrid> = (props: ILicenseMaintenanceWorkGrid) => {

    const [selectedRows, setSelectedRows] = useState<ILicenseWorksRowType[]>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [hasChanges, setHasChanges] = useState<number[]>([]);
    const searchInput = useRef<InputRef>(null);
    const [file, setFile] = useState();
    const [fileData, setFileData] = useState();
    const [isWorksGridModified, setIsWorksGridModified] = useState([false]);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (pagination, filters: globalThis.Record<string, FilterValue>, sorter) => {
        if (filters.title) {
            props.changeData(filters.title.toString(), 'licenseRequestWorkID');
        }
    }

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<ILicenseWorksRowType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined translate={undefined} onAuxClick={undefined} onAuxClickCapture={undefined} />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button type="link" size="small" onClick={() => { close(); }}>
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} translate={undefined} onAuxClick={undefined} onAuxClickCapture={undefined} />
        ),
        onFilter: (value, record) => {
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase())
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#fcfa5d', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    React.useEffect(() => {
        const { getWorksContributors, tableContents } = props;
        const workIds = tableContents.map(x => x.workID);
        getWorksContributors(workIds);
        tableContents.forEach(tc => getDynamicColumns(true, tc));
    }, []);

    const onInputChange = (value,fieldName, index, licenseRequestWorkID) =>  {
        const { licenseRequest, updateLicenseRequestWorkItemToLocalState } = props;
        const licenseRequestWorkUpdate = licenseRequest.licenseRequestWorks;
        licenseRequestWorkUpdate.find(x => x.licenseRequestWorkID == licenseRequestWorkID)[fieldName] = value;
        updateLicenseRequestWorkItemToLocalState(licenseRequestWorkUpdate);
        if (!isWorksGridModified[0])
            isWorksGridModified[0] = true;
    };

    const onRemoveClick = (fieldName, index, licenseRequestWorkID) => {
        const { licenseRequest, updateLicenseRequestWorkItemToLocalState, updateLicenseRequestWorkItem } = props;
        const licenseRequestWorkUpdate = licenseRequest.licenseRequestWorks;
        licenseRequestWorkUpdate.splice(index, 1);
        updateLicenseRequestWorkItemToLocalState(licenseRequest.licenseRequestWorks);
        updateLicenseRequestWorkItem(licenseRequestWorkID, licenseRequest.licenseRequestWorks);
    }
    const onClickExternalIcon = (workID) => {
        const { getWorkDetails } = props;
        getWorkDetails(REPERTOIRE, workID);
    }

    const displayWorksGrid = () => {
        
        const { shoppingCart} = props;
        let result: boolean = true;
        if (ComponentsHelper.isEmpty(shoppingCart.commercialUse.value)
            || ComponentsHelper.isEmpty(shoppingCart.geographicalArea.value)
            || ComponentsHelper.isEmpty(shoppingCart.isUserExternal.value)
            || ComponentsHelper.isEmpty(shoppingCart.uses.value)
            || ComponentsHelper.isEmpty(shoppingCart.sector.value)
            || ComponentsHelper.isEmpty(shoppingCart.userTypes.value)) {
            result = false
        }
        return result;
    }

    const getDynamicColumns = (record, e) => {
        const { shoppingCart, getLicenseRequestWorkFields } = props;

        if (record === true) {
            getLicenseRequestWorkFields(shoppingCart.commercialUse.value, shoppingCart.geographicalArea.value, shoppingCart.sector.value, shoppingCart.userTypes.value, shoppingCart.uses.value, e.workID, e.licenseRequestWorkID);
        }

    }

    // generate columns for the table
    const getColumns = () => {
        const { searchResultsTableData, componentInstance, licenseRequest } = props;
        let removedItemAction: IRepertoireField = undefined;
        const fieldsToFilter: string[] = LICENSE_WORKS_COLUMNS_TO_FILTER;

        if (searchResultsTableData && searchResultsTableData.fields) {
            let filteredFields = searchResultsTableData.fields.filter(
                (field: IRepertoireField) =>
                    field.componentInstance === componentInstance
            );
            filteredFields = filteredFields.filter((field: IRepertoireField) => !fieldsToFilter.includes(field.name))
            const actionFieldIndex = filteredFields.findIndex(({ name }) => name === "update_action");
            if (actionFieldIndex > -1) {
                removedItemAction = filteredFields[actionFieldIndex];
                filteredFields.splice(actionFieldIndex, 1);
            }
            let orderedFields = ComponentsHelper.orderFieldsBySpecificField(filteredFields, "order");
            if (!orderedFields && orderedFields.length > 0) {
                orderedFields = filteredFields
            }
            if (removedItemAction) {
                orderedFields.push(removedItemAction);
            }
            const isEditable: boolean = ComponentsHelper.isEmpty(licenseRequest.licenseCode);

            let columns: ColumnsType<ILicenseWorksRowType> = orderedFields.filter(x => !x.hidden).map((field, index) => {
                switch (field.name) {
                    case 'title':
                        return {
                            title: field.data,
                            dataIndex: field.name,
                            width: '21.075em',
                            key: field.name, ...getColumnSearchProps(field.name as keyof ILicenseWorksRowType),
                            fixed: 'left',
                            render: (value, record, index) => value !== null ? <div>  <div style={{ float: "left", padding: "0.1em" }}>
                                <img
                                src="assets/external-black.svg"
                                title="Open work in a new tab"
                                alt="External icon"
                                className="externalIcon"
                                onClick={()=>onClickExternalIcon(record.workID)}
                                style={{ width: "0.875em", height: "0.875em", cursor: "pointer" }}
                                /></div>
                                <div style={{ width: "20.1em", paddingLeft: "0.1em", float: "right" }} key={`${value}_${index}`}>{value}</div>
                                </div> : null,
                            sorter: (a, b) => a.title.toString().localeCompare(b.title.toString()),
                            onFilter: (value, record) => {
                                return record.title.toString().includes(value.toString());

                            },
                        }
                    case 'percentageVE':
                        return {
                            title: '% VE',
                            dataIndex: field.name,
                            width: '7em',
                            key: `${field.name}`,

                            sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                            render: (value, record, index) => {
                                return isEditable ?
                                    <InputNumber key={`${value}_${index}`} type={'number'} style={{ textAlign: 'right' }}
                                        defaultValue={value}
                                        onChange={event => onInputChange(event,field.name, index, record.licenseRequestWorkID)} /> :
                                    <div key={`${value}_${index}`}>{value}</div>
                            }
                        }
                    case 'discountPercentage':
                        return {
                            title: '% Discount',
                            dataIndex: field.name,
                            width: '8em',
                            key: `${field.name}`,
                            sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                            render: (value, record, index) => {
                                return isEditable ?
                                    <InputNumber key={`${value}_${index}`} controls={false} style={{ textAlign: 'right' }} type={'number'} defaultValue={value} onChange={event => onInputChange(event,field.name, index, record.licenseRequestWorkID)} /> ://
                                    <div key={`${value}_${index}`}>{value}</div>
                            }
                        }
                    case 'percentageVA':
                        return {
                            title: '% VA',
                            dataIndex: field.name,
                            width: '7em',
                            key: `${field.name}`,
                            sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                            render: (value, record, index) => {
                                return isEditable ?
                                    <InputNumber key={`${value}_${index}`} controls={false} className={"percentageField"} style={{ textAlign: 'right' }} type={"number"} defaultValue={value} onChange={event =>onInputChange(event,field.name, index, record.licenseRequestWorkID)} /> :
                                    <div key={`${value}_${index}`}>{value}</div>
                            }
                        }
                    case 'percentageVAT':
                        return {
                            title: '% VAT',
                            dataIndex: field.name,
                            width: '7em',
                            key: `${field.name}`,
                            sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                            render: (value, record, index) => {
                                return isEditable ?
                                    <InputNumber key={`${value}_${index}`} controls={false} className={"percentageField"}
                                        style={{ textAlign: 'right' }} type={"number"} defaultValue={value} onChange={event => onInputChange(event,field.name, index, record.licenseRequestWorkID)} /> :
                                    <div key={`${value}_${index}`}>{value}</div>
                            }
                        }
                    case 'percentageVAA':
                        return {
                            title: '% VAA',
                            dataIndex: field.name,
                            width: '7em',
                            key: `${field.name}`,
                            sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                            render: (value, record, index) => {
                                return isEditable ?
                                    <InputNumber key={`${value}_${index}`} controls={false} style={{ textAlign: 'right' }} className={"percentageField"} type="number" defaultValue={record.percentageVAA} onChange={event =>onInputChange(event,field.name, index, record.licenseRequestWorkID)} /> :
                                    <div key={`${value}_${index}`}>{value}</div>
                            }
                        }
                    case 'price':
                        return {
                            title: 'Price',
                            dataIndex: field.name,
                            width: '7em',
                            key: `${field.name}`,
                            className: 'column-money',
                            sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                            render: (value, record, index) => {
                                return isEditable ?
                                    <InputNumber key={`${value}_${index}`} defaultValue={value} controls={false} style={{ textAlign: 'right' }}
                                        type="number" onChange={event =>onInputChange(event,field.name, index, record.licenseRequestWorkID)} /> :
                                    <div key={`${value}_${index}`}>{value}</div>
                            }
                        }

                    case 'priceNet':
                        return {
                            title: 'Net Price',
                            dataIndex: field.name,
                            width: '7em',
                            key: `${field.name}`,
                            className: 'column-money',
                            sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                            render: (value, record, index) => {
                                return isEditable ?
                                    <InputNumber key={`${value}_${index}`} defaultValue={value} controls={false} style={{ textAlign: 'right' }}
                                        type="number" onChange={event => onInputChange(event, field.name, index, record.licenseRequestWorkID)} /> :
                                    <div key={`${value}_${index}`}>{value}</div>
                            }
                        }
                    
                    case 'currencyAdministrationPrice':
                        return {
                            title: 'Currency Admin Price',
                            dataIndex: field.name,
                            width: '9em',
                            key: `${field.name}`,
                            className: 'column-money',
                            sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                            render: (value, record, index) => {
                                return isEditable ?
                                    <InputNumber key={`${value}_${index}`} defaultValue={value} controls={false} style={{ textAlign: 'right' }}
                                        type="number" onChange={event => onInputChange(event, field.name, index, record.licenseRequestWorkID)} /> :
                                    <div key={`${value}_${index}`}>{value}</div>
                            }
                        }
                    
                    case 'priceTotal':
                        return {
                            title: 'Total Price',
                            dataIndex: field.name,
                            width: '7em',
                            key: `${field.name}`,
                            className: 'column-money',
                            sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                            render: (value, record, index) => {
                                return isEditable ?
                                    <InputNumber key={`${value}_${index}`} defaultValue={value} controls={false} style={{ textAlign: 'right' }}
                                        type="number" onChange={event => onInputChange(event, field.name, index, record.licenseRequestWorkID)} /> :
                                    <div key={`${value}_${index}`}>{value}</div>
                            }
                        }
                        
                    case 'workNumber':
                        return {
                            title: field.data,
                            width: '10em',
                            dataIndex: field.name,
                            key: `${field.name}`,
                            sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                            render: (value) => value !== null ? <Space key={`${value}_${index}`}>{value}</Space> : null,

                        }
                    case 'remove_action':
                        return {
                            title: "Action",
                            dataIndex: field.name,
                            width: '6em',
                            key: `${field.name}`,
                            align: 'center',
                            render: (value, record, index) => {
                                return isEditable ?
                                    <div className="tableCell">
                                        <IconTextButton
                                            icon={"icon ms-Icon ms-Icon--Delete"}
                                            text={"Remove"}
                                            onClick={() => onRemoveClick(field.name, index, record.licenseRequestWorkID)}
                                            disabled={!isEditable}
                                        />
                                    </div> : <div>record</div>
                            }
                        }
                    default:
                        return {
                            title: field.data, dataIndex: field.name,
                            width: '6em',
                            key: `${field.name}`,
                            align: 'center',
                            sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                            render: (value) => value !== null ? <Space key={`${value}_${index}`}>{value}</Space> : null
                        }
                }
            })
            return columns;
        }
    }

    const { tableContents } = props;
    const columns = getColumns();

    const data: ILicenseWorksRowType[] = tableContents.map((row, index) => ({
        key: index,
        licenseRequestWorkID: row.licenseRequestWorkID,
        licenseRequestID: row.licenseRequestID,
        price: row.price,
        workID: row.workID,
        source: row.source,
        priceVAT: row.priceVAT,
        state: row.state,
        expirationDate: row.expirationDate,
        deletedDate: row.deletedDate,
        description: row.description,
        administrationPrice: row.administrationPrice,
        currencyCalculation: row.currencyCalculation,
        currencyActual: row.currencyActual,
        actualPrice: row.actualPrice,
        currencyExchangeRate: row.currencyExchangeRate,
        currencyAdministrationPrice: row.currencyAdministrationPrice,
        notes: row.notes,
        priceNet: row.priceNet,
        totalPrice: row.totalPrice,
        priceDate: row.priceDate,
        discountPercentage: row.discountPercentage,
        percentageVA: row.percentageVA,
        percentageVE: row.percentageVE,
        percentageVAA: row.percentageVAA,
        percentageVAT: row.percentageVAT,
        title: row.title.length > 1 ? row.title[0] : row.title,
        order: row.order,
        workNumber: row.workNumber.length > 1 ? row.workNumber[0] : row.workNumber,
        number: row.number,
        expandedTitles: row.title,
        expandedWorkNumber: row.workNumber,
        dePagina: row.dePagina,
        a: row.a,
        numeroDePagina: row.numeroDePagina,
        totalPaginas: row.totalPaginas,
        porcentaje: row.porcentaje,
        obraCompleta: row.obraCompleta,
        numeroCapitulos: row.numeroCapitulos,
        numeroDeArticulos: row.numeroDeArticulos,
        numeroDeGraficos: row.numeroDeGraficos,
        idioma: row.idioma,
        observaciones: row.observaciones,
        nombreDelCurso: row.nombreDelCurso,
        universidadInstitucion: row.universidadInstitucion,
        tituloNuevaObra: row.tituloNuevaObra,
        soporteDelEstampado: row.soporteDelEstampado,
        entregaDelContenido: row.entregaDelContenido,
        numeroDeReproducciones: row.numeroDeReproducciones,
        numeroDeAccesos: row.numeroDeAccesos,
        numeroDeUsuarios: row.numeroDeUsuarios,
        numeroDeDestinatarios: row.numeroDeDestinatarios,
        inicio: row.inicio,
        fin: row.fin,
        titulo: row.titulo,
        totalPaginas2: row.totalPaginas2,
        anoPublicacion: row.anoPublicacion,
        numeroVolumen: row.numeroVolumen,
        fechaDelPost: row.fechaDelPost,
        tituloDelPost: row.tituloDelPost,
        licenseRequestWorkParameter: row.licenseRequestWorkParameter
    }));

    // handle selecting rows
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: ILicenseWorksRowType[]) => {
            setSelectedRows(selectedRows);
        },
        getCheckboxProps: (record: ILicenseWorksRowType) => ({
            name: record.licenseRequestWorkID.toString()
        }),

    };

    const openModal = () =>{
        const { showModal,addWorkToLicenseRequest } = props;
        showModal(SEARCH_VIEW,SEARCH_VIEW_WORK_LICENSES,{isRepertoireSearch: DataSource.Repertoire,addWorkToLicenseRequest},true,"Works")
    }    

    async function onFileChange(event) {
        const { tableContents, licenseWorksPricesImport } = props;      

        const fileData = await event.target.files[0].arrayBuffer();
        const pricesFile = XLSX.read(fileData);

        const workSheet = pricesFile.Sheets['Works'];
        const jsonData:any[] = XLSX.utils.sheet_to_json(workSheet);

        if (jsonData) {           
            
            let updatedTable = tableContents.map(function (x) {
                var result = jsonData.filter(jd => jd["No Solicitud"] === x.licenseRequestWorkID);                
                if (result.length === 1) {
                    x.priceNet = result[0]["Precio Neto"]
                    x.price = result[0]["Precio Total"]
                    x.percentageVE = result[0]["Prc Sobre Obra Completa"]
                }
                return x
            })

            licenseWorksPricesImport(updatedTable);           
        }            
    }

    const exportPrice = () => {
        const { tableContents, shoppingCart } = props;

        const workContributors = shoppingCart.licenseWorkContributors;

        let mergedLists = tableContents.map(itm => ({
            ...workContributors.find((item) => (item.workID === itm.workID) && item),
            ...itm
        }));

        const workRows = mergedLists.map(x => ({
            "No Solicitud": x.licenseRequestWorkID,
            "Obra": x.title[0],
            "No Comanda": x.order,
            "Precio Neto": x.priceNet,
            "Gastos Adm.": shoppingCart.licenseRequests[0].administrationPrice,
            "Precio Total": x.price,
            "Observaciones": "",
            "Titulares DO": x.ipBaseNumber,
            "Prc Sobre Obra Completa": x.percentageVE,
            "WorkID": x.workID
        }));        

        const worksheet = XLSX.utils.json_to_sheet(workRows);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Works");
        XLSX.writeFile(workbook, shoppingCart.shoppingCartName.value + ".xlsx", { compression: true });
    }

    const calculatePrice = () => {
        const { shoppingCart, licenseCalculateWorkPrice, licenseRequestWorkParameterFields, licenseRequest, lookupValues, tableContents, changesMade } = props;
        const selectedLicenseRequestWorks = tableContents.filter(rw => selectedRows.map(sr => sr.licenseRequestWorkID).indexOf(rw.licenseRequestWorkID) > -1);
        const isModified = changesMade || isWorksGridModified[0];
        licenseCalculateWorkPrice(shoppingCart, selectedLicenseRequestWorks, licenseRequestWorkParameterFields, licenseRequest, lookupValues, isModified);
    }

    const renderHeader = () => {
        const { licenseRequest, tableContents } = props;
        const disabled: boolean = !ComponentsHelper.isEmpty(licenseRequest.licenseCode);
        const importDisabled: boolean = (tableContents.length === 0) ? true : false;
        return (
            <div className="">
                <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                    <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                </button>
                <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse " >
                    <LicensesMaintenanceWorkGridToolBar
                        data={data}
                        hasChanges={hasChanges}
                        showModal={openModal}
                        disabled={disabled}
                        exportPrice={exportPrice}
                        onFileChange={onFileChange}
                        calculatePrice={calculatePrice}
                        importDisabled={importDisabled}
                    />
                </div> 
            </div>
        )
    }

    const getExpandedColumns = (record: ILicenseWorksRowType) => {
        const expandedColumns: string[] = ['expandedTitles', 'expandedWorkNumber'];
        const { searchResultsTableData, componentInstance, licenseRequestWorkParameterFields, licenseRequest } = props;
        let filteredFields = searchResultsTableData.fields.filter(
            (field: IRepertoireField) =>
                field.componentInstance === componentInstance)
        filteredFields = filteredFields.filter((field: IRepertoireField) => expandedColumns.includes(field.name));

        const isEditable: boolean = ComponentsHelper.isEmpty(licenseRequest.licenseCode);
        let dynamicFilteredFields;
        
        dynamicFilteredFields = record.licenseRequestWorkParameter;
        
        if (dynamicFilteredFields && dynamicFilteredFields.length > 0) {
            dynamicFilteredFields.map(x => filteredFields.push({
                componentInstance: componentInstance,
                data: x.label,
                fieldType: x.fieldType,
                name: x.data,
                hidden: false,
                origin: x.origin
            }))
        }
            
        let columns: ColumnsType<ILicenseWorksRowType> = filteredFields.filter(f => f.origin == TO_ASK_ORIGIN || f.origin === undefined || f.origin === null).map((field, index) => {
            switch (field.name) {
                case 'expandedTitles':
                    return {
                        index: index,
                        title: field.data,
                        dataIndex: field.name,
                        width: '5em',
                        key: `${field.name}`,
                        render: (value, record, index) => record.expandedTitles.length > 1 ?
                            value.map(t => {
                                return <Space key={`${t}_${index}`}>{t}</Space>
                            }) : <Space key={`${value}_${index}_expanded`}>{value.toString()}</Space>
                    }
                case 'expandedWorkNumber':
                    return {
                        index: index,
                        title: field.data,
                        dataIndex: field.name,
                        width: '5em',
                        key: `${field.name}`,
                        render: (value, record, index) => record.expandedWorkNumber.length > 1 ?
                            value.map(n => {
                                return <div className="row" key={`${n}_${index}_list`}><Space>{n}</Space></div>
                            }) : <div className="row" key={`${value}_${index}`}><Space>{value.toString()}</Space></div>
                    }
                default:
                    return {
                        index: index,
                        title: field.data,
                        dataIndex: field.name,
                        width: '4em',
                        key: `${field.name}`,
                        OnCell: (record) => ({
                            record,
                            editable: true,
                            dataIndex: field.name,
                            title: field.data
                        }),
                        render: (value, record, index) => {
                            return isEditable ?
                                (field.name === 'obraCompleta' ?
                                    <div style={{ paddingLeft: '25px' }}>
                                        <input type='checkbox' key={`${value}_${index}`} defaultChecked={value === "1"} onChange={event => onInputChange(event.target.checked ? "1" : "0", field.name, index, record.licenseRequestWorkID)}
                                        style={{ width: '25px', height: '25px' }}/>
                                    </div> :
                                    <div><Input key={`${value}_${index}`} defaultValue={value} type={"text"} onChange={event => onInputChange(event.target.value, field.name, index, record.licenseRequestWorkID)} /></div>
                                ) :
                                <Space key={`${value}_${index}`}>{value}</Space>
                        }
                    }
            }
        });

        return columns;
    }

    const displayGrid = () => {
        if (displayWorksGrid()) {
            return (
                <div className="tableContainer">
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            defaultPageSize: props.resultsPerPage, position: ['bottomRight', 'topRight'], onChange(page, pageSize) {
                                let isLastPage = page * pageSize === data.length ? true : false;
                                const indexOfLastResult = page * props.resultsPerPage;
                                const indexOfFirstResult = indexOfLastResult - props.resultsPerPage;
                                props.updatePagination(indexOfFirstResult, indexOfLastResult, pageSize, page, LICENSE_MAINTENANCE_WORK_GRID_SECTION_KEY)
                                if (isLastPage && props.continuationToken) {
                                    props.search(props.continuationToken)
                                }
                            },
                            defaultCurrent: props.currentPage,
                            className: 'pageNumberDiv',
                            hideOnSinglePage: true
                        }}
                        expandable={{
                            expandedRowRender: (record, index) => <Table columns={getExpandedColumns(record)} dataSource={[record]} pagination={false} rowClassName="editable-row"></Table>,
                            rowExpandable: (record) => true,
                            onExpand: (record, e) => {
                                if (e.licenseRequestWorkParameter === undefined || e.licenseRequestWorkParameter.length === 0) {
                                    getDynamicColumns(record, e);
                                }                                
                            },
                            expandIcon: ({ expanded, onExpand, record }) => expanded ?
                                <>
                                    <div className="arrowIconDiv">
                                        <img
                                            src="assets/right-arrow.svg"
                                            title="Expand details"
                                            alt="Right Arrow icon"
                                            className={"arrowIconExpanded"}
                                            onClick={e => onExpand(record, e)}
                                        />
                                    </div>
                                </> :
                                <>
                                    <div className="arrowIconDiv">
                                        <img
                                            src="assets/right-arrow.svg"
                                            title="Expand details"
                                            alt="Right Arrow icon"
                                            className={"arrowIcon"}
                                            onClick={e => onExpand(record, e)}
                                        />
                                    </div>
                                </>

                        }}
                        rowClassName={() => 'editable-row'}
                        rowSelection={{ type: 'checkbox', ...rowSelection }}
                        size={'middle'}
                        onChange={handleChange}
                        title={renderHeader}
                        className="parent-table"
                        tableLayout={"fixed"}

                    />
                </div>
            )
        } else {
            return (
                <div className="tableContainer">
                    <MessageBanner messages={["All Uses option must be selected before add/see works"]} messageType={MessageType.Warning} resetMessageBanner={function (): void {
                        throw new Error("Function not implemented.");
                    }} />
                </div>
            )
        }
    }

    return (
        displayGrid()
    );
}
