import axios from "axios";
import { IComponentField } from "../types/IComponentField";
import { Dictionary } from "../types/Dictionary";
import { IRepertoireField } from "../../repertoire/types/IRepertoireField";
import IRepertoireComponentData from "../../redux/types/IRepertoireComponentData";
import {
    SEARCH_VIEW,
    SEARCH_RESULTS_TABLE,
    WORK_MAINTENANCE_ACCORDION_VIEW,
    WORK_MAINTENANCE_GRIDS_VIEW,
    DATA_GRID_TABLE,
    CONTRIBUTORS_DATA_VIEW,
    WORK_MAINTENANCE_GENERAL_DATA_VIEW,
    WORK_MAINTENANCE_TOOLBAR,
    WORKS_PAGE_VIEW,
    WORK_NOT_FOUND_VIEW,
    LOADING_VIEW,
    REPERTOIRE_PAGE_DATA_VIEW,
    TABS_VIEW,
    AGREEMENTS_PAGE_VIEW,
    AGREEMENT_MAINTENANCE_GENERAL_DATA_VIEW,
    AGREEEMENT_MAINTENANCE_GRIDS_VIEW,
    AGREEMENT_MAINTENANCE_ACCORDION_VIEW,
    IPS_PAGE_VIEW,
    IP_MAINTENANCE_GENERAL_DATA_VIEW,
    IP_MAINTENANCE_GRIDS_VIEW,
    IP_MAINTENANCE_ACCORDION_VIEW,
    YES_NO_PROMPT_VIEW,
    AGREEMENT_MAINTENANCE_TOOLBAR,
    IP_MAINTENANCE_TOOLBAR,
    SAVE_RESULT,
    PRODUCTS_PAGE_VIEW,
    PRODUCT_MAINTENANCE_GENERAL_DATA_VIEW,
    PRODUCT_MAINTENANCE_GRIDS_VIEW,
    PRODUCT_MAINTENANCE_ACCORDION_VIEW,
    PRODUCT_MAINTENANCE_TOOLBAR,
    WORKS_PAGE,
    IPS_PAGE,
    MY_PACKAGES_PAGE,
    MY_PACKAGES_VIEW,
    PRODUCTS_PAGE,
    WORK_SUBMISSION_DETAILS_VIEW,
    IP_MAINTENANCE_TIME_RANGE_SELECTOR,
    EDITABLE_FIELDS_DATA_VIEW,
    WORKFLOW_FIELDS,
    EDITABLE_GRID_FIELDS_DATA_VIEW,
    WORKFLOW_PAGE_VIEW,
    WORKFLOW_PAGE_TOOLBAR_FIELDS,
    ASSING_WORKFLOW_VIEW,
    UPDATE_WORKFLOW_FIELDS_VIEW,
    AVWORK_DETAILS_ACCORDION_VIEW,
    AV_SUBMISSION_DETAILS_ACCORDIAN_VIEW,
    FINDUSAGE_PAGE_VIEW,
    USAGESEARCH_VIEW,
    USAGERESULTTABLE_VIEW,
    USAGE_MAINTENANCE_GENERAL_DATA_VIEW,
    USAGE_MAINTENANCE_GRIDS_VIEW,
    USAGE_SAVE_RESULT,
    USAGE_MAINTENANCE_TOOLBAR,
    ARTISTS_PAGE_VIEW,
    ARTIST_MAINTENANCE_GENERAL_DATA_VIEW,
    ARTIST_MAINTENANCE_GRIDS_VIEW,
    ARTIST_MAINTENANCE_ACCORDION_VIEW,
    ARTIST_MAINTENANCE_TOOLBAR,
    SEARCH_VIEW_POOLS,
    USAGE_POOLS_PAGE_VIEW,
    USAGE_POOL_MAINTENANCE_GENERAL_DATA_VIEW,
    USAGE_POOL_MAINTENANCE_TOOLBAR,
    USAGE_POOL_MAINTENANCE_GRIDS_VIEW,
    DISTRIBUTIONS_PAGE_VIEW,
    DISTRIBUTIONS_MAINTENANCE_GENERAL_DATA_VIEW,
    DISTRIBUTION_MAINTENANCE_TOOLBAR,
    DISTRIBUTION_MAINTENANCE_GRIDS_VIEW,
    USAGE_POOL_MAINTENANCE_ALLOCATION_SETTINGS_GRID,
    WORK_METADATA_ACCORDION_VIEW,
    REPRESENTATION_GENERAL_DATA_VIEW,
    IP_REPRESENTATION_GENERAL_DATA_VIEW,
    CLAIMS_MAINTENANCE_GENERAL_DATA_VIEW,
    CLAIMS_MAINTENANCE_GRIDS_VIEW,
    CLAIMS_MAINTENANCE_TOOLBAR,
    CLAIMS_PAGE_VIEW,
    WORK_GROUP_GENERAL_DATA_VIEW,
    ADJUSTMENTS_PAGE_VIEW,
    ADJUSTMENT_MAINTENANCE_GENERAL_DATA_VIEW,
    ADJUSTMENT_MAINTENANCE_TOOLBAR,
    IP_TRANSFER_VIEW,
    ADJUSTMENT_MAINTENANCE_GRIDS_VIEW,
    ALLOCATION_ADJUSTMENT_DETAILS_VIEW,
    PAYMENTRUN_PAGE_VIEW,
    PAYMENTRUN_MAINTENANCE_TOOLBAR,
    PAYMENTRUN_MAINTENANCE_GENERAL_DATA_VIEW,
    PAYMENTRUN_MAINTENANCE_GRIDS_VIEW,
    LICENSE_MAINTENANCE_TOOLBAR,
    LICENSE_MAINTENANCE_GENERAL_DATA_VIEW,
    LICENSE_MAINTENANCE_GRIDS_VIEW,
    LICENSES_PAGE_VIEW,
    FOOTER,
    WEIGHT_SETTINGS_POOL_MAINTENANCE,
    USAGE_POOL_MAINTENANCE_WEIGHT_SETTINGS_GRID,
    USAGE_POOL_MAINTENANCE_ESTIMATED_VALUE_PER_POINT_SETTINGS_GRID,
    SCHEDULED_JOB_MODAL_VIEW,
    MY_PACKAGES_MAINTENANCE_PAGE,
    MY_PACKAGES_GENERAL_DATA_VIEW,
    MY_PACKAGES_GRID_VIEW
} from "../../repertoire/Consts";
import { IMenuItem } from "../types/IMenuItem";
import {
    JOB_SEARCH_RESULTS_GRID,
    JOB_SEARCH_RESULTS_VIEW,
    JOB_SEARCH_VIEW,
    MANUAL_JOB_VIEW,
    TIME_RANGE_SELECTOR
} from "../../home/Consts";
import IHomeComponentData from "../../home/types/IHomeComponentData";
import { IAccountComponentData } from "../../accounts/types/IAccountComponentData";
import { LOGIN_PAGE, MEMBERS_PORTAL } from "../../accounts/Consts";
import IDataIngestionComponentData from "../../dataingestion/types/IDataIngestionComponentData";
import dataIngestionReducer from "../../redux/reducers/DataIngestionReducer";
import { CONTAINER_DETAILS_WINDOW_KEY, DATA_INGESTION_TOOLBAR } from "../../dataingestion/Consts";
import IMembersPortalComponentData from "../../redux/types/IMembersPortalComponentData";
import { IMembersPortalField } from "../../membersportal/types/IMembersPortalField";
import { ALL_REGISTERED_WORKS_MAINTENANCE_GENERAL_DATA_VIEW, ALL_REGISTERED_WORKS_MAINTENANCE_GRIDS_VIEW, ALL_REGISTERED_WORKS_MAINTENANCE_TOOLBAR, ALL_REGISTERED_WORKS_PAGE_VIEW, DRAFT_WORKS_MAINTENANCE_GENERAL_DATA_VIEW, DRAFT_WORKS_MAINTENANCE_GRIDS_VIEW, DRAFT_WORKS_MAINTENANCE_TOOLBAR, DRAFT_WORKS_PAGE_VIEW, MEMBERSPORTAL_PAGE_DATA_VIEW, MEMBERS_PORTAL_SIDE_MENU, MEMBER_PAYMENT_STATEMENT_GENERAL_DATA_VIEW, MEMBER_STATEMENTS_DETAILS_PAGE_VIEW, MEMBER_STATEMENT_PAGE_VIEW, MEMBER_STATEMENT_DETAILS_GRIDS_VIEW, REGISTERED_WORKS_MAINTENANCE_GENERAL_DATA_VIEW, REGISTERED_WORKS_MAINTENANCE_GRIDS_VIEW, REGISTERED_WORKS_MAINTENANCE_TOOLBAR, REGISTERED_WORKS_PAGE_VIEW, REQUEST_ACCESS_PAGE_VIEW, MEMBER_STATEMENT_WORK_DETAILS_GRIDS_VIEW, MEMBER_STATEMENT_USAGE_DETAILS_GRIDS_VIEW, MEMBER_STATEMENT_USAGE_DETAILS_PAGE_VIEW, DRAFT_SET_LISTS_PAGE, DRAFT_SET_LISTS_PAGE_VIEW, REGISTERED_SET_LISTS_PAGE, REGISTERED_SET_LISTS_PAGE_VIEW, DRAFT_SET_LISTS_MAINTENANCE_GENERAL_DATA_VIEW, DRAFT_SET_LISTS_MAINTENANCE_TOOLBAR, DRAFT_SET_LISTS_MAINTENANCE_GRIDS_VIEW, LIVE_PERFORMANCE_SUBMISSION_PAGE_VIEW, SUBMIT_NEW_LIVE_PERFORMANCE_PAGE_VIEW, LIVE_PERFORMANCE_MAINTENANCE_GENERAL_DATA_VIEW, LIVE_PERFORMANCE_MAINTENANCE_TOOLBAR, LIVE_PERFORMANCE_MAINTENANCE_GRIDS_VIEW, UNPAID_CLAIMS_MEMBERS_PAGE, CLAIMS_HISTORY_PAGE_VIEW, UNPAID_CLAIMS_REGISTERED_WORKS_SEARCH, DRAFT_AGREEMENTS_PAGE_VIEW, REGISTERED_AGREEMENTS_PAGE_VIEW,DRAFT_AGREEMENTS_MAINTENANCE_GENERAL_DATA_VIEW, DRAFT_AGREEMENTS_MAINTENANCE_TOOLBAR, DRAFT_AGREEMENTS_MAINTENANCE_GRIDS_VIEW } from "../../membersportal/Consts";
import { Dispatch } from "redux";
import { getMenuItemsSuccess } from "../../redux/reducers/HomeReducer";
import IShortCutKeys from "../../redux/types/IShortCutKeys";

export class ComponentFields {
    public static get(componentName: string) {
        return new Promise<Dictionary<IComponentField>>((resolve, reject) => {
            axios
                .get("Component/Fields", {
                    params: {
                        componentName: componentName
                    }
                })
                .then(res => {
                    let fields: Dictionary<IComponentField> = {};
                    res.data.map((field: IComponentField) => {
                        fields[field.name] = field;
                    });
                    resolve(fields);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    public static getComponentDataItem(componentName: string) {
        let fields: IComponentField;
        return new Promise<any>((resolve, reject) => {
            axios
                .get("Component/Fields", {
                    params: {
                        componentName: componentName
                    }
                })
                .then(res => {
                    fields = res.data;
                    resolve({ [componentName]: { fields } });
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    public static getAppVersionNumber() {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("Component/AppVersionNumber")
                .then(res => {
                    let versionNumber = res.data;
                    resolve(versionNumber);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    public static async getPortalType() {
        const response = await axios.get("Component/PortalType")
        return response.data;
    }

    public static getMembersPortalComponentData(): Promise<IMembersPortalComponentData> {
        return new Promise<IMembersPortalComponentData>((resolve, reject) => {
            axios
                .get("Component/Module", { params: { moduleName: "MembersPortal" } })
                .then(res => {
                    const result: IMembersPortalComponentData = {
                        SearchView: {
                            fields: res.data[SEARCH_VIEW] as IMembersPortalField[]
                        },
                        SearchResultsTable: {
                            fields: res.data[SEARCH_RESULTS_TABLE] as IMembersPortalField[]
                        },
                        DataGridTable: {
                            fields: res.data[DATA_GRID_TABLE] as IMembersPortalField[]
                        },
                        SaveResult: {
                            fields: res.data[SAVE_RESULT] as IMembersPortalField[]
                        },
                        LoadingView: {
                            fields: res.data[LOADING_VIEW] as IMembersPortalField[]
                        },
                        YesNoPromptView: {
                            fields: res.data[YES_NO_PROMPT_VIEW] as IMembersPortalField[]
                        },
                        TabsView: {
                            fields: res.data[TABS_VIEW] as IMembersPortalField[]
                        },
                        DraftSetListsPage: {
                            fields: res.data[DRAFT_SET_LISTS_PAGE_VIEW] as IMembersPortalField[]
                        },
                        UnpaidClaimsMembersPage: {
                            fields: res.data[UNPAID_CLAIMS_MEMBERS_PAGE] as IMembersPortalField[]
                        },
                        UnpaidClaimsRegisteredWorksSearch: {
                            fields: res.data[UNPAID_CLAIMS_REGISTERED_WORKS_SEARCH] as IMembersPortalField[]
                        },
                        DraftWorksPage: {
                            fields: res.data[DRAFT_WORKS_PAGE_VIEW] as IMembersPortalField[]
                        },
                        DraftAgreementsPage: {
                            fields: res.data[DRAFT_AGREEMENTS_PAGE_VIEW] as IMembersPortalField[]
                        },
                        // DraftAgreementMaintenanceGeneralDataView: {
                        //     fields: res.data[DRAFT_AGREEMENTS_PAGE_VIEW] as IMembersPortalField[]
                        // },
                        DraftAgreementsMaintenanceGeneralDataView: {
                            fields: res.data[DRAFT_AGREEMENTS_MAINTENANCE_GENERAL_DATA_VIEW] as IMembersPortalField[]
                        },
                        DraftAgreementsMaintenanceToolbar: {
                            fields: res.data[DRAFT_AGREEMENTS_MAINTENANCE_TOOLBAR] as IMembersPortalField[]
                        },
                        DraftAgreementsMaintenanceGridsViewData: {
                            fields: res.data[DRAFT_AGREEMENTS_MAINTENANCE_GRIDS_VIEW] as IMembersPortalField[]
                        },
                        RegisteredAgreementsPage: {
                            fields: res.data[REGISTERED_AGREEMENTS_PAGE_VIEW] as IMembersPortalField[]
                        },
                        RegisteredAgreementMaintenanceGeneralDataView: {
                            fields: res.data[REGISTERED_AGREEMENTS_PAGE_VIEW] as IMembersPortalField[]
                        },
                        DraftWorkMaintenanceGeneralDataView: {
                            fields: res.data[DRAFT_WORKS_MAINTENANCE_GENERAL_DATA_VIEW] as IMembersPortalField[]
                        },
                        DraftWorkMaintenanceToolbar: {
                            fields: res.data[DRAFT_WORKS_MAINTENANCE_TOOLBAR] as IMembersPortalField[]
                        },
                        DraftWorkMaintenanceGridsViewData: {
                            fields: res.data[DRAFT_WORKS_MAINTENANCE_GRIDS_VIEW] as IMembersPortalField[]
                        },
                        MembersPortalSideMenuDataView: {
                            fields: res.data[MEMBERS_PORTAL_SIDE_MENU] as IMembersPortalField[]
                        },
                        MembersPortalPageDataView: {
                            fields: res.data[MEMBERSPORTAL_PAGE_DATA_VIEW] as IMembersPortalField[]
                        },
                        RegisteredWorksPage: {
                            fields: res.data[REGISTERED_WORKS_PAGE_VIEW] as IMembersPortalField[]
                        },
                        RegisteredSetListsPage: {
                            fields: res.data[REGISTERED_SET_LISTS_PAGE_VIEW] as IMembersPortalField[]
                        },
                        RegisteredWorkMaintenanceGeneralDataView: {
                            fields: res.data[REGISTERED_WORKS_MAINTENANCE_GENERAL_DATA_VIEW] as IMembersPortalField[]
                        },
                        RegisteredWorkMaintenanceToolbar: {
                            fields: res.data[REGISTERED_WORKS_MAINTENANCE_TOOLBAR] as IMembersPortalField[]
                        },
                        RegisteredWorkMaintenanceGridsViewData: {
                            fields: res.data[REGISTERED_WORKS_MAINTENANCE_GRIDS_VIEW] as IMembersPortalField[]
                        },
                        AllRegisteredWorksPage: {
                            fields: res.data[ALL_REGISTERED_WORKS_PAGE_VIEW] as IMembersPortalField[]
                        },
                        AllRegisteredWorkMaintenanceGeneralDataView: {
                            fields: res.data[ALL_REGISTERED_WORKS_MAINTENANCE_GENERAL_DATA_VIEW] as IMembersPortalField[]
                        },
                        AllRegisteredWorkMaintenanceToolbar: {
                            fields: res.data[ALL_REGISTERED_WORKS_MAINTENANCE_TOOLBAR] as IMembersPortalField[]
                        },
                        AllRegisteredWorkMaintenanceGridsViewData: {
                            fields: res.data[ALL_REGISTERED_WORKS_MAINTENANCE_GRIDS_VIEW] as IMembersPortalField[]
                        },
                        FooterView: {
                            fields: res.data[FOOTER] as IMembersPortalField[]
                        },
                        ViewAsPage: {
                            fields: res.data[MEMBERSPORTAL_PAGE_DATA_VIEW] as IMembersPortalField[]
                        },
                        RequestAccessPage: {
                            fields: res.data[REQUEST_ACCESS_PAGE_VIEW] as IMembersPortalField[]
                        },
                        MemberStatementsPage: {
                            fields: res.data[MEMBER_STATEMENT_PAGE_VIEW] as IMembersPortalField[]
                        },
                        MemberPaymentStatementGeneralDataView: {
                            fields: res.data[MEMBER_PAYMENT_STATEMENT_GENERAL_DATA_VIEW] as IMembersPortalField[]
                        },
                        MemberStatementDetailsPage: {
                            fields: res.data[MEMBER_STATEMENTS_DETAILS_PAGE_VIEW] as IMembersPortalField[]
                        },
                        MemberStatementDetailsGridsView: {
                            fields: res.data[MEMBER_STATEMENT_DETAILS_GRIDS_VIEW] as IMembersPortalField[]
                        },
                        MemberStatementWorkDetailsGridsView: {
                            fields: res.data[MEMBER_STATEMENT_WORK_DETAILS_GRIDS_VIEW] as IMembersPortalField[]
                        },
                        MemberStatementUsageDetailsPage: {
                            fields: res.data[MEMBER_STATEMENT_USAGE_DETAILS_PAGE_VIEW] as IMembersPortalField[]
                        },
                        MemberStatementUsageDetailsGridsView: {
                            fields: res.data[MEMBER_STATEMENT_USAGE_DETAILS_GRIDS_VIEW] as IMembersPortalField[]
                        },
                        DraftSetListsMaintenanceGeneralDataView: {
                            fields: res.data[DRAFT_SET_LISTS_MAINTENANCE_GENERAL_DATA_VIEW] as IMembersPortalField[]
                        },
                        DraftSetListsMaintenanceToolbar: {
                            fields: res.data[DRAFT_SET_LISTS_MAINTENANCE_TOOLBAR] as IMembersPortalField[]
                        },
                        DraftSetListsMaintenanceGridsViewData: {
                            fields: res.data[DRAFT_SET_LISTS_MAINTENANCE_GRIDS_VIEW] as IMembersPortalField[]
                        },
                        LivePerformanceSubmissionPage:{
                            fields: res.data[LIVE_PERFORMANCE_SUBMISSION_PAGE_VIEW] as IMembersPortalField[]
                        }, 
                        SubmitNewLivePerformancePage:{
                            fields: res.data[SUBMIT_NEW_LIVE_PERFORMANCE_PAGE_VIEW] as IMembersPortalField[]
                        }, 
                        LivePerformanceMaintenanceGeneralDataView: {
                            fields: res.data[LIVE_PERFORMANCE_MAINTENANCE_GENERAL_DATA_VIEW] as IMembersPortalField[]
                        },
                        LivePerformanceMaintenanceToolbar: {
                            fields: res.data[LIVE_PERFORMANCE_MAINTENANCE_TOOLBAR] as IMembersPortalField[]
                        },
                        LivePerformanceMaintenanceGridsViewData: {
                            fields: res.data[LIVE_PERFORMANCE_MAINTENANCE_GRIDS_VIEW] as IMembersPortalField[]
                        },
                        ClaimsHistoryPage: {
                            fields: res.data[CLAIMS_HISTORY_PAGE_VIEW] as IMembersPortalField[]
                        },
                    }
                    resolve(result);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    public static getRepertoireComponentData(): Promise<IRepertoireComponentData> {
        return new Promise<IRepertoireComponentData>((resolve, reject) => {
            axios
                .get("Component/Module", { params: { moduleName: "Repertoire" } })
                .then(res => {
                    const result: IRepertoireComponentData = {
                        ScheduledJobModalViewData: {
                            fields: res.data[SCHEDULED_JOB_MODAL_VIEW] as IRepertoireField[]
                        },
                        SearchView: {
                            fields: res.data[SEARCH_VIEW] as IRepertoireField[]
                        },
                        SearchResultsTable: {
                            fields: res.data[SEARCH_RESULTS_TABLE] as IRepertoireField[]
                        },
                        WorkMaintenanceGridsView: {
                            fields: res.data[WORK_MAINTENANCE_GRIDS_VIEW] as IRepertoireField[]
                        },
                        WorkMaintenanceAccordionView: {
                            fields: res.data[WORK_MAINTENANCE_ACCORDION_VIEW] as IRepertoireField[]
                        },
                        DataGridTable: {
                            fields: res.data[DATA_GRID_TABLE] as IRepertoireField[]
                        },
                        ContributorsDataView: {
                            fields: res.data[CONTRIBUTORS_DATA_VIEW] as IRepertoireField[]
                        },
                        WorkMetadataAccordionView: {
                            fields: res.data[WORK_METADATA_ACCORDION_VIEW] as IRepertoireField[]
                        },
                        WorksPage: {
                            fields: res.data[WORKS_PAGE_VIEW] as IRepertoireField[]
                        },
                        WorkMaintenanceGeneralDataView: {
                            fields: res.data[WORK_MAINTENANCE_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        RepertoirePageDataView: {
                            fields: res.data[REPERTOIRE_PAGE_DATA_VIEW] as IRepertoireField[]
                        },
                        WorkMaintenanceToolbar: {
                            fields: res.data[WORK_MAINTENANCE_TOOLBAR] as IRepertoireField[]
                        },
                        SaveResult: {
                            fields: res.data[SAVE_RESULT] as IRepertoireField[]
                        },
                        WorkNotFoundView: {
                            fields: res.data[WORK_NOT_FOUND_VIEW] as IRepertoireField[]
                        },
                        LoadingView: {
                            fields: res.data[LOADING_VIEW] as IRepertoireField[]
                        },
                        YesNoPromptView: {
                            fields: res.data[YES_NO_PROMPT_VIEW] as IRepertoireField[]
                        },
                        TabsView: {
                            fields: res.data[TABS_VIEW] as IRepertoireField[]
                        },
                        AgreementsPage: {
                            fields: res.data[AGREEMENTS_PAGE_VIEW] as IRepertoireField[]
                        },
                        AgreementMaintenanceGeneralDataView: {
                            fields: res.data[AGREEMENT_MAINTENANCE_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        AgreementMaintenanceGridsView: {
                            fields: res.data[AGREEEMENT_MAINTENANCE_GRIDS_VIEW] as IRepertoireField[]
                        },
                        AgreementMaintenanceAccordionView: {
                            fields: res.data[AGREEMENT_MAINTENANCE_ACCORDION_VIEW] as IRepertoireField[]
                        },
                        AgreementMaintenanceToolbar: {
                            fields: res.data[AGREEMENT_MAINTENANCE_TOOLBAR] as IRepertoireField[]
                        },
                        IPsPage: {
                            fields: res.data[IPS_PAGE_VIEW] as IRepertoireField[]
                        },
                        MyPackagesPage: {
                            fields: res.data[MY_PACKAGES_VIEW] as IRepertoireField[]
                        },
                        MyPackagesMaintenancePage: {
                            fields: res.data[MY_PACKAGES_MAINTENANCE_PAGE] as IRepertoireField[]
                        },
                        MyPackagesGeneralDataView:{
                            fields:res.data[MY_PACKAGES_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        MyPackagesGridView:{
                            fields:res.data[MY_PACKAGES_GRID_VIEW] as IRepertoireField[]
                        },
                        IPMaintenanceGeneralDataView: {
                            fields: res.data[IP_MAINTENANCE_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        IPRepresentationGeneralDataView: {
                            fields: res.data[IP_REPRESENTATION_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        WorkGroupGeneralDataView: {
                            fields: res.data[WORK_GROUP_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        IPMaintenanceGridsView: {
                            fields: res.data[IP_MAINTENANCE_GRIDS_VIEW] as IRepertoireField[]
                        },
                        IPMaintenanceAccordionView: {
                            fields: res.data[IP_MAINTENANCE_ACCORDION_VIEW] as IRepertoireField[]
                        },
                        IPMaintenanceToolbar: {
                            fields: res.data[IP_MAINTENANCE_TOOLBAR] as IRepertoireField[]
                        },
                        WeightSettingsPoolMaintenance: {
                            fields: res.data[WEIGHT_SETTINGS_POOL_MAINTENANCE] as IRepertoireField[]
                        },
                        ArtistsPage: {
                            fields: res.data[ARTISTS_PAGE_VIEW] as IRepertoireField[]
                        },
                        ArtistMaintenanceGeneralDataView: {
                            fields: res.data[ARTIST_MAINTENANCE_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        ArtistMaintenanceGridsView: {
                            fields: res.data[ARTIST_MAINTENANCE_GRIDS_VIEW] as IRepertoireField[]
                        },
                        ArtistMaintenanceAccordionView: {
                            fields: res.data[ARTIST_MAINTENANCE_ACCORDION_VIEW] as IRepertoireField[]
                        },
                        ArtistMaintenanceToolbar: {
                            fields: res.data[ARTIST_MAINTENANCE_TOOLBAR] as IRepertoireField[]
                        },
                        ProductsPage: {
                            fields: res.data[PRODUCTS_PAGE_VIEW] as IRepertoireField[]
                        },
                        ProductMaintenanceGeneralDataView: {
                            fields: res.data[PRODUCT_MAINTENANCE_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        ProductMaintenanceGridsView: {
                            fields: res.data[PRODUCT_MAINTENANCE_GRIDS_VIEW]
                        },
                        ProductMaintenanceAccordionView: {
                            fields: res.data[PRODUCT_MAINTENANCE_ACCORDION_VIEW]
                        },
                        ProductMaintenanceToolbar: {
                            fields: res.data[PRODUCT_MAINTENANCE_TOOLBAR]
                        },
                        AVWorkDetailsAccordianViewData: {
                            fields: res.data[AVWORK_DETAILS_ACCORDION_VIEW]
                        },
                        AVSubmissionViewData: {
                            fields: res.data[AV_SUBMISSION_DETAILS_ACCORDIAN_VIEW]
                        },
                        WorkSubmissionDetails: {
                            fields: res.data[WORK_SUBMISSION_DETAILS_VIEW]
                        },
                        IpMaintenanceTimeRangeSelector: {
                            fields: res.data[IP_MAINTENANCE_TIME_RANGE_SELECTOR] as IComponentField[]
                        },
                        EditableFieldsDataView: {
                            fields: res.data[EDITABLE_FIELDS_DATA_VIEW] as IRepertoireField[]
                        },
                        WorkflowFieldsData: {
                            fields: res.data[WORKFLOW_FIELDS] as IRepertoireField[]
                        },
                        EditableGridFieldsView: {
                            fields: res.data[EDITABLE_GRID_FIELDS_DATA_VIEW] as IRepertoireField[]
                        },
                        WorkflowPage: {
                            fields: res.data[WORKFLOW_PAGE_VIEW] as IRepertoireField[]
                        },
                        WorkflowPageToolbar: {
                            fields: res.data[WORKFLOW_PAGE_TOOLBAR_FIELDS] as IRepertoireField[]
                        },
                        AssingWorkflowView: {
                            fields: res.data[ASSING_WORKFLOW_VIEW] as IRepertoireField[]
                        },
                        UpdateWorkflowViewData: {
                            fields: res.data[UPDATE_WORKFLOW_FIELDS_VIEW] as IRepertoireField[]
                        },
                        FindUsagePage: {
                            fields: res.data[FINDUSAGE_PAGE_VIEW] as IRepertoireField[]
                        },
                        UsageMaintenanceGeneralDataView: {
                            fields: res.data[USAGE_MAINTENANCE_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        UsageMaintenanceGridsView: {
                            fields: res.data[USAGE_MAINTENANCE_GRIDS_VIEW] as IRepertoireField[]
                        },
                        UsageSearchView: {
                            fields: res.data[USAGESEARCH_VIEW] as IRepertoireField[]
                        },
                        ResultsTable: {
                            fields: res.data[USAGERESULTTABLE_VIEW] as IRepertoireField[]
                        },
                        UsageSaveResult: {
                            fields: res.data[USAGE_SAVE_RESULT] as IRepertoireField[]
                        },
                        UsageMaintenanceToolbar: {
                            fields: res.data[USAGE_MAINTENANCE_TOOLBAR]
                        },
                        UsagePoolsPage: {
                            fields: res.data[USAGE_POOLS_PAGE_VIEW] as IRepertoireField[]
                        },
                        UsagePoolMaintenanceGeneralDataView: {
                            fields: res.data[USAGE_POOL_MAINTENANCE_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        UsagePoolMaintenanceToolbar: {
                            fields: res.data[USAGE_POOL_MAINTENANCE_TOOLBAR] as IRepertoireField[]
                        },
                        UsagePoolMaintenanceGridsView: {
                            fields: res.data[USAGE_POOL_MAINTENANCE_GRIDS_VIEW] as IRepertoireField[]
                        },
                        UsagePoolMaintenanceAllocationSettingsGrid: {
                            fields: res.data[USAGE_POOL_MAINTENANCE_ALLOCATION_SETTINGS_GRID]
                        },
                        UsagePoolMaintenanceWeightSettingsGrid: {
                            fields: res.data[USAGE_POOL_MAINTENANCE_WEIGHT_SETTINGS_GRID] 
                        },
                        UsagePoolMaintenanceEstimatedValuePerPointSettingsGrid:{
                            fields: res.data[USAGE_POOL_MAINTENANCE_ESTIMATED_VALUE_PER_POINT_SETTINGS_GRID] 
                        },
                        DistributionsPage: {
                            fields: res.data[DISTRIBUTIONS_PAGE_VIEW] as IRepertoireField[]
                        },
                        DistributionMaintenanceGeneralDataView: {
                            fields: res.data[DISTRIBUTIONS_MAINTENANCE_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        DistributionMaintenanceToolbar: {
                            fields: res.data[DISTRIBUTION_MAINTENANCE_TOOLBAR] as IRepertoireField[]
                        },
                        DistributionMaintenanceGridsViewData: {
                            fields: res.data[DISTRIBUTION_MAINTENANCE_GRIDS_VIEW] as IRepertoireField[]
                        },
                        ClaimMaintenanceGeneralDataView: {
                            fields: res.data[CLAIMS_MAINTENANCE_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        ClaimMaintenanceGridsView: {
                            fields: res.data[CLAIMS_MAINTENANCE_GRIDS_VIEW] as IRepertoireField[]
                        },
                        ClaimsPage: {
                            fields: res.data[CLAIMS_PAGE_VIEW] as IRepertoireField[]
                        },
                        AdjustmentsPage: {
                            fields: res.data[ADJUSTMENTS_PAGE_VIEW] as IRepertoireField[]
                        },
                        AdjustmentMaintenanceGeneralDataView: {
                            fields: res.data[ADJUSTMENT_MAINTENANCE_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        AdjustmentMaintenanceToolbarData: {
                            fields: res.data[ADJUSTMENT_MAINTENANCE_TOOLBAR] as IRepertoireField[]
                        },
                        AdjustmentMaintenanceGridsView: {
                            fields: res.data[ADJUSTMENT_MAINTENANCE_GRIDS_VIEW] as IRepertoireField[]
                        },
                        ClaimMaintenanceToolbar: {
                            fields: res.data[CLAIMS_MAINTENANCE_TOOLBAR] as IRepertoireField[]
                        },
                        IPTransferView: {
                            fields: res.data[IP_TRANSFER_VIEW] as IRepertoireField[]
                        },
                        AllocationAdjustmentDetailsViewData: {
                            fields: res.data[ALLOCATION_ADJUSTMENT_DETAILS_VIEW] as IRepertoireField[]
                        },
                        PaymentRunPage: {
                            fields: res.data[PAYMENTRUN_PAGE_VIEW] as IRepertoireField[]
                        },
                        PaymentRunMaintenanceGeneralDataView: {
                            fields: res.data[PAYMENTRUN_MAINTENANCE_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        PaymentRunMaintenanceToolbar: {
                            fields: res.data[PAYMENTRUN_MAINTENANCE_TOOLBAR] as IRepertoireField[]
                        },
                        PaymentRunMaintenanceGridsViewData: {
                            fields: res.data[PAYMENTRUN_MAINTENANCE_GRIDS_VIEW] as IRepertoireField[]
                        },
                        LicensesPage: {
                            fields: res.data[LICENSES_PAGE_VIEW] as IRepertoireField[]
                        },
                        LicenseMaintenanceGeneralDataView: {
                            fields: res.data[LICENSE_MAINTENANCE_GENERAL_DATA_VIEW] as IRepertoireField[]
                        },
                        LicenseMaintenanceToolbar: {
                            fields: res.data[LICENSE_MAINTENANCE_TOOLBAR] as IRepertoireField[]
                        },
                        LicenseMaintenanceGridsViewData: {
                            fields: res.data[LICENSE_MAINTENANCE_GRIDS_VIEW] as IRepertoireField[]
                        }
                    }
                    resolve(result);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    public static getMenuItems(portalType: string, dispatch?: Dispatch): Promise<IMenuItem[]> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("Component/MenuItems", { params: { portalType: portalType === MEMBERS_PORTAL ? 2 : 1 } })
                .then(res => {
                    if (dispatch)
                        dispatch(getMenuItemsSuccess(res.data))
                    resolve(res.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    public static getHomeComponentData(): Promise<
        IHomeComponentData
    > {
        return new Promise<IHomeComponentData>((resolve, reject) => {
            axios
                .get("Component/Module", { params: { moduleName: "Home" } })
                .then(res => {
                    const result: IHomeComponentData = {
                        SearchView: {
                            fields: res.data[SEARCH_VIEW] as IComponentField[]
                        },
                        JobSearchView: {
                            fields: res.data[JOB_SEARCH_VIEW] as IComponentField[]
                        },
                        JobSearchResultsGrid: {
                            fields: res.data[JOB_SEARCH_RESULTS_GRID] as IComponentField[]
                        },
                        TimeRangeSelector: {
                            fields: res.data[TIME_RANGE_SELECTOR] as IComponentField[]
                        },
                        JobSearchResultsView: {
                            fields: res.data[JOB_SEARCH_RESULTS_VIEW] as IComponentField[]
                        },
                        ManualJobView: {
                            fields: res.data[MANUAL_JOB_VIEW] as IComponentField[]
                        },
                        SearchResultsTable: {
                            fields: res.data[SEARCH_RESULTS_TABLE] as IRepertoireField[]
                        }
                    };
                    resolve(result);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    public static getDataIngestionComponentData(): Promise<
        IDataIngestionComponentData
    > {
        return new Promise<IDataIngestionComponentData>((resolve, reject) => {
            axios
                .get("Component/Module", { params: { moduleName: "DataIngestion" } })
                .then(res => {
                    const result: IDataIngestionComponentData = {
                        DataIngestionPage: {
                            fields: res.data["DataIngestionPage"] as IComponentField[]
                        },
                        ContainerDetailsWindow: {
                            fields: res.data["ContainerDetailsWindow"] as IComponentField[]
                        },
                        StoragePathItem: {
                            fields: res.data["StoragePathItem"] as IComponentField[]
                        },
                        YesNoPromptView: {
                            fields: res.data["YesNoPromptView"] as IComponentField[]
                        },
                        SearchView: {
                            fields: res.data["SearchView"] as IComponentField[]
                        },
                        SearchResultsTable: {
                            fields: res.data["SearchResultsTable"] as IComponentField[]
                        },
                        DataIngestionToolbar: {
                            fields: res.data[DATA_INGESTION_TOOLBAR] as IComponentField[]
                        },
                    };
                    resolve(result);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    public static getAccountComponentData(): Promise<
        IAccountComponentData
    > {
        return Promise.all([
            ComponentFields.getComponentDataItem(LOGIN_PAGE),

        ]).then(function (values) {
            return Object.assign({}, ...values);
        });
    }

    public static updateComponentFieldItem(fields: IRepertoireField[], componentName: string, componentInstance?: string): Promise<IRepertoireField[]> {
        const inputModel: {} = { fields: fields, componentName: componentName, componentInstance: componentInstance };
        return new Promise<IComponentField[]>((resolve, reject) => {
            axios
                .post('Component/Update', inputModel)
                .then(res => {
                    if (res.data)
                        resolve(res.data);
                    else
                        reject(false);
                });
        });
    }

    public static updateMembersPortalComponentFieldItem(fields: IMembersPortalField[], componentName: string, componentInstance?: string): Promise<IMembersPortalField[]> {
        const inputModel: {} = { fields: fields, componentName: componentName, componentInstance: componentInstance };
        return new Promise<IComponentField[]>((resolve, reject) => {
            axios
                .post('Component/Update', inputModel)
                .then(res => {
                    if (res.data)
                        resolve(res.data);
                    else
                        reject(false);
                });
        });
    }

        
    public static getShortcutKeys(): Promise<IShortCutKeys[]> {
        return new Promise<IShortCutKeys[]>((resolve) => {
            axios
                .get(`Component/ShortcutKeys`)
                .then(res => {
                    resolve(res.data);
                });
        });
    }

    public static updateMultipleComponentFieldItem(fields: IRepertoireField[]): Promise<boolean[]> {
        return new Promise<boolean[]>((resolve, reject) => {
            axios
                .post('Component/UpdateMultiple', fields)
                .then(res => {
                    if (res.data)
                        resolve(res.data);
                    else
                        reject(false);
                });
        });
    }


}
