import * as React from "react";
import {
    removeTab,
    openTab,
    addArtistToWork,
    setDataSource,
    addWorkToProduct,
    addArtistToProduct,
    updateRelatedWork,
    updateWorkSourceIP,
    updateEditableFields,
    addMatchWorkToUsage,
    addMatchProductToUsage, searchDataChanged,
    updateSelectionCriteria,
    updateForeignSocietyInfo,
    displayRepresentationFlag,
    addIPToAdjustmentDetails,
    addWorkToLicenseRequest,
    addIPToUsageDetails,
    updateProductSourceIP,
    addWorkToClaimRequest,
    updateScheduledJobField,
    updateTerritorySelector
} from "../redux/reducers/RepertoireReducer";
import { showModal, hideModal, showYesNoPrompt, updateComment } from "../redux/reducers/ModalReducer";
import TabsView from "./components/tabsView/TabsView";
import IRepertoireComponentDataItem from "../redux/types/IRepertoireComponentDataItem";
import { IContributorSearchQuery } from "./types/IContributorSearchQuery";
import { ILookupDictionary } from "../lookup/types/ILookupDictionary";
import { IArtist } from "./types/IArtist";
import { IContributorSearchResult } from "./types/IContributorSearchResult";
import { REPERTOIRE_PAGE_JOBS } from "./Consts";
import { IArtistSearchQuery } from "./types/IArtistSearchQuery";
import { ITabReduxItem } from "../redux/types/ITabReduxItem";
import { JobMonitoringService } from "../job/services/JobMonitoringService";
import { renderJobProgress } from "../redux/reducers/JobReducer";
import { IContributorItem } from "./types/IContributorItem";
import { IP } from "./types/IP";
import { DataSource } from "./types/DataSource";
import { IWorksSearchQuery } from "./types/IWorksSearchQuery";
import { IWorksSearchResult } from "./types/IWorksSearchResult";
import { IRepertoirePathInfo } from "./types/IRepertoirePathInfo";
import { setCurrentPage } from "../redux/reducers/HomeReducer";
import { IEnableModulesConfigurationParametersItem } from "../redux/types/IEnableModulesConfigurationParameterItem";
import { IActiveAccordion } from "../redux/types/IActiveAccordion";
import { IUsagesSearchQuery } from "./types/usageTypes/IUsagesSearchQuery";
import { IUsageGroupResult } from "./types/usageTypes/IUsageGroupsSearchResult";
import { IMatchWorksSearchQuery } from "./types/usageTypes/IMatchWorksSearchQuery";
import { IMatchWorksSearchResult } from "./types/usageTypes/IMatchWorksSearchResult";
import { IMatchProductsSearchQuery } from "./types/usageTypes/IMatchProductsSearchQuery";
import { IProductSearchResult } from "./types/IProductSearchResult";
import { ITreeData } from "./types/ITreeData";
import { addFileToUpload, CleanUploadFilesState, setInDropZone, setProgressUploadFileProccess, sortColumn } from "../redux/reducers/DataIngestionReducer";
import { IDistribution } from "./types/usageTypes/IDistribution";
import { IProductCore } from "./types/IProductCore";
import IDroppedFiles from "../redux/types/IDroppedFiles";
import { IComponentDataItem } from "../core/types/IComponentDataItem";
import { IDistributionState } from "../redux/types/IDistributionState";
import { getMatchingWorkInfo } from "../redux/thunks/RepertoireThunks";
import { IDistributionType } from "./types/usageTypes/IDistibutionType";
import { IIPsSearchQuery } from "./types/IIPsSearchQuery";
import { IipTransfer } from "./types/usageTypes/IipTransfer";
import { ILicenseUserSearchQuery } from "./types/ILicenseUserSearchQuery";
import { ILicenseUserSearchResult } from "./types/ILicenseUserSearchResult"
import { IProductSearchQuery } from "./types/IProductSearchQuery";
import { IDataActionToolbar } from "./types/IDataActionToolbar";
import { FormatFields } from "../redux/types/FormatFields";
import { IDistributionSubType } from "./types/usageTypes/IDistributionSubType";
import { IWorkSubjectSearchResult } from "./types/IWorkSubjectSearchResult";
import { ISubjectsSearchQuery } from "./types/ISubjectsSearchQuery";
import { ISubject } from "./types/ISubject";
import IShortCutKeys from "../redux/types/IShortCutKeys";
import CleanUpJobMonitoringService from "../membersportal/CleanUpJobMonitoringService";
import { RepertoireModal } from "./components/repertoireModal/RepertoireModal";
import { IMyPackagesDetails } from "./types/IMyPackagesDetails";


export interface IRepertoirePageProps {
    pathInfo: IRepertoirePathInfo;
    setDataSource: typeof setDataSource;
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    updateModalComment: typeof updateComment;
    displayModal: boolean;
    addArtistToWork: typeof addArtistToWork;
    addArtistToProduct: typeof addArtistToProduct;
    addWorkToProduct: typeof addWorkToProduct;
    updateWorkContributorIP: (dataSource: DataSource, workContributor: IContributorItem, ip: IP) => void;
    updateProductContributorIP: (productContributorID: number, ip: IP) => void;
    updateAgreementIP: (agreementAdminIPID: number, ip: IP) => void;
    updateAgreementWork: ( work: IWorksSearchResult) => void;
    updateAgreementCarveOutProduct: (agreementProductWorkID: number, product: IProductSearchResult) => void;
    updateMatchingWork: (workID: number, work: IWorksSearchResult) => void;
    updateMatchingProduct?: (productID: number, product: IProductSearchResult) => void;
    updateRelatedWork: typeof updateRelatedWork;
    updateWorkSourceIP: typeof updateWorkSourceIP;
    updateProductSourceIP: typeof updateProductSourceIP;
    renderJobProgress: typeof renderJobProgress;
    loadRepertoireFromPath: (pathInfo: IRepertoirePathInfo, location: Location) => void;
    modalContent: string;
    modalComponentInstance: string;
    modalProps: any;
    modalIndex: number;
    modalKey:string;
    displayModalCloseButton: boolean;
    modalTitle: string;
    getRepertoireFields: () => void;
    tabs: ITabReduxItem[];
    removeTab: typeof removeTab;
    openTab: typeof openTab;
    setCurrentPage: typeof setCurrentPage;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    workNotFoundViewData: IRepertoireComponentDataItem;
    loadingViewData: IRepertoireComponentDataItem;
    yesNoPromptViewData: IRepertoireComponentDataItem;
    repertoirePageDataView: IRepertoireComponentDataItem;
    tabsViewData: IRepertoireComponentDataItem;
    dataGridTableData: IRepertoireComponentDataItem;
    containerDetailsWindowComponentData: IComponentDataItem;
    dataSource: string;
    agreementsDataSource: string;
    productDataSource: string;
    lookupEntities: ILookupDictionary;
    reduxStorageLoaded: boolean;
    searchArtists: (query: IArtistSearchQuery, dataSource: string) => void;
    searchContributors: (
        query: IContributorSearchQuery
    ) => void;
    searchSubjects: (query: ISubjectsSearchQuery, modalOpen: boolean) => void;
    updateSubjectCode: (subject: ISubject) => void;
    searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    searchProducts: (query: IProductSearchQuery, modalOpen: boolean) => void;
    activeTab: number;
    closeTabDisabled: boolean;
    artistsSearchResults: IArtist[];
    contributorsSearchResults: IContributorSearchResult[];
    worksSearchResults: IWorksSearchResult[];
    productSearchResults: IProductSearchResult[];
    workSubjectSearchResults: IWorkSubjectSearchResult[];
    searchSuccessful: boolean;
    showYesNoPrompt?: typeof showYesNoPrompt;
    currentUser?: string;
    editableFields?: any[];
    updateEditableFields: typeof updateEditableFields;
    expandedWorkResults?: number[];
    expandedProductResults?: number[];
    expandAll?: boolean;
    expandWorkResult?: (workResult: number) => void;
    expandProductResult?: (productResult: number) => void;
    expandAllResults?: () => void;
    modalMessage: string;
    updateWorkflowViewData: IRepertoireComponentDataItem;
    enabledModules: IEnableModulesConfigurationParametersItem[];
    getAgreementShareDisplayOptions: () => void;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    component?: string;
    getShareDecimalsToDisplay: () => void;
    getUsageGridSearchResultsDisplayOptions: () => void;
    getIPGridSearchResultsDisplayOptions: () => void;
    getProductMaintenanceEnableCuesheets:()=>void;
    getProductMaintenanceCuesheetsDataSource:()=>void;
    getPDArrangementMusicLyricOptions: () => void;
    getWorkMaintenanceGeneralViewDisplayOptions: () => void;
    getProductMaintenanceGeneralViewDisplayOptions: () => void;
    getPoolMaintenanceGeneralViewDisplayOptions: () => void;
    getShareToleranceSettings: () => void;
    getDistributionMaintenanceSettingsDisplayOptions:()=>void;
    getUsageMatchingDefaultsProducts: () => void;
    getUsageMatchingDefaultsWorks: () => void;
    getShortCutKeys: () => void;
    shortCutKeys: IShortCutKeys[];
    usageSearchViewData: IRepertoireComponentDataItem;
    usageSearchResultsTableData: IRepertoireComponentDataItem;
    searchUsages: (query: IUsagesSearchQuery, modalOpen: boolean, usageType: string, lookupSources: ITreeData[]) => void;
    usageGroupsSearchResult: IUsageGroupResult[];
    searchMatchWorks: (query: IMatchWorksSearchQuery, modalOpen: boolean) => void;
    searchMatchProducts: (query: IMatchProductsSearchQuery, modalOpen: boolean) => void;
    matchWorkSearchResult: IMatchWorksSearchResult[];
    expandMatchWorkResult: (matchWorkResults: number) => void;
    addMatchworkToUsage?: typeof addMatchWorkToUsage;
    expandedMatchWorkResults?: number[];
    usageExpandAll?: boolean,
    usageExpandAllResults?: () => void;
    getUsageDetails: (usageID: string) => void;
    getDistributions: () => void;
    matchProductsSearchResult: IProductSearchResult[];
    addMatchProductToUsage?: typeof addMatchProductToUsage;
    lookupValues: ILookupDictionary;
    searchDataChanged: typeof searchDataChanged;
    ipMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    myPackagesGeneralDataViewData?: IRepertoireComponentDataItem;
    addNewAccount: (lookupValues: ILookupDictionary, isNew?: boolean, ipMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => void;
    getSources: () => void;
    lookupSources: ITreeData[];
    updateSelectionCriteria: typeof updateSelectionCriteria;
    distributions?: IDistribution[];
    products?: IProductCore[];
    displayRepresentation: typeof displayRepresentationFlag;
    isReadOnlyModal?: boolean;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    sortColumn: typeof sortColumn;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    isRepertoireModule?: boolean;
    importDistributionPools: (distribution: IDistributionState, file: string, lookupValues: ILookupDictionary) => void;
    updateForeignSocietyInfo: typeof updateForeignSocietyInfo;
    getMatchingWorkInfo: typeof getMatchingWorkInfo;
    distributionTypes: IDistributionType[];
    getDistributionTypes: () => void;
    distributionSubTypes: IDistributionSubType[];
    getDistributionSubTypes: () => void;
    getLookupEntities?: () => void;
    ipTransferViewData: IRepertoireComponentDataItem;
    searchIPs?: (query: IIPsSearchQuery) => void;
    ipTransfer: (ipTransferObject: IipTransfer) => void;
    addIpToAdjustmentDetail?: typeof addIPToAdjustmentDetails;
    addIPToUsageDetails?: typeof addIPToUsageDetails;
    usageMatchingDefaultsWorks?: string[];
    usageMatchingDefaultsProducts?: string[];
    searchLicenseUser: (query: ILicenseUserSearchQuery) => void;
    licenseUserSearchResults: ILicenseUserSearchResult[];
    updateLicenceUserValues?: (params: any) => void;
    addWorkToLicenseRequest?: typeof addWorkToLicenseRequest;
    addWorkToClaimRequest?: typeof addWorkToClaimRequest;
    getCommentCategoryFlag: () => void;
    getAgreementAttachmentTypeRequired: () => void;
    getExcludeSourceTypePickShowFields: () => void;
    updateScheduledJobField?: typeof updateScheduledJobField;
    getCustomerDateConfiguration: () => void;
    customerDateConfiguration?: string;
    updateTerritorySelector?: typeof updateTerritorySelector;
    getRecalculateDomesticorForeignThunk?:()=>void;
    customer?: string;
    getDownloadFileFormat?: () => void;
    myPackagesResult?:IMyPackagesDetails[];
}

interface IRepertoirePageState {
    loaded: boolean;
}

export default class RepertoirePage extends React.Component<
    IRepertoirePageProps, IRepertoirePageState
> {
    viewData;
    constructor(props: IRepertoirePageProps) {
        super(props);
        this.state = { loaded: false };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            getRepertoireFields,
            shortCutKeys,
            getShortCutKeys,
            renderJobProgress,
            searchViewData,
            pathInfo,
            loadRepertoireFromPath,
            getAgreementShareDisplayOptions,
            getShareDecimalsToDisplay,
            getUsageGridSearchResultsDisplayOptions,
            getIPGridSearchResultsDisplayOptions,
            getProductMaintenanceEnableCuesheets,
            getProductMaintenanceCuesheetsDataSource,
            getPDArrangementMusicLyricOptions,
            getWorkMaintenanceGeneralViewDisplayOptions,
            getProductMaintenanceGeneralViewDisplayOptions,
            getPoolMaintenanceGeneralViewDisplayOptions,
            getShareToleranceSettings,
            getDistributionMaintenanceSettingsDisplayOptions,
            getUsageMatchingDefaultsProducts,
            getUsageMatchingDefaultsWorks,
            usageSearchViewData,
            getDistributions,
            lookupSources,
            getSources,
            distributionTypes,
            getDistributionTypes,
            distributionSubTypes,
            getDistributionSubTypes,
            lookupValues,
            getLookupEntities,
            getCommentCategoryFlag,
            getAgreementAttachmentTypeRequired,
            getExcludeSourceTypePickShowFields,
            getCustomerDateConfiguration,
            customerDateConfiguration,
            getRecalculateDomesticorForeignThunk,
            getDownloadFileFormat
        } = this.props;

        if (!searchViewData || searchViewData.fields.length === 0) {
            getRepertoireFields && getRepertoireFields();
        }
        if (!usageSearchViewData || usageSearchViewData.fields.length === 0) {
            getRepertoireFields && getRepertoireFields();
        }
        if (!(shortCutKeys && shortCutKeys.length > 0)) {
            getShortCutKeys && getShortCutKeys();
        }
        if (!customerDateConfiguration) {
            getCustomerDateConfiguration && getCustomerDateConfiguration();
        }
        if (pathInfo && pathInfo.submodule) {
            loadRepertoireFromPath && loadRepertoireFromPath(pathInfo, window.location);
        }
        if (!lookupSources) {
            getSources && getSources();
        }

        if (!lookupValues || Object.keys(lookupValues).length == 0) {
            getLookupEntities && getLookupEntities();
        }
        if (!distributionTypes) {
            getDistributionTypes && getDistributionTypes();
        }
        if (!distributionSubTypes) {
            getDistributionSubTypes && getDistributionSubTypes();
        }


        renderJobProgress && JobMonitoringService.startMonitoringProgress(renderJobProgress);
        getAgreementShareDisplayOptions && getAgreementShareDisplayOptions();
        getShareDecimalsToDisplay && getShareDecimalsToDisplay();
        getUsageGridSearchResultsDisplayOptions && getUsageGridSearchResultsDisplayOptions();
        getRecalculateDomesticorForeignThunk && getRecalculateDomesticorForeignThunk();
        getIPGridSearchResultsDisplayOptions && getIPGridSearchResultsDisplayOptions();
        getProductMaintenanceEnableCuesheets && getProductMaintenanceEnableCuesheets();
        getProductMaintenanceCuesheetsDataSource && getProductMaintenanceCuesheetsDataSource();
        getPDArrangementMusicLyricOptions && getPDArrangementMusicLyricOptions(),
        getWorkMaintenanceGeneralViewDisplayOptions && getWorkMaintenanceGeneralViewDisplayOptions();
        getProductMaintenanceGeneralViewDisplayOptions && getProductMaintenanceGeneralViewDisplayOptions();
        getPoolMaintenanceGeneralViewDisplayOptions && getPoolMaintenanceGeneralViewDisplayOptions();
        getShareToleranceSettings && getShareToleranceSettings();
        getDistributionMaintenanceSettingsDisplayOptions && getDistributionMaintenanceSettingsDisplayOptions();
        getUsageMatchingDefaultsProducts && getUsageMatchingDefaultsProducts();
        getUsageMatchingDefaultsWorks && getUsageMatchingDefaultsWorks();
        getDistributions && getDistributions();
        getDownloadFileFormat && getDownloadFileFormat();
        getCommentCategoryFlag();
        getAgreementAttachmentTypeRequired();
        getExcludeSourceTypePickShowFields();
    }

    componentDidUpdate(prevProps: IRepertoirePageProps, prevState: IRepertoirePageState) {
        const { loaded } = this.state;
        if (!loaded) {
            if (!this.props.repertoirePageDataView) {
                return;
            }

            const { repertoirePageDataView: { fields } } = this.props;

            fields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === fields.length) {
                    this.setState({ loaded: true });
                }
            });
        }

        const { pathInfo, loadRepertoireFromPath } = this.props;
        const prevPath = prevProps.pathInfo && prevProps.pathInfo.submodule;
        const newPath = pathInfo && pathInfo.submodule;
        if (newPath && newPath !== prevPath) {
            loadRepertoireFromPath(pathInfo, window.location);
        }
    }

    render() {
        const {
            displayModal,
            tabs,
            tabsViewData,
            removeTab,
            openTab,
            activeTab,
            closeTabDisabled,
            setCurrentPage,
            showYesNoPrompt,
            hideModal,
            displayRepresentation,
            shortCutKeys,
        } = this.props;
        
        let contentClassName: string = "container-fluid pageContent";

        return (
            <div> 
                <CleanUpJobMonitoringService />
                <div className={contentClassName}>
                    {tabs && tabs.length > 0 ? (<div className="row">
                        <TabsView
                            tabs={tabs}
                            tabsViewData={tabsViewData}
                            removeTab={removeTab}
                            activeTab={activeTab}
                            openTab={openTab}
                            closeTabDisabled={closeTabDisabled}
                            hideModal={hideModal}
                            showYesNoPrompt={showYesNoPrompt}
                            displayRepresentation={displayRepresentation}
                            setCurrentPage={setCurrentPage}
                            portalType="MatchingEngine"
                            displayModal={displayModal}
                            shortCutKeys={shortCutKeys}
                        />
                    </div>) : null}
                </div>
                <a target="_blank" id="downloadRepertoireAttachments"></a>
                {displayModal ? (<RepertoireModal {...this.props} isDropZone={true} />) : null}
            </div>
        );
    }
}
