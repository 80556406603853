import * as React from "react";
import { Link } from "react-router-dom";
import { NavItem, NavLink } from 'reactstrap';
import { MATCHING_ENGINE, MEMBERS_PORTAL } from "../../../accounts/Consts";
import { clearDataIngestionCache } from "../../../redux/reducers/DataIngestionReducer";
import { clearHomeCache, setCurrentPage, toggleTopMenuIsActive } from "../../../redux/reducers/HomeReducer";
import { clearLookupCache } from "../../../redux/reducers/LookupReducer";
import { clearRepertoireCache } from "../../../redux/reducers/RepertoireReducer";
import { SESSION_STORAGE, LOCAL_STORAGE_VERSION } from "../../../redux/StorageEngineKeys";
import { IEnableModulesConfigurationParametersItem } from "../../../redux/types/IEnableModulesConfigurationParameterItem";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { If } from "../../components/If";
import { IMenuItem } from "../../types/IMenuItem";
import { Menu } from "../Menu";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { FormatFields } from "../../../redux/types/FormatFields";
import ViewPreferenceDropDown from "../ViewPreferenceDropDown";
import { isNullOrUndefined } from "../../../util/tools";
import { JOBS_IN_PROGRESS_MODAL, MY_PACKAGES_PAGE, REPERTOIRE_PAGE_JOBS, SHORTCUT_KEY_MODAL } from "../../../repertoire/Consts";
import { IJobState } from "../../../redux/types/IJobState";
import { INGESTION_PAGE_JOBS } from "../../../dataingestion/Consts";
import IMembersPortalComponentDataItem from "../../../redux/types/IMembersPortalComponentDataItem";

interface IHeaderProps {
    setCurrentPage: typeof setCurrentPage;
    toggleTopMenuIsActive: typeof toggleTopMenuIsActive;
    getMenuItems: (portalType: string) => void;
    menuItems: IMenuItem[];
    currentPage: IMenuItem;
    topMenuIsActive: boolean;
    username: string;
    isSignedIn: boolean;
    enabledModules?: IEnableModulesConfigurationParametersItem[]
    clearLookupCache: typeof clearLookupCache;
    clearHomeCache: typeof clearHomeCache;
    clearRepertoireCache: typeof clearRepertoireCache;
    clearDataIngestionCache: typeof clearDataIngestionCache;
    displayCustomerLogo: boolean;
    getDisplayCustomerLogo: () => void;
    logoAddresses: string[];
    isLogoRequested: boolean;
    portalType: string;
    customer: string;
    getCustomer: () => void;
    addTab: (tab: ITabReduxItem, portalType: string) => void;
    addWork: (lookups: ILookupDictionary, currentUserNumber: string, formats?: FormatFields[]) => void;
    addSetLists: (lookups: ILookupDictionary, currentUserNumber: string) => void;
    addAgreements: (lookups: ILookupDictionary, currentUserNumber: string,isNew?:boolean,draftAgreementsMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem) => void;
    currentUserNameNumber?: string;
    lookupValues?: ILookupDictionary;
    getUserCRMValues:()=>void;
    showModal: (
        modalContent: string,
        modalComponentInstance: string,
        modalProps: any,
        displayModalCloseButton: boolean,
        title: string
    ) => void;
    jobs: IJobState[];
}

interface IHeaderState {
    isLoading: boolean
}

export class Header extends React.Component<IHeaderProps, IHeaderState> {
    viewData;
    constructor(props: IHeaderProps) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    updateHeader = (menuItem: IMenuItem) => {
        this.props.setCurrentPage(menuItem.link);

        document.title = menuItem.label + " | Matching Engine";
    };

    componentDidMount() {
        const {
            getMenuItems,
            menuItems,
            portalType,
            getCustomer,
        } = this.props;
        if (portalType === MEMBERS_PORTAL) {
            this.setState(() => ({ isLoading: true }));
            localStorage.setItem('portalType', portalType);
        }
        getCustomer();
        if (portalType && (!menuItems || menuItems.length === 0)) {
            this.setState(() => ({ isLoading: true }))
            getMenuItems(portalType);
        }
        document.title = portalType === MEMBERS_PORTAL ? "Members Portal" : "Matching Engine";

        
    }

    refreshCache(): void {
        const { clearDataIngestionCache, clearHomeCache, clearLookupCache, clearRepertoireCache } = this.props;
        window.localStorage.removeItem(LOCAL_STORAGE_VERSION);
        window.sessionStorage.removeItem(SESSION_STORAGE);
        sessionStorage.setItem('isSignedIn', 'false')
        clearDataIngestionCache();
        clearHomeCache();
        clearLookupCache()
        clearRepertoireCache()
    }
    
    handleAddTab = () => {
        let currentPage: IMenuItem;
        currentPage = {
            icon: 'icon ms-Icon ms-Icon--CubeShape',
            children: [],
            isEnabled: false,
            label: 'mypackages',
            link:'/repertoire/mypackages',
            pageHeader: 'My Packages',
            portalType: ''
        }
        const { addTab, addWork, addSetLists,addAgreements, currentUserNameNumber,portalType } = this.props;

        const META_DATA_KEY: any = {
            'mypackages': { 'tab': MY_PACKAGES_PAGE, instance: MATCHING_ENGINE }
        }

        this.updateHeader(currentPage);

        if (META_DATA_KEY[currentPage.label.toLowerCase()])
            if(META_DATA_KEY[currentPage.label.toLowerCase()].instance === portalType)
                addTab(META_DATA_KEY[currentPage.label.toLowerCase()].tab,portalType);
    };

    componentDidUpdate(prevProps, prevState: IHeaderState) {
        const {
            getMenuItems,
            menuItems,
            portalType
        } = this.props;
        const { isLoading } = this.state;
        if (isLoading && menuItems && menuItems.length > 0) {
            this.setState(() => ({ isLoading: false }));
        }
        if (!isLoading && !prevState.isLoading && portalType && (!menuItems || menuItems.length === 0)) {
            this.setState(() => ({ isLoading: true }))
            getMenuItems(portalType);
        }
    }

    renderHeaderLogo = () => {
        const { portalType } = this.props;
        if (portalType) {
            if (portalType === MEMBERS_PORTAL) {
                return <img src={`assets/logo-spt.png`} title="Members Portal" alt="Members Portal" />
            }
            else {
                return <img src="assets/logo-matching-engine.png" title="Spanish Point Technologies" alt="Spanish Point Technologies" />
            }
        }
    }

    render() {
        if (window.location.pathname === "/aadLogin") {
            return <div className="emptyHeader" />;
        }

        let className: string = "collapse navbar-collapse";
        const {
            currentPage,
            menuItems,
            topMenuIsActive,
            toggleTopMenuIsActive,
            enabledModules,
            displayCustomerLogo,
            logoAddresses,
            getDisplayCustomerLogo,
            isLogoRequested,
            addTab,
            addWork,
            addSetLists,
            addAgreements,
            portalType,
            currentUserNameNumber,
            customer,
            lookupValues,
            showModal,
            jobs,
        } = this.props;
        const { isLoading } = this.state;
        const DATA_INGESTION = 'Data Ingestion';
        const jobsToDisplay = REPERTOIRE_PAGE_JOBS;
        const displayedJobs = jobs && jobs.filter(j => jobsToDisplay.includes(j.type));
        const jobsLength = displayedJobs.length;

        const isSignedIn = sessionStorage.getItem('isSignedIn');
        const isSignedInParsed = isSignedIn && isSignedIn == 'true' ? true : false
        let enabled = enabledModules
        const username = sessionStorage.getItem('username')
        if (!isSignedInParsed)
            return <div className="emptyHeader" />;

        if (topMenuIsActive) {
            className = className + " in";
        }

        if (isSignedInParsed && !isLogoRequested) {
            getDisplayCustomerLogo();
        }

        const showModalShortCutKeys = () => {
            showModal(SHORTCUT_KEY_MODAL, SHORTCUT_KEY_MODAL, null, true, "Shortcuts keys");
        }

        const handleOpenJobsInProgressModal = () => {
            showModal(JOBS_IN_PROGRESS_MODAL, JOBS_IN_PROGRESS_MODAL, currentPage, true, "Jobs in progress");
        }

        const renderJobsInProgressLink = jobsLength && jobsLength >= 1;

        const renderTitleSection = () => {

            if (isNullOrUndefined(currentPage)) {
                return null;
            }
            else {
                return <div className="titleSection flexRow flexJustifyContentSpaceBetween tabBannerSection">
                    <div className="flexRow paddingLeftLarge">
                    <span className={currentPage.icon} aria-hidden="true"></span>
                    <span>{currentPage.pageHeader}</span>
                    </div>
                    <div className="paddingRightLarge">
                        {!!renderJobsInProgressLink && <div className="jobsProgressHeader" onClick={handleOpenJobsInProgressModal}><span className="icon info ms-Icon ms-Icon--SyncStatus"></span><a>{`${jobsLength} Jobs in Progress`}</a></div>}
                    </div>
                </div>
        }
            }

        const filteredMenuItems = menuItems.filter(m => m.link !== '/');
        if (portalType === MEMBERS_PORTAL && !currentUserNameNumber)
            filteredMenuItems.forEach(i => i.isEnabled = false);
        if (portalType === MEMBERS_PORTAL && currentUserNameNumber && currentUserNameNumber !== "00000") {
            filteredMenuItems.forEach(i => i.isEnabled = true);
        }
        if (portalType === MEMBERS_PORTAL && currentUserNameNumber && currentUserNameNumber == "00000") {
            filteredMenuItems.filter(m => m.label == "My Account").forEach(i => i.isEnabled = true)
            filteredMenuItems.filter(m => m.label !== "My Account").forEach(function (c) {
                c.children.forEach(c => c.isEnabled = false)
            });

        }
        if (!isLoading)
            return (
                <header className="container-full">
                    <nav className="navbar navbar-fixed-top" role="navigation">
                        <div className="container-fluid topSection">

                            {/* Brand and toggle get grouped for better mobile display -->*/}
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle" data-toggle="collapse"
                                    onClick={toggleTopMenuIsActive}>
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <NavLink tag={Link} className="navbar-brand" to="/">
                                    {this.renderHeaderLogo()}
                                </NavLink>
                            </div>

                            <div className={className}>
                                <If condition={menuItems && menuItems.length > 0}>
                                    <Menu items={filteredMenuItems} 
                                        updateHeader={this.updateHeader.bind(this)} 
                                        enabledModules={enabledModules}
                                        isMembersPortal={portalType === MEMBERS_PORTAL}
                                        customer={customer}
                                        addTab={(tab) => addTab(tab, portalType)} 
                                        currentUserNameNumber={currentUserNameNumber}
                                        lookups={lookupValues}
                                        addWork={addWork}
                                        addAgreements={addAgreements}
                                        addSetLists={addSetLists}/>
                                </If>


                                {/* Account & Logo -->*/}
                                <ul className="nav navbar-nav navbar-right">
                                    <ViewPreferenceDropDown />
                                    {/* Logo -->*/}
                                    <If condition={displayCustomerLogo === true && logoAddresses !== undefined && logoAddresses.length > 0}>
                                        <div id="logo" className="navbar-header">
                                            {logoAddresses.map((address) => {
                                                return (
                                                    <NavLink tag={Link} className="navbar-brand" to="/">
                                                        <img src={address} title="EU" alt="EU" />
                                                    </NavLink>
                                                )
                                            })}
                                        </div>
                                    </If>
                                    <If condition={(username !== null || username !== undefined) && username.length > 0 && portalType === MEMBERS_PORTAL} >
                                        <li className="dropdown">
                                            <NavLink className="dropdown-toggle" tag={Link} to="/">
                                                {username}
                                                <b className="caret" />
                                            </NavLink>
                                            <ul className="dropdown-menu">
                                                <NavItem>
                                                    <NavLink tag={Link} to="/myAccount"><h3>My Account</h3></NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink tag={Link} to="/myAccount">Account Details</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink tag={Link} to="/myAccount/viewAs">View As</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink tag={Link} to="/myAccount/requestAccess">Request Access to My Account(s)</NavLink>
                                                </NavItem>
                                                <hr/>
                                                <NavItem>
                                                    <NavLink tag={Link} to="/signOut" >Log Out</NavLink>
                                                </NavItem>
                                                <li>
                                                    <a href="/signOut" onClick={this.refreshCache}>Clear Cache</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </If>
                                    <If condition={(username !== null || username !== undefined) && username.length > 0 && portalType !== MEMBERS_PORTAL}>
                                    <li className="dropdown">
                                            <NavLink className="dropdown-toggle" tag={Link} to="/">
                                                {username}
                                                <b className="caret" />
                                            </NavLink>
                                            <ul className="dropdown-menu">
                                                <NavItem>
                                                    <a onClick={(e) => showModalShortCutKeys()} >Shortcuts</a>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink tag={Link} to="/signOut" >Log Out</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink tag={Link} onClick={()=> this.handleAddTab()} to="/repertoire/mypackages" >My Packages</NavLink>
                                                </NavItem>
                                                <li>
                                                    <a href="/signOut" onClick={this.refreshCache}>Clear Cache</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </If>
                                </ul>
                            </div>
                        </div>
                        {renderTitleSection()}
                    </nav>
                </header>
            );
        return <div></div>
    }
}
