import IAppState from "../../redux/types/IAppState";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import MyPackagesPage from "./MyPackagesPage"
import { addTab, createNewAccount, resetPagination, sortSearchResults, updatePagination } from "../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { showModal } from "../../redux/reducers/ModalReducer";
import { IIPsSearchQuery } from "../types/IIPsSearchQuery";
import { searchIPsThunk, getIPDetailsThunk, updateUserPreferencesThunk, getDownloadFileFormatThunk, getMyPackageDetailsThunk, clearPakagesThunk, loadRepertoireFromPathThunk, generatePackageThunk, closePackageThunk, addPackageNoteThunk } from "../../redux/thunks/RepertoireThunks";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { getUserCRMValuesThunk } from "../../redux/thunks/AccountThunks";
import { IMyPackagesDetails } from "../types/IMyPackagesDetails";
import { IRepertoirePathInfo } from "../types/IRepertoirePathInfo";
import { RouteChildrenProps } from 'react-router';

export default connect(
    (state: IAppState, props?: RouteChildrenProps) => ({
        packageMaintenanceGridsViewData: state.repertoire.componentData.MyPackagesMaintenancePage,
        myPackagesGeneralDataViewData:state.repertoire.componentData.MyPackagesGeneralDataView,
        myPackagesGridView:state.repertoire.componentData.MyPackagesGridView,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.MyPackagesGridView,
        lookupValues: state.lookupEntities,
        downloadFileFormat:state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].downloadFileFormat,
        username: state.account.currentUser,
        myPackagesResult: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].myPackagesResult
    }),
    (dispatch: Dispatch) => ({
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        getDownloadFileFormat: () => dispatch<any>(getDownloadFileFormatThunk()),
        getMyPackagesDetails: (userName: string, modalOpen: boolean) =>
            dispatch<any>(getMyPackageDetailsThunk(userName, true)),
        getUserCRMValues: () => dispatch<any>(getUserCRMValuesThunk()),
        clearPackages:(myPackages:IMyPackagesDetails)=>dispatch<any>(clearPakagesThunk(myPackages)),
        loadRepertoireFromPath: (pathInfo: IRepertoirePathInfo, location: Location) => dispatch<any>(loadRepertoireFromPathThunk(pathInfo, location)),
        generatePackage: (myPackage: IMyPackagesDetails, selectedFormat: any) => dispatch<any>(generatePackageThunk(myPackage, selectedFormat)),
        closePackage: (myPackage: IMyPackagesDetails) => dispatch<any>(closePackageThunk(myPackage)),
        addPackageNote: (myPackage: IMyPackagesDetails) => dispatch<any>(addPackageNoteThunk(myPackage)),

    })
) (MyPackagesPage);