import * as React from "react";
import { ITriggerManualJob } from "../../../redux/types/ITriggerManualJob";
import { ATF_JOB_TYPE,DEADLINE_DATE_KEY,DISTRIBUTION_DATE_KEY,MINIMUM_THRESHOLD_KEY, USAGE_DATE_FROM_KEY, USAGE_DATE_TO_KEY } from "../../Consts";
import { getOpenDistributionsThunk, triggerManualJob } from "../../../redux/thunks/HomeThunks";
import { IDistributionState } from "../../../redux/types/IDistributionState";
import TextDataInput from "../../../repertoire/components/textDataInput/TextDataInput";
import SizedDateDataInput from "../../../repertoire/components/dateDataInput/DateDataInput";
import ActionButton from "../../../repertoire/components/actionButton/ActionButton";
import {Dropdown, IDropdownOption} from "office-ui-fabric-react/lib/Dropdown";


export interface IICEATFJobParametersProps {
    triggerManualJob:typeof triggerManualJob;
    createNewJob: any;
    setManualJobParameters: (parameters: ITriggerManualJob) => void;
    getOpenDistributions: typeof getOpenDistributionsThunk;
    openDistributions?: IDistributionState[];
}

interface IICEATFJobParametersState {
    distributionDate: string;
    deadlineDate:string;
    minimumThreshold: number;
    usageDateFrom:string;
    usageDateTo:string;
    distributionIDs: Array<number>;
    generalError: string;
}

export class ICEATFJobParameters extends React.Component<
    IICEATFJobParametersProps,
    IICEATFJobParametersState
> {
    constructor(props: IICEATFJobParametersProps) {
        super(props);
        this.state = {
            minimumThreshold: null,
            deadlineDate: '',
            distributionDate: '',
            usageDateFrom:'',
            usageDateTo:'',
            distributionIDs: [],
            generalError: ""
        };
    }
    onClick = () => {
        if (this.setParamsandTriggerJob()) {
            const { createNewJob } = this.props
            createNewJob();
        }
    }
         handleDeadlineDateChange = (value : string | number | boolean, fieldname: string) => {
          this.setState({
                 deadlineDate: value as string
             })
        }
         handleDistributionDateChange = (value : string | number | boolean, fieldname: string) => {
            this.setState({
                distributionDate: value as string
            })
        }
         handleMinimumThresholdChange =  (value : string | number | boolean, fieldname: string) => {
            this.setState({
                minimumThreshold: value as number
            })

        }    
        handleUsageDateFromChange = (value : string | number | boolean, fieldname: string) => {
            this.setState({
                   usageDateFrom: value as string
               })
          }
          handleUsageDateToChange = (value : string | number | boolean, fieldname: string) => {
            this.setState({
                   usageDateTo: value as string
               })
          }

    handleOptionChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        const { distributionIDs } = this.state;

        if (option?.selected) {
            distributionIDs.push(option.data);
            this.setState({ distributionIDs });
        } else {
            const updatedOptions = distributionIDs.filter(opt => opt !== option.data);
            this.setState({ distributionIDs: updatedOptions });
        }
    };
        renderOpenDistributions = () => {
            const { openDistributions  } = this.props;

            let filteredDistributions = [];
            let distributionsOptions = [];
            let newDistribution = openDistributions;
            filteredDistributions = newDistribution.filter(d => d.distributionPools.length > 0)
    
            distributionsOptions.push(<option value="" title="emtpy" key=""></option>);
    
           
            if (filteredDistributions && filteredDistributions.length > 0) {

                filteredDistributions.map((val) => distributionsOptions.push({
                    data: val.distributionID,
                    text: val.distributionCode + ' - ' + val.description,
                    key: val.distributionID
                        .toString()
                }));
    
            }

            return distributionsOptions
        }
    
    componentDidMount() {
        const {
            getOpenDistributions,
            openDistributions,
        } = this.props;

        if (!openDistributions || openDistributions.length === 0) getOpenDistributions();
    }

    setParamsandTriggerJob = () => {
        const { setManualJobParameters, triggerManualJob } = this.props;
        const { distributionDate, deadlineDate, minimumThreshold, usageDateFrom, usageDateTo, distributionIDs } = this.state;

        if (this.validateFields()) {
            const manualJobParameters: ITriggerManualJob = {
                jobType: ATF_JOB_TYPE,
                jobParameters: JSON.stringify({ distributionIDs, distributionDate, deadlineDate, minimumThreshold, usageDateFrom, usageDateTo }),
            };

            setManualJobParameters(manualJobParameters);
            this.setState({ generalError: "" });
            return triggerManualJob(manualJobParameters)
        } else {
            this.setState({ generalError: "Please fill in all the required fields" });
            return false;
        }
    }

    validateFields = () => {
        const { distributionDate, deadlineDate, minimumThreshold, usageDateFrom, usageDateTo, distributionIDs } = this.state;
        return (!minimumThreshold || !distributionDate || !deadlineDate || !usageDateFrom || !usageDateTo || distributionIDs.length === 0) ? false : true;
    };

    dismissAlert = () => {
        this.setState({ generalError: "" });
    };

    render() {
        const{minimumThreshold,distributionDate,deadlineDate,usageDateFrom,usageDateTo,generalError} = this.state;
        
        return (
            <>
                {generalError && (
                    <div className="alert alert-danger alert-dismissible">
                        <span>{generalError}</span>
                        <button type="button" className="close" aria-label="Close" onClick={this.dismissAlert}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                )}

            <div className="form-group col-md-6 col-sm-6 col-xs-12">
                 <label>Distributions</label>
                    <Dropdown
                    options={this.renderOpenDistributions()}
                    multiSelect={true}
                    onChange={this.handleOptionChange}
                    placeholder={""}
                    disabled={false}
                    required={true}
                    />
            </div> 

                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <TextDataInput
                        label={MINIMUM_THRESHOLD_KEY}
                        fieldName={MINIMUM_THRESHOLD_KEY}
                        changeData={this.handleMinimumThresholdChange}
                        value={minimumThreshold}
                        readOnly={false}
                        isHidden={false}
                        isMandatory={true}
                    />
                </div>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                <SizedDateDataInput
                    label={DISTRIBUTION_DATE_KEY}
                    fieldName={DISTRIBUTION_DATE_KEY}
                    value={distributionDate}
                    changeData={this.handleDistributionDateChange}
                    readOnly={false}
                    isHidden={false}
                    isMandatory={true}
                    />
                <SizedDateDataInput
                    label={DEADLINE_DATE_KEY}
                    fieldName={DEADLINE_DATE_KEY}
                    value={deadlineDate}
                    changeData={this.handleDeadlineDateChange}
                    readOnly={false}
                    isHidden={false}
                    isMandatory={true}
                    />
                </div>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                <SizedDateDataInput
                    label={USAGE_DATE_FROM_KEY}
                    fieldName={USAGE_DATE_FROM_KEY}
                    value={usageDateFrom}
                    changeData={this.handleUsageDateFromChange}
                    readOnly={false}
                    isHidden={false}
                    isMandatory={true}
                    />
                <SizedDateDataInput
                    label={USAGE_DATE_TO_KEY}
                    fieldName={USAGE_DATE_TO_KEY}
                    value={usageDateTo}
                    changeData={this.handleUsageDateToChange}
                    readOnly={false}
                    isHidden={false}
                    isMandatory={true}
                    />
                </div>
                <div className="row">
                     <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                        <ActionButton buttonText={"Create Manual Job"} buttonAction={() => this.onClick()} />
                    </div>
                </div>            
            </>
        );
    }
}
export default ICEATFJobParameters;