import * as React from "react";
import {
    WORKS_PAGE,
    IPS_PAGE,
    AGREEMENTS_PAGE,
    WORK_MAINTENANCE_PAGE,
    PRODUCTS_PAGE,
    AGREEMENT_MAINTENANCE_PAGE,
    IP_MAINTENANCE_PAGE,
    PRODUCT_MAINTENANCE_PAGE,
    WORKFLOW_PAGE,
    FINDUSAGE_PAGE,
    USAGE_MAINTENANCE_PAGE,
    ARTIST_MAINTENANCE_PAGE,
    ARTISTS_PAGE,
    USAGE_POOLS_PAGE,
    USAGE_POOL_MAINTENANCE_PAGE,
    DISTRIBUTIONS_PAGE,
    DISTRIBUTION_MAINTENANCE_PAGE,
    REPRESENTATION_PAGE,
    CLAIM_MAINTENANCE_PAGE,
    CLAIMS_PAGE,
    WORK_GROUP_PAGE,
    ADJUSTMENTS_PAGE,
    ADJUSTMENT_MAINTENANCE_PAGE,
    PAYMENTRUN_PAGE,
    PAYMENTRUN_MAINTENANCE_PAGE,
    MY_PACKAGES_PAGE,
    LICENSE_PAGE,
    LICENSE_MAINTENANCE_PAGE
} from "../../Consts";
import WorksPageContainer from "../../works/WorksPageContainer";
import WorkMaintenancePageContainer from "../../works/components/workMaintenancePage/WorkMaintenancePageContainer";
import AgreementsPageContainer from "../../agreements/AgreementsPageContainer";
import AgreementMaintenancePageContainer from "../../agreements/components/AgreementMaintenancePage/AgreementMaintenancePageContainer";
import IPsPageContainer from "../../ips/IPsPageContainer";
import IPMaintenancePageContainer from "../../ips/components/ipMaintenancePage/IPMaintenancePageContainer";
import ProductsPageContainer from "../../products/ProductsPageContainer";
import ProductMaintenancePageContainer from "../../products/productMaintenancePage/ProductMaintenancePageContainer";
import WorkflowPageContainer from '../../workflows/WorkflowsPageContainer';
import UsageMaintenancePageContainer from "../../usage/components/usageMaintenancePage/UsageMaintenancePageContainer";
import FindUsagePageContainer from "../../usage/FindUsagePageContainer";
import ArtistMaintenancePageContainer from "../../artists/components/artistMaintenancePage/ArtistMaintenancePageContainer";
import ArtistsPageContainer from "../../artists/ArtistsPageContainer";
import PoolsPageContainer from "../../pools/PoolsPageContainer";
import PoolMaintenancePageContainer from "../../pools/components/poolMaintenancePage/PoolMaintenancePageContainer";
import DistributionsPageContainer from "../../distributions/DistributionsPageContainer";
import DistributionMaintenancePageContainer from "../../distributions/components/distributionMaintenancePage/DistributionMaintenancePageContainer";
import ClaimMaintenancePageContainer from "../../claims/components/claimMaintenancePage/ClaimMaintenancePageContainer";
import ClaimsPageContainer from "../../claims/ClaimsPageContainer";
import { displayRepresentationFlag } from "../../../redux/reducers/RepertoireReducer";
import IPRepresentationContainer from "../../ips/components/ipRepresentations/IPRepresentationContainer";
import WorkGroupPageContainer from "../../ips/components/ipRepresentations/WorkGroup/WorkGroupPageContainer";
import AdjustmentsPageContainer from "../../adjustments/AdjustmentsPageContainer";
import AdjustmentMaintenancePageContainer from "../../adjustments/components/adjustmentMaintenancePage/AdjustmentMaintenancePageContainer";
import PaymentRunPageContainer from "../../paymentruns/PaymentRunPageContainer";
import PaymentRunMaintenancePageContainer from "../../paymentruns/components/paymentRunMaintenancePage/PaymentRunMaintenancePageContainer";
import LicensesPageContainer from "../../licenses/LicensesPageContainer";
import LicenseMaintenancePageContainer from "../../licenses/components/licenseMaintenancePage/LicenseMaintenancePageContainer";
import { CLAIMS_HISTORY_PAGE, DRAFT_AGREEMENTS_MAINTENANCE_PAGE, DRAFT_AGREEMENTS_PAGE, DRAFT_SET_LISTS_PAGE, DRAFT_WORKS_MAINTENANCE_PAGE, DRAFT_WORKS_PAGE, LIVE_PERFORMANCE_SUBMISSION_PAGE, MEMBER_STATEMENTS_PAGE, UNPAID_CLAIMS_PAGE } from "../../../membersportal/Consts";
import DraftWorksPageContainer from "../../../membersportal/draftWorks/DraftWorksPageContainer";
import DraftWorksMaintenancePageContainer from "../../../membersportal/draftWorks/components/draftWorksMaintenancePage/DraftWorksMaintenancePageContainer";
import MemberStatementsPageContainer from "../../../membersportal/statements/MemberStatementsPageContainer";
import DraftSetListsPageContainer from "../../../membersportal/draftSetLists/DraftSetListsPageContainer";
import LivePerformanceSubmissionContainer from "../../../membersportal/livePerformanceSubmission/LivePerformanceSubmissionContainer";
import UnpaidClaimsMembersPageContainer from "../../../membersportal/unpaidClaims/UnpaidClaimsMembersPageContainer";
import ClaimsHistoryPageContainer from "../../../membersportal/claimsHistory/ClaimsHistoryPageContainer";
import DraftAgreementsPageContainer from "../../../membersportal/draftAgreements/DraftAgreementsPageContainer";
import DraftAgreementsMaintenancePageContainer from "../../../membersportal/draftAgreements/components/draftAgreementsMaintenancePage/DraftAgreementsMaintenancePageContainer";
import MyPackagesPageContainer from "../../myPackages/MyPackagesPageContainer"
interface ITabComponentProps {
    component: string;
    displayRepresentation: typeof displayRepresentationFlag;
}

const TabComponent: React.FC<ITabComponentProps> = ({ component }) => { 
    const components = {
        [WORKS_PAGE.component]: WorksPageContainer,
        [IPS_PAGE.component]: IPsPageContainer,
        [ARTISTS_PAGE.component]: ArtistsPageContainer,
        [AGREEMENTS_PAGE.component]: AgreementsPageContainer,
        [WORK_MAINTENANCE_PAGE.component]: WorkMaintenancePageContainer,
        [AGREEMENT_MAINTENANCE_PAGE.component]: AgreementMaintenancePageContainer,
        [IP_MAINTENANCE_PAGE.component]: IPMaintenancePageContainer,
        [ARTIST_MAINTENANCE_PAGE.component]: ArtistMaintenancePageContainer,
        [PRODUCTS_PAGE.component]: ProductsPageContainer,
        [PRODUCT_MAINTENANCE_PAGE.component]: ProductMaintenancePageContainer,
        [WORKFLOW_PAGE.component]: WorkflowPageContainer,
        [FINDUSAGE_PAGE.component]: FindUsagePageContainer,
        [USAGE_MAINTENANCE_PAGE.component]: UsageMaintenancePageContainer,
        [USAGE_POOLS_PAGE.component]: PoolsPageContainer,
        [USAGE_POOL_MAINTENANCE_PAGE.component]: PoolMaintenancePageContainer,
        [DISTRIBUTIONS_PAGE.component]: DistributionsPageContainer,
        [DISTRIBUTION_MAINTENANCE_PAGE.component]: DistributionMaintenancePageContainer,
        [REPRESENTATION_PAGE.component]: IPRepresentationContainer,
        [CLAIM_MAINTENANCE_PAGE.component]: ClaimMaintenancePageContainer,
        [CLAIMS_PAGE.component]: ClaimsPageContainer,
        [WORK_GROUP_PAGE.component]: WorkGroupPageContainer,
        [ADJUSTMENTS_PAGE.component]: AdjustmentsPageContainer,
        [ADJUSTMENT_MAINTENANCE_PAGE.component]: AdjustmentMaintenancePageContainer,
        [PAYMENTRUN_PAGE.component]: PaymentRunPageContainer,
        [PAYMENTRUN_MAINTENANCE_PAGE.component]: PaymentRunMaintenancePageContainer,
        [LICENSE_PAGE.component]: LicensesPageContainer,
        [LICENSE_MAINTENANCE_PAGE.component]: LicenseMaintenancePageContainer,
        [DRAFT_WORKS_PAGE.component]: DraftWorksPageContainer,
        [DRAFT_AGREEMENTS_PAGE.component]: DraftAgreementsPageContainer,
        [DRAFT_SET_LISTS_PAGE.component]: DraftSetListsPageContainer,
        [UNPAID_CLAIMS_PAGE.component]: UnpaidClaimsMembersPageContainer,
        [DRAFT_WORKS_MAINTENANCE_PAGE.component]: DraftWorksMaintenancePageContainer,
        [DRAFT_AGREEMENTS_MAINTENANCE_PAGE.component]: DraftAgreementsMaintenancePageContainer,
        [MEMBER_STATEMENTS_PAGE.component]: MemberStatementsPageContainer,
        [LIVE_PERFORMANCE_SUBMISSION_PAGE.component]: LivePerformanceSubmissionContainer,
        [CLAIMS_HISTORY_PAGE.component]: ClaimsHistoryPageContainer,  
        [MY_PACKAGES_PAGE.component]:MyPackagesPageContainer,
    };
    const TagName = components[component];
// @ts-ignore
    return <TagName />;
};

export default TabComponent;
