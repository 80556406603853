import axios from "axios";
import { IWorksSearchResult } from "../types/IWorksSearchResult";
import { IWorksSearchQuery } from "../types/IWorksSearchQuery";
import { IArtist } from "../types/IArtist";
import { IContributorSearchQuery } from "../types/IContributorSearchQuery";
import { IContributorSearchResult } from "../types/IContributorSearchResult";
import { IArtistSearchQuery } from "../types/IArtistSearchQuery";
import { IAgreementSearchQuery } from "../types/IAgreementSearchQuery";
import { IAgreementSearchResult } from "../types/IAgreementSearchResult";
import { IIPsSearchQuery } from "../types/IIPsSearchQuery";
import { IIPsSearchResult } from "../types/IIPsSearchResult";
import { EMPTY_STRING_VALUE, TEXT_READONLY_IN_REPERTOIRE_INPUT } from "../Consts";
import { IProductSearchQuery } from "../types/IProductSearchQuery";
import { IProductSearchResult } from "../types/IProductSearchResult";
import { IVersionHistorySearchResult } from "../types/IVersionHistorySearchResult";
import { IProductVersionHistorySearchResult } from "../types/IProductVersionHistorySearchResult";
import { ISocIPVersionHistorySearchResult } from "../types/ISocIPVersionHistorySearchResult";
import { forEach } from "lodash";
import { IWorkflowSearchQuery } from "../types/IWorkflowSearchQuery";
import { IWorkflowSearchResult } from "../types/IWorkflowSearchResult";
import { IWorkflow } from "../types/IWorkflow";
import { IUsagePool } from "../types/usageTypes/IUsagePool";
import { IUsagePoolSearchQuery } from "../types/usageTypes/IUsagePoolSearchQuery";
import { IDistributionSearchQuery } from "../types/usageTypes/IDistributionSearchQuery";
import { IDistribution } from "../types/usageTypes/IDistribution";
import { IAgreementVersionHistorySearchResult } from "../types/IAgreementVersionHistorySearchResult";
import { IUsagePoolVersionHistorySearchResult } from "../types/usageTypes/IUsagePoolVersionHistorySearchResult";
import { IAdjustmentSearchQuery } from "../types/usageTypes/IAdjustmentSearchQuery";
import { IAdjustment } from "../types/usageTypes/IAdjustment";
import { IClaimSearchResult } from "../../repertoire/types/IClaimSearchResult";
import { IClaimSearchQuery } from "../../repertoire/types/IClaimSearchQuery";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { AGREEMENT_CATEGORY_LOOKUP } from "../../lookup/Consts";
import { IWorkflowReferenceKeyValue } from "../types/IWorkflowReferenceKeyValue";
import { DataSource } from "../types/DataSource";
import { IPaymentRunSearchQuery } from "../types/IPaymentRunSearchQuery";
import { IPaymentRun } from "../types/usageTypes/IPaymentRun";
import { IPaymentRunVersionHistorySearchResult } from "../types/usageTypes/IPaymentRunVersionHistorySearchResult";
import { IAdjustmentVersionHistorySearchResult } from "../types/usageTypes/IAdjustmentVersionHistorySearchResult";
import { IShoppingCart } from "../types/IShoppingCart";
import { IShoppingCartSearchQuery } from "../types/IShoppingCartSearchQuery";
import { ILicenseUserSearchQuery } from "../types/ILicenseUserSearchQuery";
import { ILicenseUserSearchResult } from "../types/ILicenseUserSearchResult";
import { IWorkSubjectSearchResult } from "../types/IWorkSubjectSearchResult";
import { duration } from "moment";
import { ISubjectsSearchQuery } from "../types/ISubjectsSearchQuery";


export class SearchRequests {
    public static getWorks(
        searchQuery: IWorksSearchQuery
    ): Promise<IWorksSearchResult[]> {
        const {
            title,
            number,
            artist,
            writers,
            societyAccountNumber,
            dataSource,
            workBatchID,
            hasOpenWorkflow,
            workType,
            workGroupID
        } = searchQuery;

        return new Promise<IWorksSearchResult[]>((resolve, reject) => {
            axios
                .post("/search/works", {
                    title: {
                        value: title,
                        type: 0
                    },
                    number: {
                        value: number,
                        type: 0
                    },
                    artist: {
                        value: artist,
                        type: 0
                    },
                    contributor: {
                        value: writers,
                        type: 0
                    },
                    societyAccountNumber: {
                        value: societyAccountNumber,
                        type: 0
                    },
                    workBatchID: {
                        value: workBatchID,
                        type: 2
                    },
                    dataSource: dataSource,
                    workType: {
                        value: workType,
                        type: 2
                    },
                    hasOpenWorkflow: {
                        value: hasOpenWorkflow,
                        type: 2
                    },
                    workGroupID: {
                        value: workGroupID,
                        type: 0
                    }
                })
                .then(response => {
                    const workSearchResultItems: IWorksSearchResult[] = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const data = response.data[x];

                        let workSearchResultItem: IWorksSearchResult = {
                            title: [],
                            number: [],
                            writers: [],
                            publishers: [],
                            artists: [],
                            compoundType: data.medleyType ? data.medleyType : '',
                            workID: response.data[x].workID,
                            dataSource: dataSource,
                            duration: data.duration
                        }

                        for (let x = 0; x < 3; x++) {
                            if (data.workNames[x]) {
                                workSearchResultItem.title.push(data.workNames[x].name)
                            }

                            if (data.workNumbers[x]) {
                                workSearchResultItem.number.push(data.workNumbers[x].number)
                            }

                            if (data.performers[x]) {
                                workSearchResultItem.artists.push(data.performers[x].fullName)
                            }
                        }

                        if (data.contributors) {
                            for (let x = 0; x < data.contributors.length; x++) {
                                if (data.contributors[x].contributorType === 'Writer' && workSearchResultItem.writers.length < 3) {
                                    workSearchResultItem.writers.push(data.contributors[x].fullName)
                                }
                                if (data.contributors[x].contributorType === 'Publisher' && workSearchResultItem.publishers.length < 3) {
                                    workSearchResultItem.publishers.push(data.contributors[x].fullName)
                                }
                            }
                        }

                        workSearchResultItems.push(workSearchResultItem);
                    }

                    resolve(workSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }

    public static getSubjects(
        searchQuery: ISubjectsSearchQuery
    ): Promise<IWorkSubjectSearchResult[]> {
        const {
            subjectCode,
            description,
        } = searchQuery;

        return new Promise<IWorkSubjectSearchResult[]>((resolve, reject) => {
            axios
                .post("/search/subjects", {
                    workSubject: subjectCode,
                    description: description,
                    pagination: null,
                })
                .then(response => {
                    const workSubjectResultItems: IWorkSubjectSearchResult[] = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const data = response.data[x];

                        let workSearchResultItem: IWorkSubjectSearchResult = {
                            code: data.code,
                            description: data.description,
                        };

                        workSubjectResultItems.push(workSearchResultItem);
                    }

                    resolve(workSubjectResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }

    public static getWork(dataSource: string, workID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/work/" + dataSource + '/' + workID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getWorkHistoryVersion(workID: number, versionNumber: number, mergedWork: boolean, dataSource: DataSource): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get(`/work/WorkHistory/${workID}/${versionNumber}/${mergedWork}/${dataSource}`)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getAgreement(dataSource: string, agreementID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/agreement/" + dataSource + "/" + agreementID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getIP(ipBaseNumber?: string, accountNumber?: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (ipBaseNumber != null) {
                axios
                    .get("/interestedParty/IPBaseNumber/" + ipBaseNumber)
                    .then(response => {
                        if (response.status === 200) {
                            const result = response.data;
                            resolve(result);
                        }
                        else {
                            throw response;
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
            else if (accountNumber != null) {
                axios
                    .get("/interestedParty/Account/" + accountNumber)
                    .then(response => {
                        if (response.status === 200) {
                            const result = response.data;
                            resolve(result);
                        }
                        else {
                            throw response;
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
            else {
                reject(404);
            }
        });
    }

    public static getArtist(performerID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/performer/" + performerID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getProduct(dataSource: string, productCoreID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/product/" + dataSource + '/' + productCoreID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    
    public static getCueSheetEnabledProduct(id1: string, id2: string,originOfRevision:string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/product/" + id1 + '/' + id2 + '/' + originOfRevision)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    public static getProductVersionHistory(productCoreID: number): Promise<IProductVersionHistorySearchResult[]> {
        return new Promise<IProductVersionHistorySearchResult[]>((resolve, reject) => {
            axios
                .get("Search/ProductHistory/" + productCoreID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    public static getProductHistoryVersion(productCoreID: number, versionNumber: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/product/ProductHistory/" + productCoreID + '/' + versionNumber)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    };
    public static getSocIPVersionHistory(socIPID: number): Promise<ISocIPVersionHistorySearchResult[]> {
        return new Promise<ISocIPVersionHistorySearchResult[]>((resolve, reject) => {
            axios
                .get("Search/SocIPHistory/" + socIPID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    public static getSocIPHistoryVersion(socIPID: number, versionNumber: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/interestedParty/SocIPHistory/" + socIPID + '/' + versionNumber)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    };
    public static getAgreementVersionHistory(agreementID: number): Promise<IAgreementVersionHistorySearchResult[]> {
        return new Promise<IAgreementVersionHistorySearchResult[]>((resolve, reject) => {
            axios
                .get("Search/AgreementHistory/" + agreementID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    public static getAgreementHistoryVersion(agreementID: number, versionNumber: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/agreement/AgreementHistory/" + agreementID + '/' + versionNumber)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    public static getArtists(
        artist: IArtistSearchQuery,
        dataSource: string
    ): Promise<IArtist[]> {
        return new Promise<IArtist[]>((resolve, reject) => {
            axios
                .post("/search/artists", {
                    fullName: { value: artist.fullName, type: 0 },
                    firstName: { value: artist.firstName, type: 0 },
                    lastName: { value: artist.lastName, type: 0 },
                    number: { value: artist.number, type: 0 },
                    type: { value: artist.type, type: 0 },
                    dataSource: dataSource
                })
                .then(response => {
                    const artistSearchResultItems: IArtist[] = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const data = response.data[x];

                        const artistSearchResultItem: IArtist = {
                            fullName: data.fullName,
                            performerID: data.performerID,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            type: data.type,
                            number: data.number,
                            workPerformerID: data.workPerformerID,
                            performerNumberID: data.performerNumberID ? data.performerNumberID : 0,
                        };
                        artistSearchResultItems.push(artistSearchResultItem);
                    }
                    resolve(artistSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }

    public static getContributors(
        searchQuery: IContributorSearchQuery
    ): Promise<IContributorSearchResult[]> {
        const {
            ipNumber,
            fullName,
            accountNumber,
            legalEntityType,
            isMembersPortal
        } = searchQuery;
        return new Promise<IContributorSearchResult[]>((resolve, reject) => {
            axios
                .post("/search/contributors", {
                    ipiNumber: {
                        value: ipNumber && !isNaN(Number(ipNumber)) ? ipNumber : null,
                        type: 0
                    },
                    ipBaseNumber: {
                        value: ipNumber && !isNaN(Number(ipNumber)) ? "" : ipNumber,
                        type: 0
                    },
                    fullName: {
                        value: fullName,
                        type: 0
                    },
                    accountNumber: {
                        value: accountNumber,
                        type: 1
                    },
                    legalEntityType: {
                        value: legalEntityType,
                        type: 0
                    },
                    isMembersPortal
                })
                .then(response => {
                    const contributorSearchResultItems = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const contributorSearchResultItem = {
                            accountNumber: response.data[x].accountNumber,
                            accountName: response.data[x].accountName,
                            type: response.data[x].type,
                            ipiNumber: response.data[x].ipiNumber,
                            ipName: response.data[x].fullName,
                            ipBaseNumber: response.data[x].ipBaseNumber,
                            societyAffiliation: response.data[x].societyAffiliation,
                            legalEntityType: response.data[x].legalEntityType,
                            birthDate: response.data[x].birthDate !== null ? response.data[x].birthDate.substring(0, 10) : null,
                            societyOfLicense: response.data[x].defaultSocietyOfLicense
                        };

                        contributorSearchResultItems.push(contributorSearchResultItem);
                    }

                    resolve(contributorSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }

    public static getAgreements(
        searchQuery: IAgreementSearchQuery,
        lookupValues: ILookupDictionary
    ): Promise<IAgreementSearchResult[]> {
        const {
            ipBaseNumber,
            ipNameNumber,
            ipName,
            type,
            agreementReference,
            agreementCategory,
            dataSource,
            setTypeGroup,
            searchType,
            agreementBatchID
        } = searchQuery;
        return new Promise<IAgreementSearchResult[]>((resolve, reject) => {
            axios
                .post("/Search/Agreements", {
                    ipBaseNumber: ipBaseNumber,
                    ipNameNumber: parseInt(ipNameNumber),
                    ipName: ipName,
                    type: type,
                    agreementReference: agreementReference,
                    agreementCategory: agreementCategory,
                    setTypeGroup: setTypeGroup,
                    dataSource: dataSource,
                    pagination: null,
                    searchType: searchType,
                    agreementBatchID: agreementBatchID
                })
                .then(response => {
                    const agreementSearchResultItems = [];
                    let lookup = lookupValues[AGREEMENT_CATEGORY_LOOKUP].lookups;

                    for (let x = 0; x < response.data.length; x++) {
                        const agreementSearchResultItem = {
                            ipNameNumber: response.data[x].ipNameNumber,
                            ipBaseNumber: response.data[x].ipBaseNumber,
                            ipName: response.data[x].ipName,
                            agreementType: (response.data[x].type == 1 ? 'General' : 'Specific'),
                            agreementReference: response.data[x].agreementReference,
                            agreementCategory: lookup.filter(l => l.code === response.data[x].agreementCategory).length > 0 ? lookup.filter(l => l.code === response.data[x].agreementCategory)[0].description : '',
                            region: response.data[x].setTypeGroup,
                            effectiveFrom: response.data[x].effectiveFrom,
                            effectiveTo: response.data[x].effectiveTo,
                            maintained: response.data[x].maintained,
                            source: response.data[x].source,
                            agreementID: response.data[x].agreementID,
                            dataSource: dataSource,
                            worldExcluding: response.data[x].worldExcluding
                        };

                        agreementSearchResultItems.push(agreementSearchResultItem);
                    }
                    resolve(agreementSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        })
    }

    public static getIPs(
        searchQuery: IIPsSearchQuery
    ): Promise<IIPsSearchResult[]> {
        const {
            ipNumber,
            fullName,
            accountNumber,
            interestedPartyType,
            count,
            skip
        } = searchQuery;
        return new Promise<IIPsSearchResult[]>((resolve, reject) => {
            axios
                .post("/Search/IPs", {
                    ipiNumber: {
                        value: ipNumber && !isNaN(Number(ipNumber)) ? ipNumber : null,
                        type: 0
                    },
                    fullName: {
                        value: fullName,
                        type: 0
                    },
                    ipBaseNumber: {
                        value: ipNumber && !isNaN(Number(ipNumber)) ? "" : ipNumber,
                        type: 0
                    },
                    accountNumber: {
                        value: accountNumber,
                        type: 1
                    },
                    replacedAccounts: [{
                        fieldName: "ReplacedAccount",
                        filterFields: [{
                            fieldName: "Number",
                            values: {
                                value: accountNumber,
                                type: 3
                            }
                        }]
                    }],
                    interestedPartyType: interestedPartyType,
                    pagination: {
                      count: count,
                      skip: skip
                    }
                })
                .then(response => {
                    const ipSearchResultItems = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const ipSearchResultItem = {
                            ipNameNumber: response.data[x].ipiNumber,
                            ipBaseNumber: response.data[x].ipBaseNumber,
                            ipName: response.data[x].fullName,
                            ipType: response.data[x].type,
                            accountNumber: response.data[x].accountNumber,
                            societyAffiliation: response.data[x].societyAffiliation,
                            legalEntityType: response.data[x].legalEntityType,
                            accountName:response.data[x].accountName,
                            birthDate: response.data[x].birthDate !== null ? response.data[x].birthDate.substring(0, 10) : null,
                            isLibraryPublisher: response.data[x].isLibraryPublisher !== null ? response.data[x].isLibraryPublisher : null
                        };

                        ipSearchResultItems.push(ipSearchResultItem);
                    }
                    resolve(ipSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        })
    }

    public static getWorkVersionHistory(workID: number, dataSource: DataSource): Promise<IVersionHistorySearchResult[]> {
        return new Promise<IVersionHistorySearchResult[]>((resolve, reject) => {
            axios
                .get("Search/WorkHistory/" + workID + '/' + dataSource)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getProducts(
        searchQuery: IProductSearchQuery
    ): Promise<IProductSearchResult[]> {
        const {
            productIDs,
            productType,
            title,
            number,
            contributor,
            artist,
            productBatchID,
            dataSource,
            hasOpenWorkflow,
            recordCompany,
            productSource
        } = searchQuery;

        return new Promise<IProductSearchResult[]>((resolve, reject) => {
            axios
                .post("/Search/Products", {
                    productIDs: {
                        values: productIDs.map(id => { return { value: id, type: 2 } })
                    },
                    type: {
                        value: productType,
                        type: 1
                    },
                    title: {
                        value: title,
                        type: 0
                    },
                    number: {
                        value: number,
                        type: 0
                    },
                    contributor: {
                        value: contributor,
                        type: 0
                    },
                    artist: {
                        value: artist,
                        type: 0
                    },
                    productBatchID: {
                        value: productBatchID,
                        type: 2
                    },
                    hasOpenWorkflow: {
                        value: hasOpenWorkflow,
                        type: 2
                    },
                    dataSource: dataSource,
                    recordCompany: {
                        value: recordCompany,
                        type: 0
                    },
                    productSource: {
                        value: productSource,
                        type: 2
                    },
                })
                .then(response => {
                    const productSearchResultItems: IProductSearchResult[] = [];
                    for (let x = 0; x < response.data.length; x++) {
                        const data = response.data[x];

                        let productSearchResultItem: IProductSearchResult = {
                            productCoreID: response.data[x].productCoreID,
                            dataSource: dataSource,
                            type: response.data[x].productType,
                            id1: response.data[x].id1 ?? '',
                            id2: response.data[x].id2 ?? '',
                            number: [],
                            title: [],
                            source: response.data[x].source,
                            artist: [],
                            contributor: [],
                            originOfRevision:response.data[x].originOfRevision?response.data[x].originOfRevision:null,
                            productionType: response.data[x].productionType ?? '',
                            musicDuration: response.data[x].musicDuration ? [response.data[x].musicDuration] : []
                        }

                        for (let x = 0; x < 3; x++) {
                            if (data.productNames[x]) {
                                var type = data.productNames[x].productNameType
                                productSearchResultItem.title.push(data.productNames[x].name + " (" + type + ")")
                            }

                            if (data.productPerformers[x]) {
                                productSearchResultItem.artist.push(data.productPerformers[x].performerName)
                            }

                            if (data.productNumbers[x]) {
                                productSearchResultItem.number.push(data.productNumbers[x].number)
                            }

                            if (data.productContributors[x]) {
                                productSearchResultItem.contributor.push(data.productContributors[x].name)
                            }
                        }

                        productSearchResultItems.push(productSearchResultItem);
                    }
                    resolve(productSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        })
    }

    public static getConfigurationParameter(key: string, group: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/Component/ConfigurationParameter/" + key + "/" + group)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getWorkflows(
        searchQuery: IWorkflowSearchQuery
    ): Promise<IWorkflowSearchResult[]> {
        const {
            assignedTo,
            entityType,
            status,
            workflowType,
            referenceKeyValues,
            valueKey,
            valuesMax,
            valuesMin,
            fromDateCreated,
            toDateCreated
        } = searchQuery;
        return new Promise<IWorkflowSearchResult[]>((resolve, reject) => {
            axios
                .post("/Search/Workflows", {
                    entityType: { type: 2, value: entityType },
                    status: { type: 2, value: status },
                    workflowType: { type: 2, value: workflowType },
                    assignedTo: { type: 2, value: assignedTo },
                    pagination: null,
                    referenceKeyValues: this.mapReferenceKeyValues(referenceKeyValues),
                    valueKey: { type: 2, value: valueKey },
                    valuesMax: { type: 2, value: valuesMax },
                    valuesMin: { type: 2, value: valuesMin },
                    fromDateCreated: { type: 2, value: fromDateCreated },
                    toDateCreated: { type: 2, value: toDateCreated }
                })
                .then(response => {
                    const workflowSearchResultItems: IWorkflowSearchResult[] = [];

                    const data = response.data;

                    for (let x = 0; x < data.length; x++) {
                        const workflowSearchResultItem = {
                            assignedTo: !data[x].assignedTo ? EMPTY_STRING_VALUE : data[x].assignedTo,
                            entityType: !data[x].entityType ? EMPTY_STRING_VALUE : data[x].entityType,
                            references: Object.keys(data[x]?.references ?? {}).length === 0 ? EMPTY_STRING_VALUE : JSON.stringify(data[x]?.references).replace(/"/g, ''),
                            status: !data[x].status ? EMPTY_STRING_VALUE : data[x].status,
                            workflowType: !data[x].workflowType ? EMPTY_STRING_VALUE : data[x].workflowType,
                            values: Object.keys(data[x]?.values ?? {}).length === 0 ? EMPTY_STRING_VALUE : JSON.stringify(data[x]?.values).replace(/"/g, ''),
                            setType: !data[x].setType ? EMPTY_STRING_VALUE : data[x].setType,
                            entityID: !data[x].entityID ? EMPTY_STRING_VALUE : data[x].entityID,
                            selected: false,
                            dateCreated: new Date(data[x].dateCreated).toLocaleDateString('en-GB'),
                            ID: !data[x].id ? EMPTY_STRING_VALUE : data[x].id.toString()
                        };

                        workflowSearchResultItems.push(workflowSearchResultItem);
                    }
                    resolve(workflowSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        })
    }

    static mapReferenceKeyValues = (referenceKeyValues: IWorkflowReferenceKeyValue[]) => {
        return referenceKeyValues.map((item) => ({ referenceKey: { type: 2, value: item.referenceKey }, referenceValue: { type: 2, value: item.referenceValue } }));
    }

    public static getUsagePools(searchQuery: IUsagePoolSearchQuery): Promise<IUsagePool[]> {
        const {
            poolCode,
            cat1,
            cat2,
            allocationRule
        } = searchQuery;
        return new Promise<IUsagePool[]>((resolve, reject) => {
            axios
                .post("/Search/UsagePools", {
                    poolCode: poolCode,
                    cat1: cat1,
                    cat2: cat2,
                    allocationRule: allocationRule
                })
                .then(response => {
                    const usagePoolSearchResultItems: IUsagePool[] = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const data = response.data[x];
                        const usagePoolSearchResultItem: IUsagePool = {
                            poolCode: data.poolCode,
                            description: data.description,
                            cat1: data.cat1,
                            cat2: data.cat2,
                            allocationRule: data.allocationRule,
                            invoice: data.invoice ? 'Yes' : 'No',
                            licenseeID: data.licenseeID,
                            poolId: data.poolID,
                            sources: []
                        };
                        usagePoolSearchResultItems.push(usagePoolSearchResultItem);
                    }
                    resolve(usagePoolSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                })
        });
    }

    public static getUsagePool(poolId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/usagePool/" + poolId)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    public static getPoolVersionHistory(poolID: number): Promise<IUsagePoolVersionHistorySearchResult[]> {
        return new Promise<IUsagePoolVersionHistorySearchResult[]>((resolve, reject) => {
            axios
                .get("Search/PoolHistory/" + poolID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getPoolHistoryVersion(poolID: number, versionNumber: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/usagePool/PoolHistory/" + poolID + '/' + versionNumber)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getDistributions(searchQuery: IDistributionSearchQuery): Promise<IDistribution[]> {
        const {
            year,
            distributionType,
            distributionStatus,
            distributionCode,
            cat1,
            cat2
        } = searchQuery;
        return new Promise<IDistribution[]>((resolve, reject) => {
            axios
                .post("/Search/Distributions", {
                    year: year,
                    type: distributionType ? (distributionType.split(" ")).join("") : distributionType,
                    status: distributionStatus,
                    code: distributionCode,
                    cat1: cat1,
                    cat2: cat2
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(res => {
                    reject(res);
                })
        });
    }

    public static getAdjustments(searchQuery: IAdjustmentSearchQuery, dataSource: string): Promise<IAdjustment[]> {
        const {
            id,
            adjustmentDateFrom,
            adjustmentDateTo,
            user,
            adjustmentCategory,
            adjustmentStatus,
            sourceDistribution,
            adjustmentDistribution,
            sourcePool,
            adjustmentBatchID
        } = searchQuery;
        return new Promise<IAdjustment[]>((resolve, reject) => {
            axios
                .post("/Search/Adjustments", {
                    id: {
                        value: id,
                        type: 3
                    },
                    sourceDistribution: {
                        value: sourceDistribution,
                        type: 0
                    },
                    sourcePool: {
                        value: sourcePool,
                        type: 0
                    },
                    adjustmentDistribution: {
                        value: adjustmentDistribution,
                        type: 0
                    },
                    adjustmentDateFrom: {
                        value: adjustmentDateFrom,
                        type: 0
                    },
                    adjustmentDateTo: {
                        value: adjustmentDateTo,
                        type: 0
                    },
                    user: {
                        value: user,
                        type: 0
                    },
                    adjustmentCategory: {
                        value: adjustmentCategory,
                        type: 0
                    },
                    adjustmentStatus: {
                        value: adjustmentStatus,
                        type: 0
                    },
                    dataSource: {
                        value: dataSource,
                        type: 0
                    },
                    adjustmentBatchID: {
                        value: adjustmentBatchID,
                        type: 2
                    }
                })
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    public static getDistributionsTypes(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/Distribution/DistributionTypes/")
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    public static getDistributionSubTypes(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/Distribution/DistributionSubTypes/")
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }


    public static getDistribution(distributionId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/Distribution/" + distributionId)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    public static getDistributionVersionHistory(distributionID: number): Promise<IVersionHistorySearchResult[]> {
        return new Promise<IVersionHistorySearchResult[]>((resolve, reject) => {
            axios
                .get("Search/DistributionHistory/" + distributionID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getDistributionHistoryVersion(distributionID: number, versionNumber: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/distribution/DistributionHistory/" + distributionID + '/' + versionNumber)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getPaymentRunVersionHistory(paymentRunID: number): Promise<IPaymentRunVersionHistorySearchResult[]> {
        return new Promise<IPaymentRunVersionHistorySearchResult[]>((resolve, reject) => {
            axios
                .get("Search/PaymentRunHistory/" + paymentRunID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getPaymentRunHistoryVersion(paymentRunID: number, versionNumber: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/paymentRun/PaymentRunHistory/" + paymentRunID + '/' + versionNumber)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getAdjustmentVersionHistory(adjustmentID: string): Promise<IAdjustmentVersionHistorySearchResult[]> {
        return new Promise<IAdjustmentVersionHistorySearchResult[]>((resolve, reject) => {
            axios
                .get("Search/AdjustmentHistory/" + adjustmentID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getAdjustmentHistoryVersion(adjustmentID: string, versionNumber: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/adjustment/AdjustmentHistory/" + adjustmentID + '/' + versionNumber)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getClaims(
        searchQuery: IClaimSearchQuery
    ): Promise<IClaimSearchResult[]> {
        const {
            ipiNumber,
            accountNumber,
            claimCode,
            claimStatus,
            fullName
        } = searchQuery;
        return new Promise<IClaimSearchResult[]>((resolve, reject) => {
            axios
                .post("/search/claims", {
                    ipiNumber: {
                        value: ipiNumber,
                        type: 0
                    },
                    accountNumber: {
                        value: accountNumber,
                        type: 0
                    },
                    claimCode: {
                        value: claimCode,
                        type: 0
                    },
                    claimStatus: {
                        value: claimStatus,
                        type: 0
                    },
                    fullName: {
                        value: fullName,
                        type: 0
                    }
                })
                .then(response => {
                    const claimSearchResultItems: IClaimSearchResult[] = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const data = response.data[x];

                        let claimSearchResultItem: IClaimSearchResult = {
                            claimID: data.claimID,
                            claimDate: data.claimDate,
                            claimStatus: data.claimStatus,
                            claimCode: data.claimCode,
                            ipBaseNumber: data.ipBaseNumber,
                            ipName: data.ipName,
                            origin: data.origin,
                            ResolutionDate: data.ResolutionDate,
                            ipNameNumber: data.ipNameNumber
                        }
                        claimSearchResultItems.push(claimSearchResultItem);
                    }

                    resolve(claimSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }

    public static getClaim(claimID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/claim/" + claimID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getAdjustment(adjustmentId: string): Promise<IAdjustment> {
        return new Promise<IAdjustment>((resolve, reject) => {
            axios
                .get("/Adjustment/" + adjustmentId)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    public static getMatchConnectorStatus(workID: number): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            axios
                .get(`/work/MatchConnectorStatus/${workID}`)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    public static getPaymentRuns(searchQuery: IPaymentRunSearchQuery): Promise<IPaymentRun[]> {
        const {
            paymentRunID,
            type,
            paymentRunDateFrom,
            paymentRunDateTo,
            status,
        } = searchQuery;
        return new Promise<IPaymentRun[]>((resolve, reject) => {
            axios
                .post("/Search/PaymentRuns", {
                    paymentRunID: paymentRunID,
                    status: status,
                    type: type,
                    paymentRunDateFrom: paymentRunDateFrom,
                    paymentRunDateTo: paymentRunDateTo
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(res => {
                    reject(res.message);
                })
        });
    }

    public static getPaymentRun(paymentRunId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/PaymentRun/" + paymentRunId)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    public static getLicenses(searchQuery: IShoppingCartSearchQuery): Promise<IShoppingCart[]> {
        const {
            shoppingCartID,
        } = searchQuery;
        return new Promise<IShoppingCart[]>((resolve, reject) => {
            axios
                .post("/Search/Licenses", {
                    shoppingCartID: shoppingCartID,
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(res => {
                    reject(res.message);
                })
        });
    }

    public static getLicense(licenseId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/License/" + licenseId)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    public static getLicenseUser(
        searchQuery: ILicenseUserSearchQuery
    ): Promise<ILicenseUserSearchResult[]> {
        const {
            email
        } = searchQuery;
        return new Promise<ILicenseUserSearchResult[]>((resolve, reject) => {
            axios
                .post("/search/licenseuser", {
                    email: email
                })
                .then(response => {
                    const licenseUserSearchResultItems = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const licenseUserSearchResultItem = {
                            name: response.data[x].name,
                            address: response.data[x].address,
                            city: response.data[x].city,
                            postalCode: response.data[x].postalCode,
                            country: response.data[x].country,
                            compositeAddress: response.data[x].compositeAddress,
                            phone: response.data[x].phone,
                            email: response.data[x].email,
                            nameGuid: response.data[x].nameGuid,
                            addressGuid: response.data[x].addressGuid
                        };

                        licenseUserSearchResultItems.push(licenseUserSearchResultItem);
                    }

                    resolve(licenseUserSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }
    static GetAllPackages(userName: string): Promise<any> {
        const url: string = `Package/GetAllPackages/${userName}`;
        return new Promise<any>((resolve, reject) => {
            axios
                .get(url)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }
}
