import * as React from "react";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { TITLE_STATE_KEY, IP_NAME_NUMBER_STATE_KEY, SEARCH_VIEW_WORKS, SEARCH_VIEW_AGREEMENTS, SEARCH_VIEW_IPS, UPDATE_ACTION_STATE_KEY, SEARCH_VIEW_PRODUCTS, PRODUCT_SEARCH_TYPE_STATE_KEY, SEARCH_VIEW_WORKFLOW, SEARCH_VIEW_ARTISTS, TYPE_STATE_KEY, SEARCH_VIEW_POOLS, SEARCH_VIEW_DISTRIBUTIONS, POOL_LICENSEE_ID_STATE_KEY, DISTRIBUTION_ID_STATE_KEY, SEARCH_VIEW_ADJUSTMENTS, ADJUSTMENT_ID_STATE_KEY, DISTRIBUTION_CLAIMS_FROM_STATE_KEY, DISTRIBUTION_CLAIMS_TO_STATE_KEY, DISTRIBUTION_DATE_STATE_KEY, DISTRIBUTION_USAGE_FROM_STATE_KEY, DISTRIBUTION_USAGE_TO_STATE_KEY, SEARCH_VIEW_PAYMENTRUN, PAYMENTRUN_CODE_STATE_KEY, PAYMENTRUN_PAYMENTRUN_DATE_STATE_KEY, SEARCH_VIEW_LICENSES, LICENSE_ID_STATE_KEY, SEARCH_VIEW_CLAIMS, CLAIM_CODE_STATE_KEY, POOL_POOL_CODE_STATE_KEY, DISTRIBUTION_CODE_STATE_KEY, INTRAY, ADJUSTMENT_DATE_STATE_KEY, USAGE_GROUP_DISTRIBUTION_DATE_STATE_KEY, AGREEMENT_LAST_MODIFIED_DATE_STATE_KEY, IP_EFFECTIVE_FROM_KEY, IP_EFFECTIVE_TO_KEY, ADD_TO_PACKAGE_ACTION, REGION_STATE_KEY, PRODUCT_SEARCH_MUSIC_DURATION_KEY } from "../../Consts";
import { RepertoireHelper } from "../../services/RepertoireHelper";
import { DRAFT_WORKS_RESULTS_TITLE_STATE_KEY, SEARCH_VIEW_DRAFT_SET_LISTS, SEARCH_VIEW_DRAFT_WORKS, SEARCH_VIEW_LIVE_PERFORMANCE_SUBMISSION } from "../../../membersportal/Consts";
import { ILicenseInputItem } from "../../../redux/types/ILicenseInputItem";
import { FormatFields } from "../../../redux/types/FormatFields";
import DateDisplay, { YEAR_DAY_MONTH_DATE_FORMAT } from "../dateDisplay/DateDisplay";
import IconTextButton from "../iconTextButton/IconTextButton";
import { DataSource } from "../../types/DataSource";
import { EntityType } from "../../types/EntityType";
import { convertSecondsToTimeStamp } from "../../../util/tools";

export interface ISearchResultsTableSectionProps {
    tableContentItem: any;
    objectKey: string;
    index?: number;
    getWorkDetails?: (dataSource: string, workID) => void;
    getAgreementDetails?: (dataSource: string, agreementID) => void;
    getIPDetails?: (ipBaseNumber?: string, accountNumbeR?: string) => void;
    getProductDetails?: (dataSource: string, productCoreID: number, isForWorkflowSession?: boolean, openNewTab?: boolean, id1?: string, id2?: string, type?: string, originOfRevision?: string, productionType?: string, musicDuration?: number[]) => void;
    getArtistDetails?: (performerID: number) => void;
    getUsagePoolDetails?: (poolId: number) => void;
    lookupValues?: ILookupDictionary
    getDistributionDetails?: (distributionID: number, lookupValues: ILookupDictionary) => void;
    componentInstance: string;
    expanded?: boolean;
    expandResult?: (result: number) => void;
    renderExpandRow?: boolean;
    getUsageDetails?: (usageID: string) => void;
    getAdjustmentDetails?: (adjustmentId: string) => void;
    getPaymentRunDetails?: (paymentRunID: number, lookupValues: ILookupDictionary) => void;
    getLicenseDetails?: (licenseID: number, lookupValues: ILookupDictionary, licenseRequestWorkParameterFields: ILicenseInputItem[]) => void;
    getDraftWorkDetails?: (dataSource: string, workId: number) => void;
    getDraftSetListDetails?: (dataSource: string, productCoreId: number) => void;
    getMemberStatementDetails?: (paymentID: string, lookupValues: ILookupDictionary, formats?: FormatFields[]) => void;
    getLivePerformanceDetails?: (usageId: string) => void;
    getClaimDetails?: (claimID: number, lookupValues: ILookupDictionary) => void;
    addToPackages?: (userName: string, entityType: string, entityID: number) => void;
    currentUser?: string;
}

interface ISearchResultsTableSectionState {
    iconAdded: boolean;
    showMessage: boolean;
}
export default class SearchResultsTableSection extends React.PureComponent<
    ISearchResultsTableSectionProps,
    ISearchResultsTableSectionState
> {
    constructor(props: ISearchResultsTableSectionProps) {
        super(props);
        this.state = {
            iconAdded: false,
            showMessage: false,
        };
    }

    onClickExternalIcon = () => {
        const {
            getWorkDetails,
            getAgreementDetails,
            getIPDetails,
            getProductDetails,
            getArtistDetails,
            lookupValues,
            getUsagePoolDetails,
            getDistributionDetails,
            componentInstance,
            tableContentItem: { id1, id2, type, originOfRevision, entityType, dataSource, workID, agreementID, ipBaseNumber, accountNumber, productCoreID, performerID, entityID, setType, poolId, distributionID, id, paymentRunID, shoppingCartID, claimID, productionType, musicDuration },
            getUsageDetails,
            getAdjustmentDetails,
            getPaymentRunDetails,
            getLicenseDetails,
            getDraftSetListDetails,
            getDraftWorkDetails,
            getLivePerformanceDetails,
            getClaimDetails
        } = this.props;
        switch (componentInstance) {
            case SEARCH_VIEW_WORKS:
                getWorkDetails(dataSource, workID)
                break;
            case SEARCH_VIEW_AGREEMENTS:
                getAgreementDetails(dataSource, agreementID)
                break;
            case SEARCH_VIEW_IPS:
                getIPDetails(ipBaseNumber, accountNumber)
                break;
            case SEARCH_VIEW_PRODUCTS:
                getProductDetails(dataSource, productCoreID, false, false, id1, id2, type, originOfRevision, productionType, musicDuration)
                break;
            case SEARCH_VIEW_WORKFLOW:
                if (entityType == "Usage (Work Based)" || entityType == "Usage (Product Based)" || entityType == "Usage (Live Performance)" || entityType == "Usage (Unidentified Performance)")
                    getUsageDetails(entityID);
                else if (entityType === "Intray Product")
                    getProductDetails(setType, entityID);
                else if (entityType === "Intray Agreement")
                    getAgreementDetails(INTRAY, entityID);
                else
                    getWorkDetails(setType, entityID);
                break;
            case SEARCH_VIEW_ARTISTS:
                getArtistDetails(performerID);
                break;
            case SEARCH_VIEW_POOLS:
                getUsagePoolDetails(poolId);
                break;
            case SEARCH_VIEW_DISTRIBUTIONS:
                getDistributionDetails(distributionID, lookupValues);
                break;
            case SEARCH_VIEW_ADJUSTMENTS:
                getAdjustmentDetails(id);
                break
            case SEARCH_VIEW_PAYMENTRUN:
                getPaymentRunDetails(paymentRunID, lookupValues);
                break
            case SEARCH_VIEW_LICENSES:
                getLicenseDetails(shoppingCartID, lookupValues, []);
                break
            case SEARCH_VIEW_DRAFT_WORKS:
                getDraftWorkDetails(dataSource, workID);
                break
            case SEARCH_VIEW_DRAFT_SET_LISTS:
                getDraftSetListDetails(dataSource, productCoreID);
                break;
            case SEARCH_VIEW_LIVE_PERFORMANCE_SUBMISSION:
                getLivePerformanceDetails(id)
                break;
            case SEARCH_VIEW_CLAIMS:
                getClaimDetails(claimID, lookupValues);
                break;
            default:
                break;
        }
    };

    renderMultipleTitlesDiv = (array: string[], objectKey?: string) => {
        return (
            <div className="multipleTitlesDiv">
                {array.map((item, index) => (
                    <>
                        {(objectKey === TITLE_STATE_KEY && item) ? <div key={index}>
                            <a onClick={this.onClickExternalIcon}>
                                <div>
                                    {item}
                                    {index + 1 !== array.length ? <span>,</span> : null}
                                </div>
                            </a>

                        </div> : <>
                            <div key={index}>
                                <div>
                                    {item}
                                    {index + 1 !== array.length ? <span>,</span> : null}
                                </div>

                            </div>
                        </>}
                    </>
                ))}
            </div>
        );
    };

    excludeObjectKeys = (key: string) => {
        const excludedKeys = ['compoundType', 'type', 'id1', 'id2', 'source', 'musicDuration', 'productionType', 'productType'];

        if (excludedKeys.includes(key)) {
            return false;
        } else {
            return true;
        }
    }
    renderaddforpackagesstyle = () => {
        return (

            <style>{`
                        .ms-Icon--CheckMark::before{
                           color: #008f59; 
                           border: 1.5px solid #008f59;
                           border-radius: 4px;
                           background-color: #F6F7FA;
                           font-weight: bold;
                           font-size: 12px; 
                           padding: 1px;
                        }`
            }
            </style>
        )
    }
    addToPackages = (entityType: string) => {
        const {
            addToPackages,
            lookupValues,
            componentInstance,
            currentUser,
            tableContentItem: { id1, id2, type, originOfRevision, dataSource, workID, agreementID, ipBaseNumber, accountNumber, productCoreID, performerID, entityID, setType, poolId, distributionID, id, paymentRunID, shoppingCartID, claimID, productionType, musicDuration },
        } = this.props;

        console.log(currentUser, entityType);
        if (entityType === EntityType.Work) {
            addToPackages(currentUser, entityType, workID)
        } else if (entityType === EntityType.Products) {
            addToPackages(currentUser, entityType, productCoreID)
        } else if (entityType === EntityType.Agreements) {
            addToPackages(currentUser, entityType, agreementID)
        }

        this.setState(prevState => ({
            iconAdded: !prevState.iconAdded,
            showMessage: true,
        }));

        setTimeout(() => {
            this.setState({ showMessage: false });
        }, 2000);
    }

    renderTitleDiv = () => {
        const { tableContentItem, objectKey, index, componentInstance, renderExpandRow, expandResult, expanded, lookupValues } = this.props;
        const { iconAdded, showMessage } = this.state;
        const { tableContentItem: { dataSource }, } = this.props;
        if (objectKey === IP_NAME_NUMBER_STATE_KEY) {
            tableContentItem[objectKey] = RepertoireHelper.zeroPaddedIPINameNumber(tableContentItem[objectKey]);
        }

        if (objectKey === "sourcePool" && Array.isArray(tableContentItem[objectKey])) {
            tableContentItem[objectKey] = tableContentItem[objectKey].join(', ')
        }

        if (componentInstance === "works") {
            if (objectKey !== UPDATE_ACTION_STATE_KEY) {
                let item;
                    if (!this.excludeObjectKeys(objectKey)) {
                        item = tableContentItem[objectKey];
                    } else {
                        item = tableContentItem[objectKey] !== undefined ? tableContentItem[objectKey][0] : null;
                    }

                return (
                    <>
                        <div className="arrowIconDiv">
                            {renderExpandRow && (
                                <img
                                    src="assets/right-arrow.svg"
                                    title="Expand title"
                                    alt="Right Arrow icon"
                                    className={expanded ? "arrowIconExpanded" : "arrowIcon"}
                                    onClick={() => expandResult(index)}
                                />
                            )}
                            {expanded && (objectKey !== ADD_TO_PACKAGE_ACTION) && (this.excludeObjectKeys(objectKey) && tableContentItem[objectKey].length > 1) ? (
                                this.renderMultipleTitlesDiv(tableContentItem[objectKey], objectKey)
                            ) : (
                                <div className="workTitle">

                                    {(objectKey === TITLE_STATE_KEY && item) ? (<a onClick={this.onClickExternalIcon}>{item.replace(/,\s*$/, "")}</a>) : (item === "" && objectKey === TITLE_STATE_KEY) ? "Title not available" : item}
                                </div>
                            )}</div>
                        {(objectKey === ADD_TO_PACKAGE_ACTION && dataSource === DataSource.Repertoire &&
                        <div className="tableCell specialColumn" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {this.renderaddforpackagesstyle()}
                                <div className="tableCell specialColumn">
                                    {showMessage && (
                                        <div className="messageBox">Work added to package</div>
                                    )}
                                    <div className="workAddtoPackage">
                                        <IconTextButton
                                            icon={iconAdded ? "icon ms-Icon ms-Icon--CheckMark" : "icon ms-Icon ms-Icon--AddIn"}
                                            onClick={() => this.addToPackages(EntityType.Work)}
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                        </div>
                    )}
                    </>
                );
            } else {
                return (<div></div>);
            }
        }
        else if (componentInstance === null) {
            return (
                <div className="tableCel">
                    {tableContentItem[objectKey]}
                </div>
            );
        }
        else if (componentInstance === "products") {
            if (objectKey !== UPDATE_ACTION_STATE_KEY) {
                let item;
                if (!this.excludeObjectKeys(objectKey)) {
                    if (objectKey === PRODUCT_SEARCH_MUSIC_DURATION_KEY) {
                        item = tableContentItem[objectKey][0] ? convertSecondsToTimeStamp(tableContentItem[objectKey][0]) : '';
                    } else {
                        item = tableContentItem[objectKey];
                    }
                }
                else {
                    item = tableContentItem[objectKey] !== undefined ? tableContentItem[objectKey][0] : null;
                }

                return (
                    <>
                        <div className="arrowIconDiv">
                            {renderExpandRow && (
                                <img
                                    src="assets/right-arrow.svg"
                                    title="Expand title"
                                    alt="Right Arrow icon"
                                    className={expanded ? "arrowIconExpanded" : "arrowIcon"}
                                    onClick={() => expandResult(index)}
                                />
                            )}
                            {expanded && (this.excludeObjectKeys(objectKey) && (objectKey !== ADD_TO_PACKAGE_ACTION) && tableContentItem[objectKey].length > 1) ? (
                                this.renderMultipleTitlesDiv(tableContentItem[objectKey], objectKey)
                            ) : (
                                <div className="workTitle">

                                    {(objectKey === TITLE_STATE_KEY && item) ? (<a onClick={this.onClickExternalIcon}>{item.replace(/,\s*$/, "")}</a>) : (item === "" && objectKey === TITLE_STATE_KEY) ? "Title not available" : item}
                                </div>
                            )}
                        </div>
                    {(objectKey === ADD_TO_PACKAGE_ACTION && dataSource === DataSource.Repertoire &&
                        <div className="tableCell specialColumn" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {this.renderaddforpackagesstyle()}
                                <div className="tableCell specialColumn">
                                    {showMessage && (
                                        <div className="messageBox">Product added to package</div>
                                    )}
                                    <div className="productsAddtoPackage">
                                        <IconTextButton
                                            icon={iconAdded ? "icon ms-Icon ms-Icon--CheckMark" : "icon ms-Icon ms-Icon--AddIn"}
                                            onClick={() => this.addToPackages(EntityType.Products)}
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                        </div>
                      )}
                    </>
                );
            } else {
                return (<div></div>);
            }
        } else if (componentInstance === SEARCH_VIEW_WORKFLOW) {
            if (objectKey === UPDATE_ACTION_STATE_KEY) {
                return (
                    <div className="IconTextButton">
                        <span className="icon ms-Icon ms-Icon--OpenInNewTab" onClick={this.onClickExternalIcon} />
                    </div>
                )
            } else {
                if (objectKey === 'dateCreated') {
                    return <div className="arrowIconDiv">
                        <div className="workTitle">
                            <DateDisplay value={tableContentItem[objectKey]} currentFormat="DD/MM/YYYY" />
                        </div>
                    </div>
                }
                return (
                    <div className="arrowIconDiv">
                        <div className="workTitle">
                            {tableContentItem[objectKey]}
                        </div>
                    </div>
                )
            }
        } else if (componentInstance === SEARCH_VIEW_ARTISTS) {
            return (
                <div className="arrowIconDiv">
                    <div className="workTitle">
                        {tableContentItem[objectKey]}
                    </div>
                    {objectKey === TYPE_STATE_KEY && (
                        <img
                            src="assets/external-black.svg"
                            title="Open artist in a new tab"
                            alt="External icon"
                            className="externalIcon"
                            onClick={this.onClickExternalIcon}
                        />
                    )}
                </div>
            )
        }
        else if (componentInstance === SEARCH_VIEW_POOLS) {
            return (
                <div className="arrowIconDiv">
                    <div className="workTitle">
                        {(objectKey === POOL_POOL_CODE_STATE_KEY) ? (<a onClick={this.onClickExternalIcon}>{tableContentItem[objectKey]}</a>) : tableContentItem[objectKey]}
                    </div>
                </div>

            )
        }
        else if (componentInstance === SEARCH_VIEW_DISTRIBUTIONS) {
            if (objectKey === USAGE_GROUP_DISTRIBUTION_DATE_STATE_KEY ||
                objectKey === 'lastModifiedDate' ||
                objectKey === 'usageFrom' ||
                objectKey === 'usageTo' ||
                objectKey === 'usageTo' ||
                objectKey === 'createdDate' ||
                objectKey === 'claimsFrom' ||
                objectKey === 'claimsTo') {
                return <div className="arrowIconDiv">
                    <div className="workTitle">
                        <DateDisplay value={tableContentItem[objectKey]} />
                    </div>
                </div>
            }
            if ([DISTRIBUTION_CLAIMS_FROM_STATE_KEY, DISTRIBUTION_CLAIMS_TO_STATE_KEY, DISTRIBUTION_DATE_STATE_KEY, DISTRIBUTION_USAGE_FROM_STATE_KEY, DISTRIBUTION_USAGE_TO_STATE_KEY].includes(objectKey)) {
                if (tableContentItem[objectKey] !== undefined) {
                    const splitString = tableContentItem[objectKey].split('T');
                    return (
                        <div className="arrowIconDiv">
                            <div className="workTitle">
                                {(objectKey === DISTRIBUTION_CODE_STATE_KEY) ? (<a onClick={this.onClickExternalIcon}>{splitString[0] ? splitString[0] : tableContentItem[objectKey]}</a>) : (splitString[0] ? splitString[0] : tableContentItem[objectKey])}
                            </div>
                        </div>
                    )
                }
            } else {
                if (objectKey === DISTRIBUTION_DATE_STATE_KEY) {
                    return <div className="arrowIconDiv">
                        <div className="workTitle">
                            <DateDisplay value={tableContentItem[objectKey]} />
                        </div>
                    </div>
                }

                return (
                    <div className="arrowIconDiv">
                        <div className="workTitle">
                            {(objectKey === DISTRIBUTION_CODE_STATE_KEY) ? (<a onClick={this.onClickExternalIcon}>{tableContentItem[objectKey]}</a>) : tableContentItem[objectKey]}
                        </div>
                    </div>
                )
            }
        }
        else if (componentInstance === SEARCH_VIEW_ADJUSTMENTS) {
            if (objectKey === ADJUSTMENT_DATE_STATE_KEY) {
                return <div className="arrowIconDiv">
                    <div className="workTitle">
                        <DateDisplay value={tableContentItem[objectKey]} currentFormat={YEAR_DAY_MONTH_DATE_FORMAT} />
                    </div>
                </div>
            }
            return (
                <div className="arrowIconDiv">
                    <div className="workTitle">
                        {tableContentItem[objectKey]}
                    </div>
                    {objectKey === ADJUSTMENT_ID_STATE_KEY && (
                        <img
                            src="assets/external-black.svg"
                            title="Open pool in a new tab"
                            alt="External icon"
                            className="externalIcon"
                            onClick={this.onClickExternalIcon}
                        />
                    )}
                </div>
            )
        }
        else if (componentInstance === SEARCH_VIEW_LICENSES) {
            return (
                <div className="arrowIconDiv">
                    <div className="workTitle">
                        {tableContentItem[objectKey]}
                    </div>
                    {objectKey === LICENSE_ID_STATE_KEY && (
                        <img
                            src="assets/external-black.svg"
                            title="Open shopping cart in a new tab"
                            alt="External icon"
                            className="externalIcon"
                            onClick={this.onClickExternalIcon}
                        />
                    )}
                </div>
            )
        }
        else if (componentInstance === SEARCH_VIEW_CLAIMS) {
            return (
                <div className="arrowIconDiv">
                    <div className="workTitle">
                        {tableContentItem[objectKey]}
                    </div>
                    {objectKey === CLAIM_CODE_STATE_KEY && (
                        <img
                            src="assets/external-black.svg"
                            title="Open Claim in a new tab"
                            alt="External icon"
                            className="externalIcon"
                            onClick={this.onClickExternalIcon}
                        />
                    )}
                </div>
            )
        }
        else if (componentInstance === SEARCH_VIEW_PAYMENTRUN) {
            if (PAYMENTRUN_PAYMENTRUN_DATE_STATE_KEY === objectKey) {
                if (tableContentItem[objectKey] !== undefined) {
                    const splitString = tableContentItem[objectKey].split('T');
                    return (
                        <div className="arrowIconDiv">
                            <div className="workTitle">
                                {splitString[0] ? splitString[0] : tableContentItem[objectKey]}
                            </div>
                        </div>
                    )
                }
            } else {
                return (
                    <div className="arrowIconDiv">
                        <div className="workTitle">
                            {(objectKey === PAYMENTRUN_CODE_STATE_KEY) ? (<a onClick={this.onClickExternalIcon}>{tableContentItem[objectKey]}</a>) : tableContentItem[objectKey]}
                        </div>
                    </div>
                )
            }
        }

        else if (componentInstance === SEARCH_VIEW_DRAFT_SET_LISTS) {
            return (
                <div className="arrowIconDiv">
                    {
                        <div className="tableCel">
                            {tableContentItem[objectKey]}
                        </div>
                    }
                    {objectKey === DRAFT_WORKS_RESULTS_TITLE_STATE_KEY && (
                        <img
                            src="assets/external-black.svg"
                            // TODO: EB 2023/04/07. Change the title for this icon
                            // for registered works.
                            title="Open Set List in a new tab"
                            alt="External icon"
                            className="externalIcon"
                            onClick={this.onClickExternalIcon}
                        />
                    )}
                </div>
            )
        }

        else if (componentInstance === SEARCH_VIEW_LIVE_PERFORMANCE_SUBMISSION) {
            return (
                <div className="arrowIconDiv">
                    {
                        <div className="tableCel">
                            {tableContentItem[objectKey]}
                        </div>
                    }
                    {objectKey === "performanceDate" && (
                        <img
                            src="assets/external-black.svg"
                            title="Open Live Performance in a new tab"
                            alt="External icon"
                            className="externalIcon"
                            onClick={this.onClickExternalIcon}
                        />
                    )}
                </div>
            )
        }


        else if (componentInstance === SEARCH_VIEW_DRAFT_WORKS) {
            let item;
            if (!this.excludeObjectKeys(objectKey)) {
                item = tableContentItem[objectKey];
            }
            else if (typeof (tableContentItem[objectKey]) == "string") {
                item = tableContentItem[objectKey] || "";
            }
            else {
                item = (tableContentItem[objectKey] || []).length > 0 ?
                    tableContentItem[objectKey][0].toString() || "" : "";
            }

            let onExpandResult = () => {
                expandResult(index);
            }

            return (
                <div className="arrowIconDiv">
                    {renderExpandRow && (
                        <img
                            src="assets/right-arrow.svg"
                            title="Expand title"
                            alt="Right Arrow icon"
                            className={expanded ? "arrowIconExpanded" : "arrowIcon"}
                            onClick={() => onExpandResult()}
                        />
                    )}
                    {expanded &&
                        (this.excludeObjectKeys(objectKey) &&
                            typeof (tableContentItem[objectKey]) != "string" &&
                            (tableContentItem[objectKey] || []).length > 1) ?
                        (
                            this.renderMultipleTitlesDiv(tableContentItem[objectKey])
                        ) :
                        (
                            <div className="workTitle">
                                {item ? item.replace(/,\s*$/, "") : item}
                            </div>
                        )
                    }
                    {objectKey === DRAFT_WORKS_RESULTS_TITLE_STATE_KEY && (
                        <img
                            src="assets/external-black.svg"
                            // TODO: EB 2023/04/07. Change the title for this icon
                            // for registered works.
                            title="Open Draft Work in a new tab"
                            alt="External icon"
                            className="externalIcon"
                            onClick={this.onClickExternalIcon}
                        />
                    )}
                </div>
            )
        }
        else {
            if (objectKey === IP_EFFECTIVE_FROM_KEY || objectKey === IP_EFFECTIVE_TO_KEY) {
                return <div className="arrowIconDiv">
                    <div className="workTitle">
                        <DateDisplay value={tableContentItem[objectKey]} showTime={true} />
                    </div>
                </div>
            }
            return (
                <>
                <div className="arrowIconDiv">
                        <div className="workTitle">
                            {objectKey === REGION_STATE_KEY && tableContentItem.worldExcluding == true && ("World Exlcuding: ")}
                        {tableContentItem[objectKey]}
                    </div>
                    {objectKey === IP_NAME_NUMBER_STATE_KEY && (
                        <img
                            src="assets/external-black.svg"
                            title={componentInstance === "ips" ? "Open ip in a new tab" : "Open agreement in a new tab"}
                            alt="External icon"
                            className="externalIcon"
                            onClick={this.onClickExternalIcon}
                        />
                    )}
                </div>
                {(objectKey === ADD_TO_PACKAGE_ACTION && dataSource===DataSource.Repertoire && 
                <div className="tableCell specialColumn" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                {this.renderaddforpackagesstyle()}
                <div className="tableCell specialColumn">
                                {showMessage && (
                                    <div className="messageBox">Agreement added to package</div>
                                )}
                                <div className="agreementAddtoPackage">
                                    <IconTextButton
                                        icon={iconAdded ? "icon ms-Icon ms-Icon--CheckMark" : "icon ms-Icon ms-Icon--AddIn"}
                                        onClick={() => this.addToPackages(EntityType.Agreements)}
                                        disabled={false}
                                    />
                                </div>
                            </div>

                    </div>
                )}
                </>
            );
        }
    };

    render() {
        const { objectKey } = this.props;
        return <div className="tableCell">{this.renderTitleDiv()}</div>;
    }
}
